import reportColumnTypes from "./columnTypes";
import t from "./translate";
import React from "react";

export function getMenuItemName(tab, columns, formData) {
    let reportTypes = reportColumnTypes();
    
    let columnsInTab = columns.filter(col=>col.tabName === tab.nameSingle);
    
    if(columnsInTab.length === 1)
        if(columnsInTab[0].type === reportTypes.innerTable)
        {
            var value = formData.filter(x=>x.name === columnsInTab[0].name)[0]?.value ?? [];
            if(value.length !== 0)
                return t('tab_' + tab.nameSingle) + ' (' + value.length +')';
        }
    
    return t('tab_' + tab.nameSingle);
}

export function defineIfOneInnerTableInColumn(tab, columns) {
    let reportTypes = reportColumnTypes();
    
    let columnsInTab = columns.filter(col=>col.tabName === tab.nameSingle);
    
    if(columnsInTab.length === 1)
        if(columnsInTab[0].type === reportTypes.innerTable)
        {
            return false;
        }
    return true;
}