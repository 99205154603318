import {
    Button,
    Icon,
    Popup
} from "semantic-ui-react";
import React from 'react';
import * as PropTypes from "prop-types";
import t, {getCurrentLangValue} from "../../utils/translate";
import api from "../../utils/api";
import moment from "moment/moment";

export function ExportToExcel(props) {
    const [isLoading, setIsLoading] = React.useState(false);

    let onClickUpload = () => {
        if(isLoading) 
            return;
        setIsLoading(true);
        let lang = getCurrentLangValue();
        api()
            .exportToExcel(props.view.viewName, props.dictionaryItemMetaDescription.strictNameMany, lang)
            .then(file=>{
                setIsLoading(false);
                let url = window.URL.createObjectURL(file);
                let a = document.createElement('a');
                a.href = url;
                let fileName = lang == 'ru' ? (props.view.ruNameMany ?? props.dictionaryItemMetaDescription.ruNameMany) : (props.view.nameMany ?? props.dictionaryItemMetaDescription.nameMany);
                fileName = fileName + " " + moment(new Date()).format(lang == 'ru' ? "DD.MM.yy HH.mm" : "MM/DD/yy HH.mm");
                a.download = fileName + ".xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
        ;
    };

  
    return <Popup content={t('exportToExcelBtn')} trigger={<Button loading={isLoading} onClick={onClickUpload} icon={(props.as !== 'button')}>
        <Icon name='download'/>
    </Button>} />;
}

ExportToExcel.propTypes = {
    view: PropTypes.any,
    globalFilterColumn: PropTypes.any,
    dictionaryItemMetaDescription: PropTypes.any
};