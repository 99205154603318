import t, {getCurrentLangValue} from "./translate";
import humanizeDuration from "humanize-duration";

export const minutesInYear = 525600; // 60 * 24 * 365  
export const minutesInMonth = 43200; // 60 * 24 * 30  
export const minutesInDay = 1440; // 60 * 24  
export const minutesInHour = 60;

export default function customHumanizeDuration(value) {
    return humanizeDuration(value * 60 * 1000, { language: getCurrentLangValue() , unitMeasures: {
            y: minutesInYear * 60 * 1000,
            mo: minutesInMonth * 60 * 1000,
            w: 7 * minutesInDay * 60 * 1000,
            d: minutesInDay * 60 * 1000,
            h: minutesInHour * 60 * 1000,
            m: 60000,
            s: 1000,
            ms: 1,
        }})
}