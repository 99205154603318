import api from "./api";
import {toast} from "react-toastify";
import React from "react";
import {getElementsFromFormattedText} from "./textHelper";
import getBackedUrl from "./getBackedUrl";

export function HandleResponse(resp) {
    if(resp.errorMessage != null){
        toast.error(resp.errorMessage);
    }
    if(resp.messages != null)
        for(var i = 0; i < resp.messages.length; i++) {
            let msg = <div>{getElementsFromFormattedText(resp.messages[i].message)}</div>;
            //let ops = resp.messages[i].message.indexOf('http://') !== -1 || resp.messages[i].message.indexOf('https://') !== -1 ? {delay: 3000} : null;
            let ops = null;
            switch (resp.messages[i].type) {
                case 0 :
                    toast.info(msg, ops);
                    break;
                case 1 :
                    toast.error(msg, ops);
                    break;
            }
        }

    if(resp.downloadFiles != null)
        for(var i = 0; i < resp.downloadFiles.length; i++){
            let token = resp.downloadFiles[i].token;
            window.open(`${getBackedUrl()}/api/file/downloadBy?token=${token}`, '_blank');
        }
    
    if(resp.openNewPages != null)
        for(var i = 0; i < resp.openNewPages.length; i++){
            let url = resp.openNewPages[i].url;
            let onThisPage = resp.openNewPages[i].onThisPage;
            window.open(url, onThisPage ? '_this' :'_blank');
        }
    return resp;
}

export function invokeAction(strictNameMany, actionName, id) {
    return api()
        .invokeActionForId(strictNameMany, actionName, id)
        .then(resp => {
            return HandleResponse(resp);
        });
}

export function invokeActionWithParameters(strictNameMany, actionName, id, parameters) {
    return api()
        .invokeActionForIdWithParameters(strictNameMany, actionName, id, parameters)
        .then(resp => {
            return HandleResponse(resp);
        });
}

export function invokeTask(taskName) {
    return api()
        .invokeTask(taskName)
        .then(resp => {
            return HandleResponse(resp);
        });
}

export function invokeTaskWithParameters(taskName, parameters) {
    return api()
        .invokeTaskWithParameters(taskName, parameters)
        .then(resp => {
            return HandleResponse(resp);
        });
}