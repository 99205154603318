import React from 'react';
import {
    Dropdown
} from "semantic-ui-react";
import t from "../../../utils/translate";
import './ArrayLinkToEnumFormInput.scss'

export default function ArrayLinkToEnumFormInput({placeholder, name, value, column, onChangeFieldValue, readOnly, enumItemsForSelects}) {
    return <div className={'enums-input-container'}>
        <Dropdown
            placeholder={placeholder}
            noResultsMessage={t('No results found.')}
            disabled={readOnly}
            clearable
            search
            options={enumItemsForSelects.filter(z=>z.enumName === column.linkTo)[0]?.options ?? []}
            onChange={(e, data) => {if(e.stopPropagation !== undefined) {e.stopPropagation();} return  onChangeFieldValue(name, data.value)}}
            value={value}
            multiple
            selection />
    </div>
}