import React, {useState} from 'react';
import './EnumsOfSystem.scss'

import {Divider, Header, Icon, Input, Message} from "semantic-ui-react";
import EnumModalDescription from "../EnumModalDescription/EnumModalDescription";

const EnumsOfSystem = ({configuration}) => {
    const [searchByEnum, setSearchByEnum] = useState('')
    const [currentEnum, setCurrentEnum] = useState(null)
    const enumsWithFilter = configuration.enums.filter(x=> {
            if(searchByEnum == '') return true
            return x.ruNameSingle.toLowerCase().indexOf(searchByEnum.toLowerCase()) != -1
        })
    return <div className={'block'}>
        <Divider />
        <Header as='h2' id={'enums-of-system'}>Перечисления вашей системы</Header>
        {configuration.enums.length == 0 ? <Message>В вашем проекте пока нет перечислений</Message> : <p>
            <Input
                icon={'search'}
                iconPosition={'left'}
                style={{width: '100%'}}
                placeholder={'Поиск по перечислениям'}
                value={searchByEnum}
                onChange={e=> setSearchByEnum(e.target.value)}/>
            {searchByEnum != '' && enumsWithFilter.length == 0 ? <Message>Справочники содаржащие <b>{searchByEnum}</b> не найдены.</Message> : ''}
            <ul>
                {enumsWithFilter
                    .map(x=><li className={'dictionary-name'}><a onClick={() => {setCurrentEnum(x)}}>{x.ruNameSingle}</a></li>)}
            </ul>
        </p>}
        <EnumModalDescription currentEnum={currentEnum} setCurrentEnum={setCurrentEnum}/>
    </div>;
}

export default EnumsOfSystem