import React, {useEffect, useState} from 'react';
import './CreateUpdateEventModal.css'
import {
    Button,
    Dropdown,
    Form,
    FormField, Icon, Label,
    Modal
} from "semantic-ui-react";
import t, {getCurrentLangValue} from "../../../../utils/translate";
import DatePicker from "react-datepicker";
import api from "../../../../utils/api";
import {toast} from "react-toastify";
import moment from "moment";
import getBackedUrl from "../../../../utils/getBackedUrl";

export default function CreateUpdateEventModal({open, setOpen, id}) {
    let currentLangValue = getCurrentLangValue();
    let [fromDate, setFromDate] = useState(null);
    let [toDate, setToDate] = useState(null);
    let [availableMembers, setAvailableMembers] = useState([]);
    let [name, setName] = useState('');
    let [description, setDescription] = useState('');
    let [members, setMembers] = useState([]);

    useEffect(()=>{
        if(open && id !== null){
            api()
                .getCalendarEventById(id)
                .then(resp => {
                    setName(resp.name);
                    setDescription(resp.description);
                    setMembers(resp.members);
                    setFromDate(moment(resp.from, "DD.MM.YYYY hh:mm:ss").toDate());
                    setToDate(moment(resp.to, "DD.MM.YYYY hh:mm:ss").toDate());
                });
        }else{
            if(open){
                let start = new Date();
                let end = new Date();
                let diff = 0;
                if(start.getMinutes() > 30){
                    diff = 60 - start.getMinutes();
                }else{
                    diff = 30 - start.getMinutes();
                }
                start.setMinutes(start.getMinutes() + diff);
                end.setMinutes((end.getMinutes() + diff) + 60);
                setFromDate(start);
                setToDate(end);
            }
        }
    }, [open]);
    
    useEffect(()=>{
        api()
            .getAvailableMembers()
            .then(resp=>{
                setAvailableMembers(resp.map(x=> ({key: x.id, text: x.name, value: x.id, image: { avatar: true, src: `${getBackedUrl()}/api/avatar/byUser/${x.id}` },})));
            });
    }, []);
    const createOrUpdate = () => {
        api()
            .createOrUpdateCalendarEvent({
                id: id,
                name: name,
                description: description,
                from: fromDate,
                to: toDate,
                members: members
            })
                .then(resp=>{
                    if(resp.errorMessage == null)
                        setOpen(false);
                    else
                        toast.error(resp.errorMessage);
                });
    };

    const deleteEvent = () => {
        api()
            .deleteCalendarEvent(id)
                .then(resp=>{
                    setOpen(false); 
                });
    };

    return (<Modal
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        size={"mini"}
    >
        <Modal.Header>Событие</Modal.Header>
        <Modal.Content>
            <Form>
                <FormField>
                    <label>От</label>
                    <DatePicker
                        showTimeSelect
                        dateFormat={currentLangValue === 'ru' ? "dd.MM.yyyy HH:mm" : "MMMM d, yyyy h:mm aa"}
                        timeFormat={currentLangValue === 'ru' ? "HH:mm" : "h:mm aa"}
                        timeCaption={t('timeHeader')}
                        locale={currentLangValue}
                        selected={fromDate}
                        onChange={date => {
                            setFromDate(date);
                        }}
                    />
                </FormField>
                <FormField>
                    <label>До</label>
                    <DatePicker
                        showTimeSelect
                        dateFormat={currentLangValue === 'ru' ? "dd.MM.yyyy HH:mm" : "MMMM d, yyyy h:mm aa"}
                        timeFormat={currentLangValue === 'ru' ? "HH:mm" : "h:mm aa"}
                        timeCaption={t('timeHeader')}
                        locale={currentLangValue}
                        selected={toDate}
                        onChange={date => {
                            setToDate(date);
                        }}
                    />
                </FormField>
                <Form.Input label='Название' placeholder='Введите название...' 
                            value={name} onChange={e=>{setName(e.target.value)}} />
                <Form.TextArea label='Описание' placeholder='Введите описание...'  
                               value={description} onChange={e=>{setDescription(e.target.value)}} />
                {availableMembers.length > 0 ? <FormField>
                    <label>Участники</label>
                    <Dropdown
                        className={'users-dropdown'}
                        placeholder='Укажите участников'
                        renderLabel={item => <Label className={'selected-member'}>
                            <img src={item.image.src} className={'avatar'}/>
                            <span className={'text'}>{item.text}</span> <Icon name={'close'} onClick={()=>{setMembers(members.filter(x=>x !== item.value))}}/>
                        </Label>}
                        fluid
                        multiple
                        selection
                        options={availableMembers}
                        value={members}
                        onChange={(e, data)=>{setMembers(data.value)}}
                    />
                </FormField> : ''}
            </Form>
        </Modal.Content>
        <Modal.Actions>
            {id === null ? '' : <Button 
                icon={'trash'} 
                color={'red'}
                onClick={deleteEvent}
            />}
            <Button onClick={() => setOpen(false)}>
                Отмена
            </Button>
            <Button
                content={(id === null ? "Создать" : "Обновить")}
                labelPosition='right'
                icon='checkmark'
                onClick={createOrUpdate}
                positive
            />
        </Modal.Actions>
    </Modal>);
}