import React from 'react';
import './Swagger.css'
import {getCurrentLangValue} from "../../utils/translate";
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import LanguageSwitcher from "../../shared/components/LanguageSwitcher/LanguageSwitcher";
import getBackedUrl from "../../utils/getBackedUrl";

export default function Swagger() {
    return (<div className={'swagger-page-container'}>
        <LanguageSwitcher />
        <SwaggerUI
        url={(`${getBackedUrl()}/api/swagger.json?lang=${getCurrentLangValue()}`)}
        //url={"https://petstore.swagger.io/v2/swagger.json"}
    /></div>);}