import SaverPasswordFormInput from "../shared/fieldTypes/savedPassword/SaverPasswordFormInput";
import TextareaAutosize from 'react-textarea-autosize';
import {Button, Checkbox, Dropdown, Grid, Icon, Input, Label, Popup} from "semantic-ui-react";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import {ReportExpressionEditor} from "../shared/components/ReportExpressionEditor";
import t, {getCurrentLangValue} from "./translate";
import RecognitionInput from "../shared/components/RecognitionInput";
import React from "react";
import {getElementsFromFormattedText} from "./textHelper";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-solarized_dark";
import 'semantic-ui-react-icon-picker/dist/index.css';
import IconPickerFormInput from "../shared/fieldTypes/iconPicker/IconPickerFormInput";
import FileFormInput from "../shared/fieldTypes/file/FileFormInput";
import DateTimeFormInput from "../shared/fieldTypes/dateTime/DateTimeFormInput";
import {ReportEditor} from "../shared/components/ReportEditor/ReportEditor";
import DateFormInput from "../shared/fieldTypes/date/DateFormInput";
import NumberInput from 'semantic-ui-react-numberinput';
import {ImageSelector} from "../shared/fieldTypes/ImageSelector/ImageSelector";
import ColorPicker from "../shared/fieldTypes/colorPicker/ColorPicker";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import {getRole} from "./authHelper";
import DictionaryItemEdit from "../shared/components/DictionaryItemEdit";
import DurationFormInput from "../shared/fieldTypes/duration/DurationFormInput";
import AvatarFormInput from "../shared/fieldTypes/avatar/AvatarFormInput";
import getBackedUrl from "./getBackedUrl";
import appConfig from "./appConfig";
import ImageFormInput from "../shared/fieldTypes/image/ImageFormInput";
import ArrayImageFormInput from "../shared/fieldTypes/arrayImage/ArrayImageFormInput";
import JsonFormInput from "../shared/fieldTypes/json/JsonFormInput";
import {useWindowSize} from "./windowSize";
import PythonFormInput from "../shared/fieldTypes/pythonEditor/PythonFormInput";
import ArrayLinkToEnumFormInput from "../shared/fieldTypes/arrayLinkToEnum/ArrayLinkToEnumFormInput";
import ArrayLinkToDictionaryFormInput from "../shared/fieldTypes/arrayLinkToDictionary/ArrayLinkToDictionaryFormInput";

export default function resolveColumnToElement(column, 
                                                  readOnly, 
                                                  value, 
                                                  reportTypes, 
                                                  onChangeFieldValue, 
                                                  formData, 
                                                  viewLabel, 
                                                  dictionaryItemsForSelects, 
                                                  enumItemsForSelects,
                                                  data,
                                                  parentData,
                                                  nameForTranslate,
                                                  descriptionForTranslate, 
                                                  setFormData,
                                                  updateDictionaryItemsForSelectsForColumn) {
    let inputElement = null;
    let name = column.name;
    let placeholder = (getCurrentLangValue() === 'ru' ? column.ruPlaceholder : column.enPlaceholder) ?? '';
    switch (column.type) {
        case reportTypes.imageSelector:
            inputElement = <ImageSelector
                value={value}
                onChange={(e)=>{
                    onChangeFieldValue(name, e.target.value);
                }}
                placeholder={placeholder}
            />
            break;
        case reportTypes.markdowndEditor:
            if(readOnly){
                inputElement = <div style={{position: 'relative'}}><TextareaAutosize
                    rows={3}
                    disabled={readOnly}
                    value={value}
                    onChange={(e) => { if(e.stopPropagation !== undefined) {e.stopPropagation();}  return  onChangeFieldValue(name, e.target.value)}}
                    placeholder={placeholder} >
                    {column.prefix !== '' ? <Label>{column.prefix}</Label> : ''}
                    <input />
                    {column.postfix !== '' ? <Label>{column.postfix}</Label> : ''}
                </TextareaAutosize>
                    <div style={{position: 'absolute', right: '5px', top: '5px'}}><CopyToClipboard onCopy={()=>{
                        toast.info(getCurrentLangValue() === 'ru' ? 'Скопировано в буфер обмена!' : 'Copied to the clipboard!');
                    }} text={value}>
                        <Icon name={'copy'} onClick={(e)=>{
                            e.stopPropagation();
                        }}/>
                    </CopyToClipboard></div></div>;
            }else{
                inputElement = <RecognitionInput as={'markdowndEditor'} value={value}
                                                 disabled={readOnly}
                                                 onChange={(args) => { return  onChangeFieldValue(name, args.text)}}
                                                 placeholder={placeholder}/>;
            }
            break;            
        case reportTypes.colorPicker:
            inputElement = <ColorPicker
                disabled={readOnly}
                column={column}
                onChange={(color) => {return  onChangeFieldValue(name, color)}}
                value={value?.toString() ?? ''}
             />;
            break;
        case reportTypes.int:
            inputElement = <Input
                className={'number-input' + (column.postfix !== '' ?' with-label' : '')}
                disabled={readOnly}
                labelPosition={column.postfix !== '' ? 'right' : 'left'}
                onChange={(e) => {if(e.stopPropagation !== undefined) {e.stopPropagation();} return  onChangeFieldValue(name, e.target.value)}}
                placeholder={placeholder} >
                {column.prefix !== '' ? <Label>{column.prefix}</Label> : ''}
                <NumberInput
                    disabled={readOnly}
                    allowEmptyValue={true}
                    buttonPlacement="right"
                    value={value?.toString() ?? ''}
                    placeholder={''}
                    onChange={(val)=>{onChangeFieldValue(name, val.toString());}} />
                {column.postfix !== '' ? <Label style={{background: 'transparent', display: 'inline-block', width: '10%', paddingLeft: '5px'}}>{(getCurrentLangValue() === 'ru' ? column.postfix : column.enPostfix)}</Label> : ''}
            </Input>
            break;
        case reportTypes.decimal:
            inputElement = <Input
                disabled={readOnly}
                labelPosition={column.postfix !== '' ? 'right' : 'left'}
                value={value}
                onChange={(e) => {if(e.stopPropagation !== undefined) {e.stopPropagation();} return  onChangeFieldValue(name, e.target.value)}}
                placeholder={placeholder} >
                {column.prefix !== '' ? <Label>{column.prefix}</Label> : ''}
                <input type={'number'} dir="rtl" style={{textAlign: 'right'}} />
                {column.postfix !== '' ? <Label>{column.postfix}</Label> : ''}
            </Input>
            break;
        case reportTypes.dateTime:
            inputElement = <DateTimeFormInput
                disabled={readOnly}
                name={name}
                value={value}
                column={column}
                onChangeFieldValue={onChangeFieldValue}
                
            />
            break;
        case reportTypes.date:
            inputElement = <DateFormInput
                disabled={readOnly}
                name={name}
                value={value}
                column={column}
                onChangeFieldValue={onChangeFieldValue}
            />
            break;

        case reportTypes.duration:
            inputElement = <DurationFormInput
                readOnly={readOnly}
                name={name}
                value={value}
                column={column}
                onChangeFieldValue={onChangeFieldValue}
            />
            break;


        case reportTypes.file:
            inputElement = <FileFormInput
                readOnly={readOnly}
                name={name}
                value={value}
                column={column}
                onChangeFieldValue={onChangeFieldValue}
            />
            break;        
        
        case reportTypes.image:
            inputElement = <ImageFormInput
                readOnly={readOnly}
                name={name}
                value={value}
                column={column}
                onChangeFieldValue={onChangeFieldValue}
            />
            break;
            
        case reportTypes.arrayImage:
            inputElement = <ArrayImageFormInput
                readOnly={readOnly}
                name={name}
                value={value}
                column={column}
                onChangeFieldValue={onChangeFieldValue}
            />
            break;
            
        case reportTypes.arrayLinkToEnum:
            inputElement = <ArrayLinkToEnumFormInput
                placeholder={placeholder}
                readOnly={readOnly}
                name={name}
                value={value}
                column={column}
                enumItemsForSelects={enumItemsForSelects}
                onChangeFieldValue={onChangeFieldValue}
            />
            break;
            
        case reportTypes.avatar:
            inputElement = <AvatarFormInput
                readOnly={readOnly}
                name={name}
                value={value}
                column={column}
                onChangeFieldValue={onChangeFieldValue}
            />
            break;        
        
        case reportTypes.iconPicker:
            inputElement = <IconPickerFormInput
                disabled={readOnly}
                name={name}
                value={value}
                column={column}
                onChangeFieldValue={onChangeFieldValue}
                parentData={parentData}
            />;
            break;

        case reportTypes.reportEditor:

            inputElement = <ReportEditor 
                value={value} 
                formData={formData}
                setFormData={setFormData}
                column={column}
                parentData={parentData}
                onChange={(e) => {
                    if(e.stopPropagation !== undefined) {e.stopPropagation();}
                    return onChangeFieldValue(name, e.target.value)
                }}/>
            viewLabel = false;            
            break;
        case reportTypes.reportExpression:

            inputElement = <ReportExpressionEditor
                placeholder={placeholder}    
                value={value} 
                formData={formData}
                column={column}
                parentData={parentData}
                onChange={(e) => {
                    if(e.stopPropagation !== undefined) {e.stopPropagation();}
                    return onChangeFieldValue(name, e.target.value)
                }}/>
            break;

        case reportTypes.reportExpressionForSelectRestriction:

            inputElement = <ReportExpressionEditor
                placeholder={placeholder}    
                value={value} 
                formData={formData}
                column={column}
                parentData={parentData}
                forSelectRestriction={true}
                onChange={(e) => {
                    if(e.stopPropagation !== undefined) {e.stopPropagation();}
                    return onChangeFieldValue(name, e.target.value)
                }}/>
            break;

        case reportTypes.variableSelect:
            inputElement = <ReportExpressionEditor
                single
                value={value}
                formData={formData}
                column={column}
                parentData={parentData}
                onChange={(e) => {
                    if(e.stopPropagation !== undefined) {e.stopPropagation();}
                    return onChangeFieldValue(name, e.target.value)
                }}/>
            
            /*inputElement = <VariableSelectInput
                value={value}
                parentData={parentData}
                onChange={(e, data) => { return  onChangeFieldValue(name, data.value)}}
            />*/
            break;

        case reportTypes.calculateFieldString:
            inputElement = <div>{getElementsFromFormattedText(value)}</div>;
            break;
        case reportTypes.text:
            if(appConfig().info.location === 'configurator.report.ms' && column.prefix === '' && column.postfix === '' && readOnly === false){
                inputElement = <RecognitionInput as={'textArea'} value={value}
                                                 disabled={readOnly}
                                                 onChange={(e) => { if(e.stopPropagation !== undefined) {e.stopPropagation();} return  onChangeFieldValue(name, e.target.value)}}
                                                 placeholder={placeholder}/>
            }else{
                let textareaAutosize = <TextareaAutosize
                    rows={3}
                    disabled={readOnly}
                    value={value}
                    onChange={(e) => { if(e.stopPropagation !== undefined) {e.stopPropagation();}  return  onChangeFieldValue(name, e.target.value)}}
                    placeholder={placeholder} >
                    {column.prefix !== '' ? <Label>{column.prefix}</Label> : ''}
                    <input />
                    {column.postfix !== '' ? <Label>{column.postfix}</Label> : ''}
                </TextareaAutosize>;
                
                if(readOnly){
                    inputElement = <div style={{position: 'relative'}}>{textareaAutosize}
                        <div style={{position: 'absolute', right: '5px', top: '5px'}}><CopyToClipboard onCopy={()=>{
                            toast.info(getCurrentLangValue() === 'ru' ? 'Скопировано в буфер обмена!' : 'Copied to the clipboard!');
                        }} text={value}>
                            <Icon name={'copy'} onClick={(e)=>{
                                e.stopPropagation();
                            }}/>
                        </CopyToClipboard></div></div>;
                }else{
                    inputElement = textareaAutosize;
                }

            }
            break;
        case reportTypes.codeSql:
            inputElement = <AceEditor
                mode="mysql"
                theme="solarized_dark"
                minLines={10}
                maxLines={50}
                width={'100%'}
                readOnly={readOnly}
                value={value}
                placeholder={placeholder}
                fontSize={16}
                onChange={x=>{onChangeFieldValue(name, x)}}
            />
            break;
        case reportTypes.codePython:
            inputElement = <PythonFormInput
                column={column}
                readOnly={readOnly}
                value={value}
                placeholder={placeholder}
                onChange={x => {onChangeFieldValue(name, x)}}
            />
            break;
        case reportTypes.codeJson:
            inputElement = <JsonFormInput
                column={column}
                readOnly={readOnly}
                value={value}
                placeholder={placeholder}
                onChange={x => {onChangeFieldValue(name, x)}}
            />
            break;
        case reportTypes.savedPassword:
            inputElement = <SaverPasswordFormInput
                name={name}
                value={value}
                column={column}
                onChangeFieldValue={onChangeFieldValue}
            />
            break;
        case reportTypes.passwordHash:

            inputElement = <Input
                type="password"
                value={value}
                onChange={(e) => { return  onChangeFieldValue(name, e.target.value)}}
                placeholder={placeholder} > <input autoComplete="new-password" /></Input>
            break;
        case reportTypes.bool:
            //debugger;
            inputElement = <><Checkbox
                disabled={readOnly}
                label={t(nameForTranslate)}
                onChange={(e, data) => {
                    console.log('data.checked');
                    console.log(data.checked);
                    if(e.stopPropagation !== undefined) {e.stopPropagation();}
                    return  onChangeFieldValue(name, data.checked)
                }}
                checked={value.toString().toLowerCase() === 'true'}
                value={value.toString().toLowerCase() === 'true'}
            />{column.description !== '' ? <Popup
                position={"top center"}
                trigger={<Icon name={"info circle"}/>}
                on='click'
                content={getElementsFromFormattedText(t(descriptionForTranslate))}
            />:''}</>
            viewLabel = false;
            break;
        case reportTypes.linkToDictionary:
            let val = value;
            if (val.id !== undefined && val.id !== null && val.id !== '')
                val = val.id;
            let dictionaryType = appConfig().dictionaries.filter(x=>x.strictNameMany === column.linkTo)[0];
            let role = getRole();
            
            let viewAddBtn = dictionaryType.views.filter(v => v.canCreate && v.forRole === role).length > 0;
            if(column.linkTo === 'Users'){
                dictionaryItemsForSelects = dictionaryItemsForSelects.map(x => {return {...x, image: { avatar: true, src: `${getBackedUrl()}/api/avatar/byUser/${x.value}` }}})
            }
            let dropdown = <Dropdown
                placeholder={placeholder}
                noResultsMessage={t('No results found.')}
                disabled={readOnly}
                clearable
                search
                options={dictionaryItemsForSelects}
                onChange={(e, data) => { if(e.stopPropagation !== undefined) {e.stopPropagation();} return  onChangeFieldValue(name, data.value)}}
                value={val}
                selection 
                style={viewAddBtn && !readOnly ? {borderRadius: '5px 0px 0px 5px'}: {}}
            />;
            if(viewAddBtn && !readOnly){
                let view = dictionaryType.views.filter(v => v.canCreate && v.forRole === role)[0];
                let formDataTemp = [];
                if(appConfig().info.globalFilter !== null) {
                    if(localStorage.getItem('GlobalFilterValue') !== null){
                        let filterDictionary = appConfig()
                            .dictionaries.filter(x=>x.nameSingle === appConfig().info.globalFilter.split('-')[0])[0];

                        let globalFilterColumn = filterDictionary.columns.filter(column => column.type === reportTypes.linkToDictionary &&
                            column.linkTo === filterDictionary.strictNameMany)[0] ?? null;
                        //setGlobalFilterColumn(globalFilterColumn);

                        if(globalFilterColumn !== null)
                            formDataTemp.push({name: globalFilterColumn.name, value: localStorage.getItem('GlobalFilterValue')});
                    }
                }
                inputElement = <>
                    <Grid style={{paddingLeft: '14px', paddingRight: '21px'}}>
                        <Grid.Row>
                            <Grid.Column width={15} style={{padding: '0'}} className>
                                {dropdown}
                            </Grid.Column>
                            <Grid.Column width={1} style={{padding: '0'}}>
                                <DictionaryItemEdit
                                    dictionaryItemMetaDescription={dictionaryType}
                                    view={view.nameSingle}
                                    onSaveEvent={(id)=>{
                                        updateDictionaryItemsForSelectsForColumn(dictionaryType, column, formData, true);
                                        onChangeFieldValue(name, id);
                                    }}
                                    prefillFields={formDataTemp}
                                ><Button icon={'plus'} 
                                         style={{    width: '38px',
                                             height: '38px',
                                             borderRadius: '0px 5px 5px 0px'}}
                                /></DictionaryItemEdit>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </>;
            }
            else
                inputElement = dropdown;
            
            break;
        case reportTypes.arrayLinkToDictionary:
            inputElement = <ArrayLinkToDictionaryFormInput
                readOnly={readOnly}
                placeholder={placeholder}
                name={name}
                value={value}
                column={column}
                dictionaryItemsForSelects={dictionaryItemsForSelects}
                onChangeFieldValue={onChangeFieldValue}
            />
            break;
        case reportTypes.arrayString:
            console.log('!!arrayString');
            console.log(value);

            console.log('!!formData');
            console.log(formData);
            let valForArrayString = value;
            if(value.length > 0)
                valForArrayString = value.map(x=> {
                    if (x.id !== undefined && x.id !== null && x.id !== '')
                        return x.id;
                    return x;
                });
            inputElement = <Dropdown
                //key={'arr-col-' + dictionaryItemsForSelects.length + column.name}
                placeholder={placeholder}
                noResultsMessage={t('No results found.')}
                disabled={readOnly}
                clearable
                search
                options={value === '' || value === undefined || value.map === undefined ? [] : value.map(x=> ({id: x, value: x, text: x}))}
                onChange={(e, data) => {
                    console.log("!!! data.value");
                    console.log(data.value);
                    if(e.stopPropagation !== undefined) {e.stopPropagation();}
                    return  onChangeFieldValue(name, data.value)}}
                value={value}
                multiple
                selection />
            break;
        case reportTypes.linkToEnum:
            let valueColor = null;
            //debugger;
            let enumType = appConfig().enums.filter(x=>x.nameSingle === column.linkTo)[0];
            valueColor = enumType.values.filter(x=>x.nameSingle === value)[0]?.color ?? null;

            if(readOnly) {
                inputElement = <div style={{position: 'relative'}}>
                    {valueColor != null ? <div style={{position: 'absolute', zIndex: '1', top: '9px', left: '4px'}}><Icon name={'circle'} color={valueColor}/></div> : ''}
                        <Input
                            disabled={readOnly}
                            labelPosition={column.postfix !== '' ? 'right' : 'left'}
                            value={t(value)}
                            placeholder={placeholder} >
                            {column.prefix !== '' ? <Label>{column.prefix}</Label> : ''}
                            <input style={{ paddingLeft: valueColor != null ? '30px' : 'auto' }}/>
                            {column.postfix !== '' ? <Label>{column.postfix}</Label> : ''}
                        </Input>
                    </div>
                break;
            }
            if((value === undefined || value === null || value === '') || (value !== undefined && value !== null && value !== '' && valueColor == null)){
                inputElement = <Dropdown
                    placeholder={placeholder}
                    noResultsMessage={t('No results found.')}
                    disabled={readOnly}
                    clearable
                    search
                    options={enumItemsForSelects.filter(z=>z.enumName === column.linkTo)[0]?.options ?? []}
                    onChange={(e, data) => {if(e.stopPropagation !== undefined) {e.stopPropagation();} return  onChangeFieldValue(name, data.value)}}
                    value={value}
                    selection />
                
            }else{
                inputElement = <div style={{position: 'relative'}}> <div style={{position: 'absolute', zIndex: '1', top: '9px', left: '4px'}}><Icon name={'circle'} color={valueColor}/></div><Dropdown
                    placeholder={placeholder}
                    className={'with-color'}
                    disabled={readOnly}
                    clearable
                    search
                    options={enumItemsForSelects.filter(z=>z.enumName === column.linkTo)[0]?.options ?? []}
                    onChange={(e, data) => {if(e.stopPropagation !== undefined) {e.stopPropagation();} return  onChangeFieldValue(name, data.value)}}
                    value={value}
                    selection /></div>                
            }
            
            break;
            
        case reportTypes.roleSelect:
            if(readOnly) {
                inputElement = <Input
                    disabled={readOnly}
                    labelPosition={column.postfix !== '' ? 'right' : 'left'}
                    value={t(value)}>
                    {column.prefix !== '' ? <Label>{column.prefix}</Label> : ''}
                    <input />
                    {column.postfix !== '' ? <Label>{column.postfix}</Label> : ''}
                </Input>
                break;
            }
                
            inputElement = <Dropdown
                placeholder={placeholder}
                noResultsMessage={t('No results found.')}
                disabled={readOnly}
                clearable
                search
                options={enumItemsForSelects.filter(z=>z.enumName === 'Role')[0]?.options ?? []}
                onChange={(e, data) => { if(e.stopPropagation !== undefined) {e.stopPropagation();} return  onChangeFieldValue(name, data.value)}}
                value={value}
                selection />
            break;

        default:
            if(appConfig().info.location === 'configurator.report.ms' && column.prefix === '' && column.postfix === '' && readOnly === false){
                inputElement = <RecognitionInput value={value}
                                                 onChange={(e) => {if(e.stopPropagation !== undefined) {e.stopPropagation();} return  onChangeFieldValue(name, e.target.value)}}
                                                 placeholder={placeholder}/>
            }else{
                inputElement = <Input
                    disabled={readOnly}
                    labelPosition={column.postfix !== '' ? 'right' : 'left'}
                    value={value}
                    onChange={(e) => {if(e.stopPropagation !== undefined) {e.stopPropagation();} return  onChangeFieldValue(name, e.target.value)}}
                    placeholder={placeholder} >
                    {column.prefix !== '' ? <Label>{column.prefix}</Label> : ''}
                    <input />
                    {column.postfix !== '' ? <Label>{column.postfix}</Label> : ''}
                </Input>
            }
    }
    
    return [inputElement, viewLabel];
}