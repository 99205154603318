import React from 'react';
import resolveColor from "../../../../utils/colorHelper";
import {getElementsFromFormattedText} from "../../../../utils/textHelper";

const ActionElement = ({getData, pageIndex, elementIndex, element, width, height, onePercentInPixelForWidth, onePercentInPixelForHeight}) => {
    let color = (element.color !== undefined && element.color !== null && element.color !== '') ? element.color.toLowerCase() : 'black';
    color = resolveColor(color);
    let c = 1;
    
    switch (element.fontSize) {
        case 'Regular':
             c = 1;
             break;
        case 'SubTitle':
             c = 2;
             break;
        case 'Title':
             c = 3;
             break;
    }
    let fontHeight = onePercentInPixelForWidth * c;
    return <div style={{fontSize:  fontHeight  + 'px', color: color, textAlign: (element.textFormat  ?? 'left').toLowerCase()}}>{getElementsFromFormattedText(element.textForTextField)}</div>;

}

export default ActionElement