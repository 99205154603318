import React, {useState} from 'react';
import './login.css'
import {
    Button,
    Input
} from "semantic-ui-react";
import t from "../../../../utils/translate";
import api from "../../../../utils/api";
import {useWindowSize} from "../../../../utils/windowSize";

export default function Login() {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [imapServer, setImapServer] = useState('')
    const [smtpServer, setSmtpServer] = useState('')
    const [error, setError] = useState('')
    const [isDisable, setIsDisable] = useState(false)
    const [loginIsLoading, setLoginIsLoading] = useState(false)
    const [viewServers, setViewServers] = useState(false)
    const [width, height] = useWindowSize();
    const loginClick = () => {
        setIsDisable(true)
        setLoginIsLoading(true)
        api()
            .mailLogin(login, password, imapServer, smtpServer)
            .then(resp => {
                setIsDisable(false)
                setLoginIsLoading(false)
                if(resp.errorMessage !== '' && resp.errorMessage !== null){
                    setError(resp.errorMessage)
                    if(resp.errorMessage === 'wrong imap'){
                        setViewServers(true)
                    }
                    return
                }
                window.location = window.location
            })
    }
    return (<div className={'email-client-login-page-container'} style={{height: `${height - 40}px`, paddingTop: `${(height - 40)/2 - 100}px`}}>
        <div className={'login-border'}>
            <div className={'login-wrapper'}>
                <div>
                    <div className="margin-bottom-10">
                        <Input
                            name={'email'}
                            disabled={isDisable}
                            onKeyPress={(event)=>{if(event.key === 'Enter'){ loginClick(); }}}
                            onChange={(e) => {
                                setLogin(e.target.value);
                                setError('');
                            }} value={login} icon='mail' iconPosition='left' placeholder='Email' /></div>
                    <div className="margin-bottom-15">
                        <Input type="password"
                               disabled={isDisable}
                               onKeyPress={(event)=>{if(event.key === 'Enter'){ loginClick(); }}}
                               onChange={(e) => {
                                   setPassword(e.target.value);
                                   setError('');
                               }} value={password} icon='lock' iconPosition='left' placeholder={t("password_placeholder")} />
                    </div>
                    {viewServers ? <div>
                        <div className="margin-bottom-10">
                            <Input
                                name={'imap-server'}
                                disabled={isDisable}
                                onKeyPress={(event)=>{if(event.key === 'Enter'){ loginClick(); }}}
                                onChange={(e) => {
                                    setImapServer(e.target.value);
                                    setError('');
                                }} value={imapServer} icon='server' iconPosition='left' placeholder='imap.my-company.com' />
                        </div>
                        <div className="margin-bottom-10">
                            <Input
                                name={'smtp-server'}
                                disabled={isDisable}
                                onKeyPress={(event)=>{if(event.key === 'Enter'){ loginClick(); }}}
                                onChange={(e) => {
                                    setSmtpServer(e.target.value);
                                    setError('');
                                }} value={smtpServer} icon='server' iconPosition='left' placeholder='smtp.my-company.com' />
                        </div>

                    </div> : ''}
                </div>
                <div>
                    <Button
                        disabled={isDisable}
                        loading={loginIsLoading}
                        onClick={() => { loginClick(); }}>{t("login_btn")}</Button>
                </div>
                <div className="error">
                    {error}
                </div>
            </div>            
        </div>
    </div>);
}