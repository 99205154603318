import React, {useEffect, useState} from 'react';
import {Loader} from "semantic-ui-react";
import {getCurrentLangValue} from "../../../../utils/translate";
import getTitle from "../../../../utils/reportElementTitleGetter";

const ValueReportElement = ({isEdit, getData, pageIndex, elementIndex, element, width, height, onePercentInPixelForWidth, onePercentInPixelForHeight, filtersValues}) => {
    let [isLoading, setIsLoading] = useState(true);
    let [value, setValues] = useState();

    let update = ()=>{
        return getData(pageIndex, elementIndex, filtersValues).then(values => {
            let valueElement = values[0][0];
            if(!isNaN(valueElement.replace(',', '').replace('.', ''))) 
                valueElement = parseFloat(valueElement).toLocaleString(getCurrentLangValue() === 'ru' ? 'ru-RU' : 'en-US');
            
            setValues(valueElement);
            setIsLoading(false);
        });
    };
    useEffect(() => {
        update();
    }, []);

    useEffect(() => {
        update();
    }, [filtersValues, element]);

    let valueFontHeight = 3.2 * onePercentInPixelForWidth;
    let labelFontHeight = onePercentInPixelForWidth;
    let paddingTop = (height - valueFontHeight)/2/* + 11*/;

    let prefix = '';
    let postfix = '';
    let func = '';
    if(element.dictionaryFieldForValue !== undefined && element.dictionaryFieldForValue !== ''){
        let field = JSON.parse(element.dictionaryFieldForValue);
        if(isEdit){
            prefix = field.prefix ?? '';
            postfix = field.postfix ?? '';
        }else{
            prefix = (getCurrentLangValue() === 'ru' ? field.prefix : field.enPrefix) ?? '';
            postfix = (getCurrentLangValue() === 'ru' ? field.postfix : field.enPostfix) ?? '';
        }
        func = field.func ?? '';
        
        
        if(field.fieldType === 'Date' && value !== undefined && value !== null && value !== '') {
            value = value.split(' ')[0];
        }
    }
    
    return (<div>
        {isLoading ? <Loader active inline size='massive'></Loader> : <div style={{fontSize: valueFontHeight + 'px', paddingTop: paddingTop + 'px'}}>{prefix !=='' && func !== 'COUNT' ? prefix : ''} {value} {postfix !=='' && func !== 'COUNT' ? postfix : ''}{func ==='COUNT' ? (getCurrentLangValue() === 'ru' ? ' ед.' : ' units') : ''}</div>}
        {!isLoading ? <div style={{fontSize: labelFontHeight + 'px', paddingTop: (onePercentInPixelForWidth*2) + 'px'}}>{getTitle(isEdit, element)}</div> : ''}
    </div>);
}

export default ValueReportElement