import React, { useState } from 'react';
import {
    Modal,
    Button,
    GridRow,
    GridColumn,
    Grid,
    Tab,
    Header,
    Icon,
    Input,
    Form,
    Checkbox,
    Container, Message
} from 'semantic-ui-react';
import api from "../../../utils/api";
import t from "../../../utils/translate";
import {getElementsFromFormattedText} from "../../../utils/textHelper";

export default function ForgotPasswordModal({children}) {
    const [open, setOpen] = React.useState(false)
    let [loading, setLoading] = useState(false);
    let [isSent, setIsSent] = useState(false);
    let [email, setEmail] = useState('');

    let validateEmail = (email) => {
        if(email === '' || email === null)
            return false;

        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    
    let send = ()=>{
        setLoading(true);
        api().sendForgotPasswordEmail(email).then(resp=>{
            setIsSent(true);
            setLoading(false);
        })
    };
    
    return (
        <Modal dimmer="blurring"
               size={"mini"}
               onClose={() => setOpen(false)}
               onOpen={() => {
                   setEmail('');
                   setIsSent(false);
                   setLoading(false);
                   setOpen(true);
               }}
               open={open}
               trigger={children}>
            <Modal.Header>{t('password_recovery_modal_titlte')}</Modal.Header>
            <Modal.Content>
                {isSent ? <Message>{getElementsFromFormattedText(t('password_recovery_modal_message_about_sent'))}</Message> : <>
                    <Form>
                        <Message icon={'unlock alternate'} content={t('password_recovery_modal_message')}/>
                        <Form.Field>
                            <Input 
                                icon={'mail'} 
                                placeholder={'example@mail.com'}
                                onChange={(event => setEmail(event.target.value))}
                            />
                        </Form.Field>
                    </Form>
                </>}
            </Modal.Content>
            <Modal.Actions>
                {!isSent ? <Button onClick={()=>{setOpen(false);}}>{t('password_recovery_modal_cancel_btn')}</Button> : ''}
                {isSent ? <Button onClick={()=>{setOpen(false);}}>{t('Ок')}</Button> : <Button
                    primary
                    disabled={!validateEmail(email)}
                    loading={loading}
                    onClick={send}
                >{t('password_recovery_modal_recovery_btn')}</Button>}
            </Modal.Actions>
        </Modal>
    );
}