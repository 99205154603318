import {
    Button,
    Dropdown,
    Form,
    Header,
    Icon,
    Loader,
    Message,
    Modal,
    Popup
} from "semantic-ui-react";

import React, {useEffect, useState} from 'react';
import * as PropTypes from "prop-types";
import t, {getCurrentLangValue} from "../../utils/translate";
import {
    invokeAction,
    invokeActionWithParameters,
    invokeTask,
    invokeTaskWithParameters
} from "../../utils/actionInvoker";
import api from "../../utils/api";
import {getElementsFromFormattedText} from "../../utils/textHelper";
import reportColumnTypes from "../../utils/columnTypes";
import {calculateExpression} from "../../utils/expressionHelper";
import resolveColumnToElement from "../../utils/resolveColumnToElement";
import cronstrue from 'cronstrue/i18n';
import appConfig from "../../utils/appConfig";
import {toast} from "react-toastify";

export function ActionButton(props) {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    let [formData, setFormData] = useState([]);
    let [dictionaryItemsForSelects, setDictionaryItemsForSelects] = useState([]);
    let [enumItemsForSelects, setEnumItemsForSelects] = useState([]);
    let [errors, setErrors] = useState([]);

    useEffect(()=>{
        setErrors([])
    }, [])
    let reportTypes = reportColumnTypes();

    let onChangeFieldValue = (name, value) => {
        let item = formData.filter(x=>x.name === name)[0];
        if(item === undefined)
            item = {name: name, value: value}
        item.value = value;
        let newFormData = [];

        let others = formData.filter(x=>x.name !== name)

        for (let a in others)
            newFormData.push({name: others[a].name, value: others[a].value});

        newFormData.push(item);
        return setFormData(newFormData);
    };
    
    let getGroupedColumns = (columns)=>{
        let groups = [];
        let currentGroupIndex = 0;

        columns.forEach(col=>{
            if(groups[currentGroupIndex] === undefined)
                groups[currentGroupIndex] = [];
            var totalSizeInRow = 0;
            for (var i = 0; i < groups[currentGroupIndex].length; i++){
                totalSizeInRow += groups[currentGroupIndex][i].size
            }
            if((totalSizeInRow + col.size) > 16 || col.isNewLine){
                currentGroupIndex++;
                groups[currentGroupIndex] = [];
            }
            groups[currentGroupIndex].push(col);
        });
        //console.log('groups!!!!!!!!!!!!!!');
        //console.log(groups);
        return groups;
    };
    
    let createInputFromColumn = (column, viewLabel = true) => {
        let name = column.name;
        let item = formData.filter(x=>x.name === name)[0];
        let value = item?.value ?? '';
        //debugger;
        let inputElement = null;

        let readOnly = false;

        if(column.readOnlyIf !== undefined && column.readOnlyIf !== null && column.readOnlyIf !== '') {
            readOnly = calculateExpression(column.readOnlyIf, undefined, formData, undefined)
        }

        if(column.type == reportTypes.innerTable) {
           
        } else {
            [inputElement, viewLabel] = resolveColumnToElement(column,
                readOnly,
                value,
                reportTypes,
                onChangeFieldValue,
                formData,
                viewLabel,
                dictionaryItemsForSelects,
                enumItemsForSelects,
                undefined,
                {data: []},
                'action_params_field_' + name,
                'action_params_field_description_' + column.description,
                setFormData);
        }
        let requiredFlag = column.isRequiredForCreate || column.isRequiredForUpdate ? <span style={{color: 'red'}}>*</span> : '';

        let errorFlag = errors.indexOf(column.name) !== -1;
        
        return <Form.Field
            key={'form-field-' + name}
            error={errorFlag}
            onFocus={()=>{
                if(errors.indexOf(column.name) !== -1) {
                    setErrors(errors.filter(x=>x !== column.name));
                }
            }}
            width={column.size}
        >
            {viewLabel ? (column.description !== '' ?    <><label>{t('action_params_field_' + name)} {requiredFlag}</label> <Popup
                position={"top center"}
                trigger={<Icon name={"info circle"}/>}
                on='click'
                content={getElementsFromFormattedText(t('action_params_field_description_' + column.description))}

            /></> : <label>{t('action_params_field_' + name)} {requiredFlag}</label>) : ''}
            {inputElement}
        </Form.Field>;
    };    
    
    
    let trigger;
    let description = t('action_Description_' + props.action.name);
    description = description !== ('action_Description_' + props.action.name) ? description : '';
    let schedule = '';
    if(props.action.schedule !== undefined && props.action.schedule.length > 0){
        schedule = <><hr/>Задача повторяется:<ul>{props.action.schedule.map(x=><li>{cronstrue.toString(x, {
            locale:'ru'
        })}</li>)}</ul></>;
    }
    let DescriptionElementsFromFormattedText = <>{getElementsFromFormattedText(description)} {schedule}</>;
    if(props.as == null || props.as == 'button'){
        trigger = <Button 
            //icon={props.action.icon !== '' ? props.action.icon : null} 
            icon 
            labelPosition='left'
            loading={isLoading}
            style={props.style}
            onClick={(description === '' ? null : (e) => {onOpenModal(e)})}>
            <Icon name={props.action.icon} /> {t('action_' + props.action.name)}
        </Button>;
    }else{
        trigger = <Dropdown.Item
            onClick={(description === '' ? null : (e) => {onOpenModal(e)})}>
            <Icon name={props.action.icon} />{t('action_' + props.action.name)}
        </Dropdown.Item>;
    }

    let byGlobalFilter = col=>{
        {
            if(appConfig().info.globalFilter !== null) {
                if(localStorage.getItem('GlobalFilterValue') !== null){
                    let filterDictionary = appConfig()
                        .dictionaries.filter(x=>x.nameSingle === appConfig().info.globalFilter.split('-')[0])[0];

                    let b = !(col.type === reportTypes.linkToDictionary &&
                        col.linkTo === filterDictionary.strictNameMany);
                    if(!b){
                        //debugger;
                    }
                    return b;
                }else{
                    return true;
                }
            }else
                return true;
        }
    };

    let groupedColumns = props.action.parameterInfo == null ? null : getGroupedColumns(props.action.parameterInfo.fields
        .filter(column => {
            if(column.hideIf !== undefined && column.hideIf !== null && column.hideIf !== '') {
                var hideIf = column.hideIf;

                for (var i = 0;i < appConfig().enums.length; i++){
                    for (var j = 0;j < appConfig().enums[i].values.length; j++){
                        hideIf = hideIf.replaceAll(`"${appConfig().enums[i].nameSingle}"."${appConfig().enums[i].values[j].nameSingle}"`, `"${appConfig().enums[i].values[j].nameSingle}"`);
                    }
                }

                props.action.parameterInfo.fields.map(col =>
                    {
                        var fdi = formData.filter(fd => fd.name === col.name)[0];
                        hideIf = hideIf.replaceAll(col.name, fdi?.value ?? 'null');
                    }
                );

                //hideIf = hideIf.replaceAll('"Id"', data !== undefined && data.Id !== undefined ? ("'" + data.Id + "'") : 'null');

                /*for (var i = 0;i < formData.length; i++){
                    hideIf = hideIf.replaceAll(formData[i].name, formData[i].value);
                }*/

                let result = false;

                try {

                    result = eval(hideIf);
                }catch (e) {
                    console.log('hideIf ERROR');
                    console.log(e);
                }
                console.log(hideIf);
                return result !== true;
            }
            return true;
        }).filter(byGlobalFilter));
    
    let onClick = e => {
        e.stopPropagation();
        if(isLoading)
            return;
        
        setIsLoading(true);
        let thenInvokeAction = actionResult => {
            setIsLoading(false);
            if(actionResult === undefined || actionResult.messages == null || actionResult.messages.filter(x=>x.type === 1).length > 0)
                return;
            
            if(props.afterInvoke != null){
                let afterInvoke = props.afterInvoke();
                setOpen(false);
                return afterInvoke;
            }
        };
        if(props.action.parameterInfo == null) {
            if(props.for === 'task'){
                invokeTask(props.action.name)
                    .then(thenInvokeAction);
            }else{
                invokeAction(props.dictionaryStrictNameMany, props.action.name, props.id)
                    .then(thenInvokeAction);
            }
        }else{
            let cols = []
            for(let i = 0; i < groupedColumns.length; i++){
                for(let j = 0; j < groupedColumns[i].length; j++){
                    cols.push(groupedColumns[i][j])
                }
            }
            let requiredColumns = cols
                .filter(x=>x.isRequiredForCreate || x.isRequiredForUpdate);
            let requiredErrors = [];
            for (let i = 0; i < requiredColumns.length; i++){
                let requiredColumn = requiredColumns[i];
                let formDataItemForRequiredColumn = formData.filter(x=>x.name === requiredColumn.name)[0] ?? null;
                if(formDataItemForRequiredColumn == null || formDataItemForRequiredColumn.value == null || formDataItemForRequiredColumn.value == '')
                    requiredErrors.push(requiredColumn);
            }
            if(requiredErrors.length > 0) {
                toast.error(<div>
                    <p>{t('Fill_required_fields')}:</p>
                    <ul>
                        {requiredErrors.map(col=><li><b>{(getCurrentLangValue() === 'ru' ? col.ruName : col.enName)}</b></li>)}
                    </ul>
                </div>);
                setErrors(requiredErrors.map(x=>x.name));
                setIsLoading(false);
                return;
            }

            if(props.for === 'task'){
                invokeTaskWithParameters(props.action.name, formData)
                    .then(thenInvokeAction);
            }else{
                invokeActionWithParameters(props.dictionaryStrictNameMany, props.action.name, props.id, formData)
                    .then(thenInvokeAction)
            }
        }
    };

    
    if(props.action.parameterInfo == null) {
        if(props.as == null || props.as == 'button'){
            trigger = <Button
                //icon={props.action.icon !== '' ? props.action.icon : null}
                icon
                labelPosition='left'
                loading={isLoading}
                style={props.style}
                onClick={onClick}>
                <Icon name={props.action.icon ?? ''} /> {t('action_' + props.action.name)}
            </Button>;
        }else{
            trigger = <Dropdown.Item
                //icon={(props.action.icon !== '' ? props.action.icon : null)}
                icon={'globe'}
                onClick={onClick}>
                <Icon name={props.action.icon} />{t('action_' + props.action.name)} {isLoading ? <Loader active inline size='mini' /> : ''}
            </Dropdown.Item>;
        }
        if(description !== '')
            trigger = <Popup 
                trigger={trigger}
                inverted
                position={"left center"}
                on={"hover"}
                content={DescriptionElementsFromFormattedText}
            />
        return trigger;
    }
    
    if(description !== '')
        trigger = <Popup
            onClick={()=>{setOpen(true)}}
            trigger={trigger}
            inverted
            position={"left center"}
            on={"hover"}
            content={DescriptionElementsFromFormattedText}
        />

    let onOpenModal = (e) => {
        e.stopPropagation();
        /*setIsAwaliableDelete(false);
        setTimeout(()=>{setIsAwaliableDelete(true)}, 2000);*/
        setOpen(true);
        let newFormData = [];

        props.action.parameterInfo.fields.filter(col=> !byGlobalFilter(col)).map(col=>{
            newFormData.push({name: col.name, value: localStorage.getItem('GlobalFilterValue')});
        });
        
        props.action.parameterInfo.fields.filter(byGlobalFilter).map(column => {
            if(column.type === reportTypes.linkToDictionary || column.type === reportTypes.arrayLinkToDictionary) {
                let filter = [];

                api()
                    .getListForSelect(column.linkTo, 0, filter)
                    .then((data)=> {
                        let options = data.map(x=> { return {key: x.id, text: x.name, value: x.id};});
                        dictionaryItemsForSelects.push({dictionaryName: column.linkTo, options: options});
                        setDictionaryItemsForSelects(dictionaryItemsForSelects);
                    })
            }
            if(column.type === reportTypes.linkToEnum) {
                let enums = appConfig().enums;

                console.log(enums);
                console.log(column.linkTo);

                let options = enums
                    .filter(x => x.nameSingle === column.linkTo)[0].values
                    .map(x=> { return {key: x.nameSingle, text: t(x.nameSingle), value: x.nameSingle};});

                enumItemsForSelects.push({enumName: column.linkTo, options: options});
                setEnumItemsForSelects(enumItemsForSelects);
            }
        });

        let preselectedColumns = props.action.parameterInfo.fields
            .filter(byGlobalFilter)
            .filter(x=>x.preselected);
        
        if(preselectedColumns.length > 0){
            for (var i = 0; i < preselectedColumns.length; i++) {
                if(newFormData.filter(x=>x.name === preselectedColumns[i].name).length === 0)
                {
                    let newVar;
                    if(preselectedColumns[i].type === reportTypes.bool)
                        newVar = preselectedColumns[i].type === reportTypes.bool;

                    if(preselectedColumns[i].type === reportTypes.linkToEnum){
                        let enumForCol = appConfig().enums.filter(x=>x.nameSingle === preselectedColumns[i].linkTo)[0];
                        newVar = enumForCol.values[0].nameSingle;
                    }
                    newFormData.push({name: preselectedColumns[i].name, value: newVar});
                }
            }
        }
        if(props.data != undefined && props.data != null) {
            props.action.parameterInfo.fields.filter(byGlobalFilter).map(field => {
                if(props.data.filter(x => x.name == field.name).length > 0) {
                    newFormData.push({name: field.name, value: props.data.filter(x => x.name == field.name)[0].value});
                }
                return field;
            });
        }
        //if(appConfig().info.globalFilter !== null && ) {}
        //let newFormData = [];
        setFormData(newFormData);
    };
    return <Modal
        onClose={() => setOpen(false)}
        onClick={(e)=>{e.stopPropagation();}}
        onOpen={onOpenModal}
        open={open}
        size={props.action.parameterInfo.modalSize}
        trigger={trigger}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        closeIcon={true}
    >
        <Header>
            {props.for === 'task' ? `${t("action_" + props.action.name)}`: `Параметры действия  ${t("action_" + props.action.name)}`}
        </Header>
        <Modal.Content onClick={(e)=>{e.stopPropagation();}}>
            {props.action.parameterInfo.description !== '' ? <Message
                icon={props.action.parameterInfo.icon !== '' ? props.action.parameterInfo.icon : null }
                content={getElementsFromFormattedText(t("action_params_description_" + props.action.parameterInfo.description))}
            />: ''}
            <Form autoComplete="off">
                {groupedColumns.map((x, i) => <Form.Group key={"group-for-out-tabs-row-" + i}>
                    {x.map(col => createInputFromColumn(col))}
                </Form.Group>)}
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button
                basic
                disabled={isLoading}
                onClick={(e) => {e.stopPropagation(); setOpen(false)}}>
                {t('Cancel')}
            </Button>
            <Button
                icon={props.action.icon !== '' ? props.action.icon : null}
                primary
                onClick={onClick}
                loading={isLoading}
                compact={props.compact}
            >
                {props.for === 'task' ? `Выполнить`: t("action_" + props.action.name)}
            </Button>
        </Modal.Actions>
    </Modal>;
}

ActionButton.propTypes = {
    for: PropTypes.string,
    afterInvoke: PropTypes.func,
    dictionaryStrictNameMany: PropTypes.string,
    action: PropTypes.any,
    id: PropTypes.string,
};