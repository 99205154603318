import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Header from './header/header'
import Login from './pages/Login/Login'
import LoginByToken from './pages/LoginByToken/LoginByToken'
import ReportDictionary from "./shared/ReportDictionary";
import Introduction from "./pages/Introduction/Introduction";
import ConfigurationViewer from "./pages/ConfigurationViewer/ConfigurationViewer";
import * as signalR from "@microsoft/signalr";
import api from "./utils/api";
import {ConfigurationContext} from './utils/configurationContext';
import PowerBiPage from "./pages/PowerBiPage/PowerBiPage";
import SetNewPassword from "./pages/SetNewPassword/SetNewPassword";
import PasswordIsSetPage from "./pages/PasswordIsSetPage/PasswordIsSetPage";
import {getElementsFromFormattedText} from "./utils/textHelper";
import KnowledgeBase from "./pages/KnowledgeBase/KnowledgeBase";
import Meets from "./pages/Meets/Meets";
import EmailClient from "./pages/EmailClient/EmailClient";
import Calendar from "./pages/Calendar/Calendar";
import Chat from "./pages/Chat/Chat";
import {ReportVisual} from "powerbi-report-component";
import ReportViewer from "./pages/ReportViewer/ReportViewer";
import IFramePage from "./pages/IFrame/IFramePage";
import PdfPage from "./pages/Pdf/PdfPage";
import langs from "./utils/langs";
import {getCurrentLangValue, setCurrentLangValue} from "./utils/translate";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import ru from "date-fns/locale/ru";
import en from "date-fns/locale/en-US";
import PublicPage from "./pages/PublicPage/PublicPage";
import 'react-markdown-editor-lite/lib/index.css';
import Swagger from "./pages/Swagger/Swagger";
import moment from "moment";
import KnowledgeNewsItemView from "./pages/KnowledgeBase/components/KnowledgeNewsItemView/KnowledgeNewsItemView";
import getBackedUrl from "./utils/getBackedUrl";
import appConfig from "./utils/appConfig";
import PythonTasksInstruction from "./pages/PythonTasksInstruction/PythonTasksInstruction";
import useDynamicState from "./utils/useDynamicState";
import {Loader} from "semantic-ui-react";

function SwitchLangPage({ lang }) {
    useEffect(()=>{
        setTimeout(()=>{
            setCurrentLangValue(lang.value)
            window.location = window.location.href.replace('/' + lang.value, '')
        }, 10);
    }, []);
    return <div>Redirect</div>;
}

export default function App() {
    const [context, setContext] = useState({
        indicatorsLastDateUpdate: new Date(),
        lastMessage: null,
        lastTimeResiveTostFromServer: null,
        backgroundTasksLastDateUpdate: new Date(),
    });
    
    const [wsState, setWsState, wsStateRef] = useDynamicState('closed')
    
    var currentReload = {
        load: null
    };

    let currentLangValue = getCurrentLangValue();
    if(currentLangValue === 'ru')
        registerLocale("ru", ru);
    else
        registerLocale("en", en);
    
    setDefaultLocale(currentLangValue);
    moment.locale(currentLangValue)
    
    const connectWebSocket = () => {
        let url = window.location.href.indexOf('http://localhost:3000') === 0 ?
            "http://localhost:5000/apphub" :
            (window.location.href.indexOf('http://localhost:') === 0) ? `${window.location.origin}/apphub` : `${window.location.protocol}//${appConfig().info.location}/api/apphub`
        ;
        let connection = new signalR.HubConnectionBuilder()
            .withUrl(url)
            .build();

        connection.on("Reload", data => {
            setTimeout(()=>{
                window.location =window.location;
            },3000);
        });

        connection.on("NeedUpdateIndicators", data => {
            setContext({...context, indicatorsLastDateUpdate: new Date()});
        });

        connection.on("BackgroundTasksChange", data => {
            setContext({...context, backgroundTasksLastDateUpdate: new Date()});
        });

        connection.on("DeleteChatChannel", data => {
            setContext({...context, indicatorsLastDateUpdate: new Date()});
        });

        connection.on("ChatMessage", data => {
            if(window.location.pathname !== '/chat'){
                toast.info(<div onClick={()=>{window.location = '/chat'}} className={'chat-message-toast'}>
                    <div className={'img'} style={{backgroundImage: `url("${getBackedUrl()}/api/avatar/byUser/${data.fromId}")`, }}/>
                    <div>
                        <div><b>{data.from}</b></div>
                        <div>{getElementsFromFormattedText(data.message)}</div>
                    </div>
                </div>);
            }
            setContext({...context, indicatorsLastDateUpdate: new Date(), lastMessage: data});
        });

        connection.on("KnowledgeNewsItem", data => {
            if(data != '')
                toast.info(<div><KnowledgeNewsItemView data={data}/></div>);
            setContext({...context, indicatorsLastDateUpdate: new Date()});
        });

        connection.on("ViewToast", data => {
            if(data != '')
                toast.info(<div>{getElementsFromFormattedText(data)}</div>);
            setContext({...context, lastTimeResiveTostFromServer: new Date()});
        });

        connection.onclose(() => {
            setWsState('closed')
        });
        setWsState('connecting')
        connection
            .start()
            .then(()=>{
                if(window.user !== undefined)
                    connection.invoke("JoinGroup", window.user.Id);
                setWsState('opened')
            })
            .catch(() => {
                setWsState('closed')
            });
    }
    
    useEffect(()=>{
        const link = document.createElement('link');
        link.href = '/img/logo192.png';
        link.rel = 'apple-touch-icon';

        document.getElementsByTagName('head')[0].appendChild(link);
        appConfig().info.additionCss.map(css=>{
            const link = document.createElement('link');
            link.href = css;
            link.rel = 'stylesheet';
            document.getElementsByTagName('head')[0].appendChild(link);
        });
        appConfig().info.additionScripts.map(script=>{
            const tag = document.createElement('script');
            tag.src = script;
            document.getElementsByTagName('head')[0].appendChild(tag);
        });
        let wsConnectTimer = setInterval(()=>{
            if(wsStateRef.current === 'closed'){
                connectWebSocket()
            }
        }, 1000)
        return ()=>{clearInterval(wsConnectTimer)}
    }, []);
    
    let iframePages = (appConfig().pages ?? []).filter(x=>x.type === 3);
    let pdfPages = (appConfig().pages ?? []).filter(x=>x.type === 6);
    let publicPages = (appConfig().publicForms ?? []);
    let langSwitchersForPublicPages = [];
    
    const langOptions = langs();

    publicPages.map(publicPage => {langOptions.map(lang => {
        langSwitchersForPublicPages.push({
            publicPage: publicPage,
            lang: lang
        });
    })});
    if(wsState !== 'opened'){
        return <Loader active/>
    }
    
    return (<ConfigurationContext.Provider value={context}><Router>
        <Header currentReload={currentReload}/>
        <Switch>
            <Route exact path="/" component={() => <Login />} />
            <Route exact path="/loginByToken/:token" component={() => <LoginByToken />} />
            <Route exact path="/introduction" component={() => <Introduction />} />
            {appConfig().info.useSwagger && <Route exact path="/swagger" component={() => <Swagger />} />}
            <Route exact path="/setNewPassword/:token" component={() => <SetNewPassword />} />
            <Route exact path="/passwordIsSet" component={() => <PasswordIsSetPage />} />
            <Route exact path="/ConfigurationViewer" component={() => <ConfigurationViewer currentReload={currentReload} />} />
            <Route exact path="/PythonTasksInstruction" component={() => <PythonTasksInstruction />} />
            
            <Route exact path="/knowledgeBase" component={() => <KnowledgeBase />} />
            <Route exact path="/knowledgeBase/:pageName+" component={() => <KnowledgeBase />} />
            <Route exact path="/meets" component={() => <Meets />} />
            <Route exact path="/emailClient" component={() => <EmailClient />} />
            <Route exact path="/calendar" component={() => <Calendar />} />
            <Route exact path="/chat" render={() => <Chat onNeedUpdateIndicators={()=>{setContext({...context, indicatorsLastDateUpdate: new Date()})}} />} />
            {langOptions.map(lang => <Route exact path={'/' + lang.value} component={() => <SwitchLangPage lang={lang} />} />)}
            
            {(appConfig().dictionaries ?? []).map(dictionary => dictionary.views.map(view =>
                <Route
                    exact
                    key={'view-route-' + dictionary.strictNameMany}
                    path={"/" + dictionary.nameSingle + 'View' + view.viewName}
                    render={ (props) => { return <ReportDictionary
                        {...props}
                        dictionary={dictionary}
                        view={view}
                        currentReload={currentReload}
                    />;} } />))}

            {(appConfig().powerBiReports ?? []).map(powerBiReport => <Route
                exact
                key={'route-report-' + powerBiReport.name}
                path={"/powerBi-" + powerBiReport.name}
                component={ (props) => {
                    return <PowerBiPage
                    {...props}
                    name={powerBiReport.name}
                />;} } />)}         
            
            {(appConfig().reports ?? []).map(report => <Route
                exact
                key={'route-report-' + report.strictName}
                path={"/report-" + report.strictName}
                component={ (props) => { return <ReportViewer
                    {...props}
                    name={report.strictName}
                    pages={report.pages}
                    dataColors={report.dataColors}
                    enumColors={appConfig().enums}
                    getData={(pageIndex, elementIndex, filtersValues)=>{return api().getValuesForReportElement(report.strictName, pageIndex, elementIndex, filtersValues);}}
                />;} } />)}

            {iframePages.map(page=> <Route
                exact
                key={'route-report-iframe-' + page.name}
                path={"/iframe-" + page.name}
                component={ (props) => { return <IFramePage
                    {...props}
                    page={page}
                />;} } />)}
            
            {pdfPages.map(page=> <Route
                exact
                key={'route-report-pdf-' + page.name}
                path={"/pdf-" + page.name}
                component={ (props) => { return <PdfPage
                    {...props}
                    page={page}
                />;} } />)}
            {publicPages.map(publicPage=> <Route
                exact
                key={'route-report-public-page-' + publicPage.name}
                path={publicPage.url}
                component={ (props) => { return <PublicPage
                    {...props}
                    page={publicPage}
                />;} } />)}
            {langSwitchersForPublicPages.map(langSwitcher => <Route
                exact
                key={'lang-switcher-for-public-page-' + langSwitcher.publicPage.name}
                path={(langSwitcher.publicPage.url + '/' + langSwitcher.lang.value)}
                component={ (props) => { return <SwitchLangPage lang={langSwitcher.lang} />;} } />)}
        </Switch>
        
        <ToastContainer
            draggable={false}
            position={toast.POSITION.BOTTOM_RIGHT}
            autoClose={4000}
            hideProgressBar={true}
        />
    </Router></ConfigurationContext.Provider>);
}