import React, {useEffect, useState} from 'react';
import {Button, Grid, Input} from 'semantic-ui-react';
import ForgotPasswordModal from "./components/ForgotPasswordModal";
import {Route} from "react-router-dom";
import t, {getCurrentLangValue} from "../../utils/translate";
import './login.css'
import api from "../../utils/api";
import {saveAuth} from "../../utils/authHelper";
import * as AuthHelper from "../../utils/authHelper";
import {getElementsFromFormattedText} from "../../utils/textHelper";
import md5 from "md5";
import LicenseInfoAlert from "./components/LicenseInfoAlert";
import LanguageSwitcher from "../../shared/components/LanguageSwitcher/LanguageSwitcher";
import QRCode from "react-qr-code";
import LicenseIsExpired from "./components/LicenseIsExpired";
import appConfig, {setAppConfig} from "../../utils/appConfig";

export default function Login() {
    let [login, changeLogin] = useState('')
    let [password, changePassword] = useState('')
    
    let [error, setError] = useState('')
    let [loginIsLoading, setLoginIsLoading] = useState(false)
    let [isDisable, setIsDisable] = useState(false)
    
    useEffect(()=>{
        if(AuthHelper.isLogged())
            afterLogin();
        if(appConfig().info.timeLimit !== undefined) {
            let date = appConfig().info.timeLimit.split('T')[0];

            let day = date.split('-')[2];
            let month = date.split('-')[1];
            let year = date.split('-')[0];
            let dateTime = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
            let diff = new Date().getTime() - dateTime.getTime();
            if(diff > 0) {
                setIsDisable(true);
                setError(t(''));
            }
        }
    },[]);
    
    let afterLogin = ()=>{
        api()
            .getConfiguration()
            .then(configuration=>{
                api()
                    .getById("Users", AuthHelper.getId())
                    .then(user=>{
                        setAppConfig(configuration)
                        window.user = user;
                        let permissions = AuthHelper.getPermissions();
                        let pages = appConfig()
                            .pages;
                        //.filter(x=> permissions.indexOf(x.permissionForView) !== -1);

                        if(pages == null || pages.length === 0)
                        {
                            setError(t('role_not_configured'));
                            setLoginIsLoading(false);
                            return;
                        }
                        var introductionPages = appConfig().info.introductionPages;

                        if(introductionPages.length > 0 && localStorage.getItem(('Introduction-' + md5(JSON.stringify(introductionPages)) + '_' + getCurrentLangValue() + '_' + window.user.Id)) === null) {
                            window.location = '/Introduction';
                            return;
                        }
                        let pageType =  appConfig()
                            .pages[0].type;
                        switch (pageType) {
                            case 0:
                            case 1:
                            case 2:
                                let singleName =  appConfig()
                                    .dictionaries.filter(x=>x.strictNameMany === pages[0].name.split('.')[0])[0].nameSingle;
                                window.location = '/' + (singleName + 'View' + pages[0].name.split('.')[1]);
                                break;
                            case 3:
                                window.location = '/' + ("iframe-" + pages[0].name);
                                break;
                            case 4:
                                var report = appConfig().reports.filter(x=>x.strictName == pages[0].name)[0];
                                window.location = '/' + ("report-" + report.strictName);
                                break;
                            case 5:
                                var powerBiReport = appConfig().powerBiReports.filter(x=>x.name == pages[0].name)[0];

                                window.location = '/' + ("powerBi-" + powerBiReport.name);
                                break;
                            case 6:
                                window.location = '/' + ("pdf-" + pages[0].name);
                                break;
                        }
                    });

            });
    };
    
    let loginClick = (history)=>{
        if(isDisable)
            return;
        if(loginIsLoading)
            return;
        
        if(login === ''){
            setError(t('login_is_empty'));
            return;
        }
        if(password === ''){
            setError(t('password_is_empty'));
            return;
        }
        
        setLoginIsLoading(true);
        api()
            .getToken(login, password)
            .then(resp => {
                if(resp.data != undefined && resp.data.accessToken != undefined){
                    saveAuth(resp.data);
                    
                    if(window.location.host === 'configurator.report.ms')
                        window.ym(65148439,'reachGoal','login_in_configurator');
                    
                    afterLogin();
                }else {
                    setError(t('wrong_user_or_password'));
                    setLoginIsLoading(false);
                }
            });
    };

    let projectName = getCurrentLangValue() === 'ru' ? appConfig().info.nameRu : appConfig().info.nameEn;
    return (<div className={'login-page-wrapper' + (t("login_welcome", appConfig()) === "" ? " empty-description" : "")} style={appConfig().info.loginBackgroundImageUrl !== '' ?  {'backgroundImage': `url(${appConfig().info.loginBackgroundImageUrl})`} : {}}>
        {appConfig().info.blurLoginBackgroundImageUrl ? <div className="blur"></div>: ''}
        <div className={"login-page-position-" + appConfig().info.loginPageType}>
            <Grid className={"login-form-wrapper"}>
                <Grid.Row>
                    <Grid.Column className="login-form-description">
                        <div>
                            {appConfig().info.logoUrl !== '' ? <img src={appConfig().info.logoUrl} title={projectName} alt={projectName} />:
                                <h2>{projectName}</h2>}
                        </div>
                        <div>
                            <p>{getElementsFromFormattedText(t("login_welcome", appConfig()))}</p>
                            <p>
                                {t("loginLink1") !== 'loginLink1' ? <a target={'_blank'} href={t("loginLink1Url")}>{t("loginLink1")}</a> : ''}{t("loginLink1") !== 'loginLink1' && t("loginLink2") !== 'loginLink2' ? ' | ' : ''}{t("loginLink2") !== 'loginLink2' ? <a target={'_blank'} href={t("loginLink2Url")}>{t("loginLink2")}</a> : ''}
                            </p>
                        </div>
                    </Grid.Column>
                    <Route render={({ history }) => (
                        <Grid.Column className="login-form-input-wrapper">
                            <div className="margin-bottom-10">
                                <Input
                                    disabled={isDisable}
                                    onKeyPress={(event)=>{if(event.key === 'Enter'){ loginClick(history); }}}
                                    onChange={(e) => {
                                    changeLogin(e.target.value);
                                    setError('');
                                }} value={login} icon='user' iconPosition='left' placeholder='Email' /></div>
                            <div className="margin-bottom-15">
                                <Input type="password"
                                       disabled={isDisable}
                                onKeyPress={(event)=>{if(event.key === 'Enter'){ loginClick(history); }}}
                                onChange={(e) => {
                                    changePassword(e.target.value);
                                    setError('');
                                }} value={password} icon='lock' iconPosition='left' placeholder={t("password_placeholder")} />
                            </div>
                            <div>
                                <ForgotPasswordModal>
                                    <a className="login-form-forgot-pass">
                                        {t("forgot_pass")}
                                    </a>
                                </ForgotPasswordModal>
                                <Button disabled={isDisable} loading={loginIsLoading} floated='right' onClick={() => { loginClick(history); }}>{t("login_btn")}</Button>
                            </div>
                            <div className="error">
                                {error}
                            </div>
                        </Grid.Column>
                    )} />
                </Grid.Row>
            </Grid>
        </div>
        {!(appConfig().info.hideQrLink) && <div className={'qr-code-link for-desktop'}>
            <QRCode value={window.location.origin} size={100} />
        </div>}
        {appConfig().info.useSwagger && <div className={'swagger-link-container'}>
            <a href={'/swagger'} target={'_blank'}><img src={'/img/swagger.svg'}/></a>
        </div>}
        <LanguageSwitcher />
        {isDisable && !appConfig().info.isDemo ? <LicenseIsExpired /> : ''}
        <LicenseInfoAlert />
    </div>);
}