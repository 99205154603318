import React, {useState} from 'react';
import './ImageViewer.css'
import {
    Button,
    Modal, ModalActions, ModalContent, ModalHeader
} from "semantic-ui-react";

export default function ImageViewer({open, setOpen, imageViewerSrc}) {
    const [scale, setScale] = useState(1)
    return <Modal
        //basic
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size='fullscreen'
        closeIcon={true}
        // onWheel={e=>{ 
        //     e.stopPropagation(); 
        //     e.preventDefault(); 
        //     setScale(oldScale => {return e.deltaY > 0 ? (oldScale === 1 ? oldScale :  oldScale - 1) : (oldScale > 15 ? oldScale : oldScale + 1);})
        // }}
    >
        <ModalHeader>Просмотр изображения</ModalHeader>
        <ModalContent>
            <div>
                <img style={{width: `${100 * scale}%`}} src={imageViewerSrc}/>
            </div>
        </ModalContent>
        <ModalActions>
            <Button
                basic
                onClick={()=>{setOpen(false)}}
            >Закрыть</Button>
        </ModalActions>
    </Modal>;
}