import React from 'react';
import {
    Dropdown
} from "semantic-ui-react";
import t from "../../../utils/translate";
import './ArrayLinkToDictionaryFormInput.scss'

export default function ArrayLinkToDictionaryFormInput({placeholder, name, value, column, onChangeFieldValue, readOnly, dictionaryItemsForSelects}) {
    let valForArray = value;
    if(value.length > 0)
        valForArray = value.map(x=> {
            if (x.id !== undefined && x.id !== null && x.id !== '')
                return x.id;
            return x;
        });
    return <div className={'dictionaries-input-container'}>
        <Dropdown
            key={'arr-col-' + dictionaryItemsForSelects.length + column.name}
            placeholder={placeholder}
            noResultsMessage={t('No results found.')}
            disabled={readOnly}
            clearable
            search
            options={dictionaryItemsForSelects}
            onChange={(e, data) => {
                if(e.stopPropagation !== undefined) {e.stopPropagation();}
                return onChangeFieldValue(name, data.value)}}
            value={valForArray}
            multiple
            selection />
    </div>
}