import React, {useState} from "react";
import './CardsItemsView.css'
import {getCurrentLangValue} from "../../../utils/translate";
import {Checkbox, Icon, Popup, Table} from "semantic-ui-react";
import {getId} from "../../../utils/authHelper";
import {ActionInvokerForGrid} from "../../ActionInvokerForGrid";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import appConfig from "../../../utils/appConfig";

export function CardsItemsView({view, dictionary, rows, openEdit, load, selectedIds, setSelectedIds}) {
    let collapsedColumnsStateKey = 'collapsedColumns' + getId() + '.' + dictionary.nameSingle + '.' + view.viewName;
    let [collapsedColumns, setCollapsedColumns] = useState(JSON.parse(localStorage.getItem(collapsedColumnsStateKey) ?? '[]'));
    let horizontalColumnNameForCardsView = view.horizontalColumnNameForCardsView;
    let col = dictionary.columns.filter(x=>x.name === horizontalColumnNameForCardsView)[0];
    let enumType = appConfig().enums.filter(x=>x.nameSingle === col.linkTo)[0];
    
    let renderField = (data, column, columnIndex) => {
        return <div style={{fontWeight: columnIndex === 0 ? 'bold' : 'normal'}}>{data[column.name]}</div>;
    };

    let values = enumType.values.map(x=> ({nameSingle: x.nameSingle, color: x.color, ruNameSingle: x.ruNameSingle}));
    values.unshift({nameSingle: 'Not set', ruNameSingle: 'Не установлено', color: ''});

    function needScroll(nameSingle) {
        let id = 'cards-column-content-for-' + nameSingle;
        let element = document.getElementById(id);
        if(element == null)
            return false;
        if(element.scrollHeight > (window.innerHeight - 220))
            return true
        return false;
    }

    return <div className={'cards-view'}>{values.map(cardCol => {
        let isCollapsed = collapsedColumns.includes(cardCol.nameSingle);
        let rowsForColumn = rows.filter(r => {return r.data[horizontalColumnNameForCardsView] === cardCol.nameSingle || (cardCol.nameSingle === 'Not set' && (r.data[horizontalColumnNameForCardsView] == null || r.data[horizontalColumnNameForCardsView] == ''));});
        return <div className={'cards-column' + (isCollapsed ? ' collapsed' : '')}>
            <div className={'collapse-column-container'} onClick={()=>{
                let temp = collapsedColumns.map(x=>x);
                if(temp.includes(cardCol.nameSingle))
                    temp = temp.filter(x=>x !== cardCol.nameSingle);
                else
                    temp.push(cardCol.nameSingle);
                
                setCollapsedColumns(temp);
                localStorage.setItem(collapsedColumnsStateKey, JSON.stringify(temp));
            }}><Icon name={'angle ' + (isCollapsed ? 'right' : 'left')}/></div>
            <div className={'cards-column-header'}>{cardCol.color != '' ? <Icon name={"circle"} color={cardCol.color} /> : ''} <b>{getCurrentLangValue() === 'ru' ? cardCol.ruNameSingle : cardCol.nameSingle} {rowsForColumn.length > 0 ? ` (${rowsForColumn.length})` : ''}</b></div>
            {isCollapsed ? '' : <div id={'cards-column-content-for-' + cardCol.nameSingle} className={'cards-column-content' + (needScroll(cardCol.nameSingle) ? ' with-scroll' : '')}>
                {rowsForColumn
                    .map(r=> <div
                        className={'card-content'  + (view.rowActions ? ' with-action-invoker' : '') + (view.selectable ? ' with-selectable-checkbox' : '') + (view.viewId ? ' with-id-value' : '') + (view.viewCreateAt ? ' with-create-at' : '')}
                        style={{cursor: view.canOpenEditModal ? 'pointer' : 'default'}}
                        onClick={()=>{if(view.canOpenEditModal){openEdit(r.data)}}}
                    >
                        {view.viewId ? <div className={'id-value-container'}><Popup basic inverted flowing trigger={<span>{r.data.Id.substr(0, 7) + '...'}</span>} content={r.data.Id} /><CopyToClipboard onCopy={()=>{
                            toast.info(getCurrentLangValue() === 'ru' ? 'Идентификатор скопирован в буфер обмена!' : 'Id copied to the clipboard!');
                        }} text={r.data.Id}>
                            <Icon name={'copy'} onClick={(e)=>{
                                e.stopPropagation();
                            }}/>
                        </CopyToClipboard></div> : ''}
                        {view.viewCreateAt ? <div className={'create-at-container'}>
                            <Popup basic content={r.data.createAtHumanView} trigger={<span>{r.data.createAtNew}</span>} />
                        </div> : ''}
                        
                        {view.columnNamesForDisplay.length > 0 ? view.columnNamesForDisplay
                                .map(colName => {return dictionary.columns.filter(x=>x.name === colName)[0];}).map((x, i)=> {return renderField(r.data, x, i)}) :
                            dictionary.columns.map((x, i)=> {return renderField(r.data, x, i)})}
                        {/*r.data.Id*/}
                        {view.selectable ? <div className={'selectable-checkbox-container'}>
                            <Checkbox
                                checked={selectedIds.filter(x=>x === r.data.Id ).length > 0}
                                onChange={(e, { checked })=>{
                                    e.stopPropagation();
                                    //debugger;
                                    let result = [...selectedIds];
                                    if(checked){
                                        result.push(r.data.Id);
                                        setSelectedIds(result);
                                    }
                                    else{
                                        result = selectedIds.filter(x => x !== r.data.Id);
                                        setSelectedIds(result);
                                    }
                                }} />
                        </div>: ''}
                        {view.rowActions ? <div className={'action-invoker-container'}>
                            <ActionInvokerForGrid id={r.data.Id} afterInvoke={()=>{load();}} dictionaryStrictNameMany={dictionary.strictNameMany}/>
                        </div>: ''}
                    </div>)}
            </div>}
        </div>;
    })}</div>;
}