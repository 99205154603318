import React, {useRef} from 'react';
import {Button, Icon, Popup} from "semantic-ui-react";
import api from "../../../utils/api";
import t from "../../../utils/translate";
import './FileFormInput.css'
import moment from "moment";
import getBackedUrl from "../../../utils/getBackedUrl";

export default function FileFormInput({name, value, column, onChangeFieldValue, readOnly}) {
    let fileInputRef = useRef();

    function getButtonForValue(value) {
        let parsedValue = JSON.parse(value);
        let createAt = moment(new Date(parsedValue.createAt));
        return  <Popup on={'click'} content={<div style={{width: '300px', textAlign: 'center'}}>
            <Button basic color='blue' onClick={() => {
                api()
                    .createDownloadToken(parsedValue.id, parsedValue.name)
                    .then(resp => {
                        if (resp.token !== undefined && resp.token !== '') {
                            window.open(`${getBackedUrl()}/api/file/downloadBy?token=${resp.token}`, '_blank');
                        }
                    })
            }}><Icon name='download' /> Скачать</Button>
            <Button basic color='red' onClick={() => {onChangeFieldValue(name, '');}}><Icon name='remove' /> Удалить</Button>
        </div>} position={"top center"} trigger={<Button
            className={'file-form-input-button'}
            content={<div><div>{parsedValue.name}</div><div className={'file-create-at'}>{createAt.format('DD.MM.YYYY HH:mm:ss')}</div></div>}
            labelPosition="left"
            icon="file"
        />} />;
    }

    return (value == null || value === '' ? <>
        {readOnly ? <Button
            disabled
            className={'file-form-input-button'}
            content={t('Empty')}
            labelPosition="left"
            icon="file"
        /> : <>
            <Button
                className={'file-form-input-button'}
                content={t('Choose file')}
                labelPosition="left"
                icon="file"
                onClick={() => fileInputRef.current.click()}
            />
            <input
                type="file"
                hidden
                ref={fileInputRef}
                //value={value}
                onChange={(e) => { return api().uploadFile(e.target.files[0]).then((resp)=> {
                    onChangeFieldValue(name, JSON.stringify(resp));
                })}}//this.fileChange
                placeholder={column.example} />        
        </>}
    </> : getButtonForValue(value));
}