import React, {useEffect, useState} from 'react';
import './HistoryModal.scss'
import {
    Button, ButtonGroup,
    Grid,
    Icon, Loader, Modal
} from "semantic-ui-react";
import api from "../../../../../../utils/api";
import {getCurrentLangValue} from "../../../../../../utils/translate";
import {useWindowSize} from "../../../../../../utils/windowSize";
import ReactDiffViewer from 'react-diff-viewer';
import KnowledgeBasePageMarkdownView from "../../../KnowledgeBasePageMarkdownView/KnowledgeBasePageMarkdownView";
import ViewRelativeTime from "../../../../../../shared/components/ViewRelativeTime/ViewRelativeTime";
import getBackedUrl from "../../../../../../utils/getBackedUrl";

export default function HistoryModal({page, open, setOpen}) {
    const [items, setItems] = useState([])
    const [currentHistoryItem, setCurrentHistoryItem] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingItem, setIsLoadingItem] = useState(false)
    const [viewType, setViewType] = useState('content')
    const [width, height] = useWindowSize();
    const pageId = page.id
    
    const loadItem = (itemId) => {
        setIsLoadingItem(true)
        api()
            .getKnowledgeBasePageHistoryItem(itemId)
            .then(resp=>{
                setCurrentHistoryItem(resp.data)
                setIsLoadingItem(false)
                setViewType('content')
            })
    }
    const loadItems = () => {
        if(open){
            setIsLoading(true)
            api()
                .getKnowledgeBasePageHistoryItems(pageId)
                .then(resp=>{
                    setItems(resp.data)
                    loadItem(resp.data[0].id)
                    setIsLoading(false)
                })
        }
    };
    useEffect(loadItems, [page, open])

    if(isLoading) return <Loader active />
    
    let currentLangValue = getCurrentLangValue();

    return <Modal
        open={open}
        onClose={() => setOpen(false)}
        size={'fullscreen'}
        centered={false}
        closeIcon={true}
        onClick={e=>{
            e.preventDefault()
            e.stopPropagation()
        }}
    >
        <Modal.Header>История страницы "{page.title}"</Modal.Header>
        <Modal.Content className={'knowledge-base-history-modal'} style={{height: `${height - 180}px`}}>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={3} className={'menu-history'}>
                        {items.map(item => <div 
                            onClick={()=>{loadItem(item.id)}} 
                            className={'history-item' + (currentHistoryItem?.id == item.id ? ' active' : '')}
                        >
                            <div className={'avatar'} style={{backgroundImage: `url("${getBackedUrl()}/api/avatar/byUser/${item.userId}")`}}></div>
                            <div className={'name'}>{item.userName}</div>
                            {item.dateTime != null && item.dateTime != '' ? 
                                <ViewRelativeTime dateTime={item.dateTime}/>
                             : ''}
                        </div>)}
                    </Grid.Column>
                    <Grid.Column width={13}>
                        {isLoadingItem ? <Loader active /> : (currentHistoryItem != null ? <div>
                            {currentHistoryItem.titleAfter == currentHistoryItem.titleBefore ? <div>
                                <h3>{currentHistoryItem.titleAfter}</h3>
                            </div> : <div>
                                <h3>{currentHistoryItem.titleBefore} <Icon name={'arrow right'}/> {currentHistoryItem.titleAfter}</h3>
                            </div>}
                            <ButtonGroup fluid size={'mini'}>
                                <Button 
                                    primary={viewType === 'content'} 
                                    onClick={()=>{setViewType('content')}}><Icon name={'file'} pri/> Содержание</Button>
                                <Button  
                                    primary={viewType === 'diff'} 
                                    onClick={()=>{setViewType('diff')}}><Icon name={'exchange'}/> Сравнить</Button>
                            </ButtonGroup>
                            <div style={{overflowX: 'auto', height: `${height - 250}px`}}>
                                {viewType == 'content' ? <div className={'custom-html-style'}><KnowledgeBasePageMarkdownView content={currentHistoryItem.contentAfter ?? ''} /></div> : ''}
                                {viewType == 'diff' ? <div style={{overflowY: 'auto'}}>
                                    <ReactDiffViewer
                                        styles={{}}
                                        oldValue={currentHistoryItem.contentBefore ?? ''}
                                        newValue={currentHistoryItem.contentAfter ?? ''}
                                        splitView={false}
                                    />
                                </div> : ''}
                            </div>
                        </div> : '')}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={() => setOpen(false)} basic>
                Закрыть
            </Button>
        </Modal.Actions>
    </Modal>;
}