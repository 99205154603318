import React, {useState} from 'react';
import {
    Button,
    Form, Grid, Header,
    Icon,
    Input,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader
} from "semantic-ui-react";
import t, {getCurrentLangValue} from "../../../utils/translate";
import './DurationFormInput.css'
import customHumanizeDuration, {
    minutesInDay, minutesInHour,
    minutesInMonth,
    minutesInYear
} from "../../../utils/customHumanizeDuration";

function convertMinutes(totalMinutes) {
    const years = Math.floor(totalMinutes / minutesInYear);
    totalMinutes %= minutesInYear;

    const months = Math.floor(totalMinutes / minutesInMonth);
    totalMinutes %= minutesInMonth;

    const days = Math.floor(totalMinutes / minutesInDay);
    totalMinutes %= minutesInDay;

    const hours = Math.floor(totalMinutes / minutesInHour);
    totalMinutes %= minutesInHour;

    const minutes = totalMinutes;

    return {
        years,
        months,
        days,
        hours,
        minutes
    };
}

export default function DurationFormInput({name, value, column, onChangeFieldValue, readOnly}) {
    const [openEditModal, setOpenEditModal] = useState(false)
    const [years, setYears] = useState(0)
    const [months, setMonths] = useState(0)
    const [days, setDays] = useState(0)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    
    
    const onOpen = () => {
        if(readOnly)
            return
        setOpenEditModal(true)
        let totalMinutes = value ?? 0
        setMinutes(convertMinutes(totalMinutes).minutes)
        setHours(convertMinutes(totalMinutes).hours)
        setDays(convertMinutes(totalMinutes).days)
        setMonths(convertMinutes(totalMinutes).months)
        setYears(convertMinutes(totalMinutes).years)
    }
    
    const save = () => {
        onChangeFieldValue(name, years * 525600 + months * 44640 + days * 1440 + hours * 60 + minutes)
        setOpenEditModal(false)
    }
    return <><div className={'duration-input-container'}>
            <Input
                disabled={readOnly}
                value={value === null || value === '' ? '' : customHumanizeDuration(value)}
                onFocus={onOpen}
                icon={'time'} />
            {value === null || value === '' ? '' : (readOnly ? '' : <Icon className={'clear-btn'} name={'close'} onClick={()=>{onChangeFieldValue(name, null)}} />)}
        </div>
        <Modal
            size={"tiny"}
            open={openEditModal}
            onClick={e => e.stopPropagation()}
            closeIcon
            onClose={() => {
                setOpenEditModal(false);
            }}
            centered={false}
        >
            <ModalHeader>{t('set_duration_modal_title')}</ModalHeader>
            <ModalContent>
                <Form>
                    <Grid>
                        <Grid.Row columns={5}>
                            {[
                                {name: t('set_duration_modal_years'), value: years, setValue: setYears},
                                {name: t('set_duration_modal_months'), value: months, setValue: setMonths},
                                {name: t('set_duration_modal_days'), value: days, setValue: setDays},
                                {name: t('set_duration_modal_hours'), value: hours, setValue: setHours},
                                {name: t('set_duration_modal_minutes'), value: minutes, setValue: setMinutes},
                            ].map(col=><Grid.Column>
                                <Form.Field>
                                    <label>{col.name}</label>
                                    <Input 
                                        value={col.value} 
                                        type={'number'} 
                                        onChange={e => col.setValue(parseInt(e.target.value))}
                                        max={100}
                                        min={0}
                                    />
                                </Form.Field>
                            </Grid.Column>)}
                        </Grid.Row>
                    </Grid>
                </Form>
                <Header size='medium'>{customHumanizeDuration(years * 525600 + months * 44640 + days * 1440 + hours * 60 + minutes)}</Header>
            </ModalContent>
            <ModalActions>
                <Button
                    onClick={()=>{setOpenEditModal(false)}}
                    content={t('Cancel')}
                    basic
                />
                <Button
                    onClick={save}
                    positive
                    icon='checkmark'
                    labelPosition='right'
                    content={t('set_duration_btn')}
                />
            </ModalActions>
        </Modal>
    </>
}