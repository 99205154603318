import React from 'react';
import {Button} from "semantic-ui-react";
//import rssEnhancer from "react-rss";
import ReportClock from "./ReportClock";
import Rss from "./Rss";
import RSSComponent from "./Rss";
import {getCurrentLangValue} from "../../../../utils/translate";

const DefaultRSSComponent = (props) => {
    debugger;
    return <div>
        <h2>{props.label}</h2>
        <a href={props.rss.header.link}>
            {props.rss.header.title}
        </a>
        <ul>
            {props.rss.items.map(item => (
                <li>
                    {item.description}
                </li>
            ))}
        </ul>
    </div>;
};

const WebElement = ({isEdit, getData, pageIndex, elementIndex, element, width, height, onePercentInPixelForWidth, onePercentInPixelForHeight}) => {
    
    switch (element.webElementType) {
        case 'IFrame':
            return <iframe src={element.webElementUrl} width={width} height={height - 10} style={{border: 'none', marginTop: '10px'}} />;
        case 'Link':
            let buttonText = null;
            if(isEdit){
                buttonText = element.buttonText ?? null;
            }else{
                buttonText = (getCurrentLangValue() === 'ru' ? element.buttonText : element.enButtonText) ?? null;
            }
            
            return <Button as='a' href={element.webElementUrl} target={'_blank'}>
                <Button.Content >{buttonText ?? element.webElementUrl}</Button.Content>
            </Button>;
        case 'Rss':
             return <div style={{width: width + "px", height: height + 'px', overflowY: 'scroll'}}><RSSComponent
                 url={element.webElementUrl}
             /></div>;
         case 'Clock':
             return <ReportClock useAnalogClock={element.useAnalogClock} width={width} height={height} onePercentInPixelForWidth={onePercentInPixelForWidth}/>;
    }

    return <></>;
}

export default WebElement