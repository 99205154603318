import React from 'react';
import {Button, Message, Modal} from "semantic-ui-react";
import FolderViewerForFolderSelector from "./components/FolderViewerForFolderSelector/FolderViewerForFolderSelector";

export default function KnowledgeBaseFolderSelector({page, open, setOpen, onSelect}) {
    return <Modal
        open={open}
        onClose={() => setOpen(false)}
        size={"mini"}
        onClick={e=>{
            e.preventDefault()
            e.stopPropagation()
        }}
    >
        <Modal.Header>Выберите папку</Modal.Header>
        <Modal.Content>
            <Message>
                Укажите папку куда нужно переместить эту {(page.isFolder ? 'папку со всем содержимым' : 'страницу')}.
            </Message>
            <FolderViewerForFolderSelector
                directory={'/'}
                onSelect={onSelect}
            />
            <Button
                primary
                size={'mini'}
                fluid
                style={{marginTop: '15px'}}
                onClick={()=>{onSelect({url: '/'})}}
            >Выбрать корневую папку</Button>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={() => setOpen(false)} basic>
                Отмена
            </Button>
        </Modal.Actions>
    </Modal>;
}