export default function reportColumnTypes() {
    return {
        string: "string",
        text: "text",
        linkToDictionary: "linkToDictionary",
        arrayLinkToDictionary: "arrayLinkToDictionary",
        arrayString: "arrayString",
        arrayImage: "arrayImage",
        arrayLinkToEnum: "arrayLinkToEnum",
        linkToEnum: "linkToEnum",
        innerTable: "innerTable",
        passwordHash: "password-hash",
        calculateFieldEnum: "calculate-field:enum",
        calculateFieldString: "calculate-field:string",
        roleSelect: "roleSelect",
        dateTime: "dateTime",
        date: "date",
        time: "time",//todo: need resolve
        duration: "duration",
        number: "number",//todo: need resolve
        decimal: "decimal",
        int: "int",
        bool: "bool",
        codeSql: "code:sql",
        codePython: "code:python",
        codeJson: "code:json",
        iconPicker: "icon-picker",
        file: "file",
        avatar: "avatar",
        image: "image",
        savedPassword: "saved-password",

        markdowndEditor: "markdownd-editor",
        templateEditor: "template-editor",//todo: need resolve
        fieldSelectorForReport: "field-selector-for-report",
        fieldSelectorForReportWithoutFunctor: "field-selector-for-report-without-functor",
        fieldSelectorForReportDateTimeOnly: "field-selector-for-report-date-time-only",
        reportExpression: "report-expression",
        reportExpressionForSelectRestriction: "report-expression-for-select-restriction",
        variableSelect: "variable-select",
        imageSelector: "image-selector",
        colorPicker: "color-picker",
        reportEditor: "report-editor",
    };
}