import React from "react";
import reportColumnTypes from "./columnTypes";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import appConfig from "./appConfig";

export function getElementsFromFormattedText(text) {
    return <ReactMarkdown linkTarget={'_blank'} remarkPlugins={[remarkGfm]} className={'markdown-display-container'}>{text}</ReactMarkdown>;
}


export function getForSelectRestriction(text, contextDictionary, linkToDictionaryManyName, data) {
    if(text === undefined || text == null)
        return '';
    let replacedText = text;
    
    let linkToDictionary = appConfig().dictionaries.filter(x => x.strictNameMany === linkToDictionaryManyName)[0];

    for(var i = 0; i < linkToDictionary.columns.length; i++){
        replacedText = replacedText.replaceAll(`"${linkToDictionary.nameSingle}"."${linkToDictionary.columns[i].name}"`, `"${linkToDictionary.columns[i].name}"`);
    }

    if(Array.isArray(data)){
        let id = data.filter(x=>x.name === 'Id')[0]?.value;
        replacedText = replacedText.replaceAll(`"THIS"."Id"`, (id !== undefined && id !== null) ?  `'${id}'` : `null`);
    }else{
        replacedText = replacedText.replaceAll(`"THIS"."Id"`, (data !== undefined && data["Id"] !== undefined) ?  `'${data["Id"]}'` : `null`);
    }
    
    //debugger;
    for(let colIndex in contextDictionary.columns){
        let col = contextDictionary.columns[colIndex];
        if((data !== undefined && 
            data[col.name] !== undefined) || 
            (Array.isArray(data) && data.filter(x=>x.name === col.name).length > 0 && data.filter(x=>x.name === col.name)[0].value !== '' && data.filter(x=>x.name === col.name)[0].value !== null)){
            
            let val = data[col.name];
            if(Array.isArray(data))
                val = data.filter(x=>x.name === col.name)[0].value;
                
            if(col.type === reportColumnTypes().linkToDictionary && !Array.isArray(data)){
                val = val?.id;
            }
            replacedText = replacedText.replaceAll(`"${contextDictionary.nameSingle}"."${col.name}"`, `'${val}'`);
            replacedText = replacedText.replaceAll(`"THIS"."${col.name}"`, `'${val}'`);
        }
        else
            replacedText = replacedText.replaceAll(`"${contextDictionary.nameSingle}"."${col.name}"`, `null`);
            replacedText = replacedText.replaceAll(`"THIS"."${col.name}"`, `null`);
    }
    //for(let field in data){
    //    replacedText = replacedText.replaceAll(`"${contextDictionary.nameSingle}"."${field}"`, `'${data[field]}'`);
    //}
    
    
    
    console.log("SelectRestriction for " + contextDictionary.nameSingle);
    console.log(replacedText);
    console.log(data);

    if(text == '"THIS"."DictionaryId" = "DictionaryField"."DictionaryId"') debugger;
    return replacedText;
}