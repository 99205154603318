import React from 'react';
import './Context.scss'

import {Divider, Header} from "semantic-ui-react";
import CodeExample from "../CodeExample/CodeExample";

const Context = () => {
    return <div className={'block'}>
        <Divider />
        <Header as='h2' id={'context'}>Контекст</Header>
        <p>
            Для контекста вам доступен объекты <b>app.context_</b>.
        </p>

        <Header as='h3' id={'context-dictionaty'}>Контекст: Объект справочника</Header>
        <p>
            Для доступа к текущему объекту используйте свойство <b>context_object</b> объекта <b>app</b>.
        </p>
        <CodeExample title={'Текущий объект выполнения'}
                     code={'value = app.context_object.get(\'Поле объекта\')\nprint(value)'}/>

        <Header as='h3' id={'context-user'}>Контекст: Пользователь</Header>
        <p>
            Для доступа к текущему пользователю используйте свойство <b>context_user</b> объекта <b>app</b>.
        </p>
        <CodeExample title={'Отправка уведомления текущему пользователю'}
                     code={'user = app.context_user\napp.notify(user, \'Уведомление!\')'}/>

        <Header as='h3' id={'context-parameters'}>Контекст: Параметры</Header>
        <p>
            Для доступа к текущим параметрам используйте свойство <b>context_parameters</b> объекта <b>app</b>.
        </p>
        <CodeExample title={'Доступ к текущему параметру'}
                     code={'parameter_value = app.context_parameters.get(\'Имя параметра\')\nprint(parameter_value)'}/>
    </div>;
}

export default Context