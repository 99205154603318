import React from 'react';
import './Enums.scss'

import {Divider, Header} from "semantic-ui-react";
import CodeExample from "../CodeExample/CodeExample";

const Enums = () => {
    return <div className={'block'}>
        <Divider />
        <Header as='h2' id={'enums'}>Как работать с перечислениями</Header>
        <p>
            
        </p>
        <CodeExample title={'Получить значение перечисления'}
                     code={'my_enum_value = app.enum(\'Моё перечисление\', \'Моё значение\')'}/>
        
        <Header as='h3' id={'enums-search'}>Перечисления: Поиск</Header>
        <p>
        </p>
        <CodeExample title={'Поиск в участием перечисления'}
                     code={'value = app.dictionary(\'Мой справочник\').find({\'Поле перечисления\': app.enum(\'Моё перечисление\', \'Моё значение\')})[0]\nprint(value)'}/>

        <Header as='h3' id={'enums-set'}>Перечисления: Присваивание</Header>
        <p>
        </p>
        <CodeExample title={'Поиск элементов справочника'}
                     code={'value = app.dictionary(\'Мой справочник\').find()[0]\nvalue.set(\'Поле перечисления\', app.enum(\'Моё перечисление\', \'Моё значение\'))'}/>
    </div>;
}

export default Enums