import {
    Button,
    Grid,
    Icon,
    Input,
    Modal, Popup,
} from "semantic-ui-react";
import React, {useRef, useState} from "react";

import "./ImageSelector.css";
import RecognitionInput from "../../components/RecognitionInput";
import api from "../../../utils/api";
import getBackedUrl from "../../../utils/getBackedUrl";

export function ImageSelector({
                                  value,
                                  onChange,
                                  disabled,
                                  placeholder
}) {
    let [isOpenImageSelector, setIsOpenImageSelector] = useState(false);
    let [isOpenFileSelector, setIsOpenFileSelector] = useState(false);
    let [fileValue, setFileValue] = useState(null);
    let [searchValue, setSearchValue] = useState('');
    let [results, setResults] = useState([]);

    let fileInputRef = useRef();
    
    let search = () => {
        return fetch(`${getBackedUrl()}/api/fromBackgroundInput/search?query=${searchValue}`, {
            method: 'Get',
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        }).then(response => {
            if (response.ok) {
                return response.text().then(function (text) {
                    return ({text: text, response: response});
                });
            } else {
                throw new Error('Fetch error');
            }
        }).then(resp=> {setResults(JSON.parse(resp.text));});
    }; 

    let openImageSelector = ()=>{
        setIsOpenImageSelector(true);
    };
    let openFileSelector = ()=>{
        setIsOpenFileSelector(true);
    };
    let valueIsNotNull = value !== undefined && value !== null && value !== '';
    return <div style={{position: 'relative'}}>
        <Input
        className={'image-select-input ' + (value !== undefined && value !== null && value !== '' ? 'not-empty' : '')}
        disabled={disabled}
        value={value}
        onChange={onChange}
        placeholder={placeholder}/>

        {valueIsNotNull ? <Popup
            content={<div style={{width: '150px', height: '100px', overflow: "hidden"}}><img src={value} style={{width: '100%'}} /></div>}
            on='click'
            trigger={ <Icon name={'eye'} style={{position: 'absolute', left: '3px', top: '9px'}}/>}
        /> : ''}
        {!valueIsNotNull ? <Icon onClick={() => fileInputRef.current.click()} name={'upload'} style={{position: 'absolute', right: '25px', top: '9px'}}/>: ''}
        {!valueIsNotNull ? <input
            type="file"
            hidden
            ref={fileInputRef}
            onChange={(e) => { return api().uploadFile(e.target.files[0], '/api/uploadToStatic').then((resp)=> {
                onChange({target: {value: resp.url}});
                //onChangeFieldValue(name, JSON.stringify(resp));
            })}}/>: ''}

        {!valueIsNotNull ? <Icon onClick={openImageSelector} name={'image'} style={{position: 'absolute', right: '5px', top: '9px'}}/>: ''}
        {valueIsNotNull ? <Icon onClick={()=>{onChange({target: {value: ''}})}} name={'remove'} style={{position: 'absolute', right: '5px', top: '9px'}}/>: ''}
        
        <Modal
            onClose={() => setIsOpenImageSelector(false)}
            onOpen={() => setIsOpenImageSelector(true)}
            open={isOpenImageSelector}
        >
            <Modal.Header>Выберите фото</Modal.Header>
            <Modal.Content className={'search-image-modal'}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={14}>
                            <RecognitionInput
                                style={{width: '100%'}}
                                value={searchValue}
                                onChange={(e)=>{setSearchValue(e.target.value);}}
                                placeholder={'Поиск'}/>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Button onClick={search}>Искать</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <div className={'results'}>
                    {results.map(image=><div><img src={image} onClick={()=>{
                        setIsOpenImageSelector(false);
                        setSearchValue('');
                        setResults([]);
                        onChange({target: {value: image}});
                    }}/></div>)}
                </div>
            </Modal.Content>
        </Modal>
    </div>;
}