import React from 'react';
import './PasswordIsSetPage.css'
import {
    Button,
    Grid,
    Message
} from "semantic-ui-react";
import t from "../../utils/translate";

export default function PasswordIsSetPage() {
    return (<div className={'ui container PasswordIsSetPage-page-container'}>
        <Grid>
            <Grid.Row>
                <Grid.Column width={5}/>
                <Grid.Column width={6}>
                    <Message>
                        <p>{t('set_password_page_finish_message')}</p>
                        <Button onClick={()=>{
                            window.location = '/';
                        }}>{t('set_password_page_finish_btn')}</Button>
                    </Message>
                </Grid.Column>
                <Grid.Column width={5}/>
            </Grid.Row>
        </Grid>
    </div>);
}