import React, {useContext, useEffect, useState} from 'react';
import {
    Button, Checkbox,
    Container,
    Dropdown,
    Grid,
    Icon,
    Loader,
    Message,
    Popup,
    Search,
    Table,
    Visibility
} from "semantic-ui-react";
import t, {getCurrentLangValue} from "../utils/translate";
import api from "../utils/api";
import reportColumnTypes from "../utils/columnTypes";
import "./reportDictionary.css";
import DictionaryItemEdit from "./components/DictionaryItemEdit";
import columnValueGetter from "../utils/columnValueGetter";
import {getElementsFromFormattedText} from "../utils/textHelper";
import {ActionButton} from "./components/ActionButton";
import {UploadFromExcel} from "./components/UploadFromExcel";
import {calculateExpression} from "../utils/expressionHelper";
import {ActionInvokerForGrid} from "./ActionInvokerForGrid";
import moment from "moment";
import {DictionaryHeaderCell} from "./components/DictionaryHeaderCell/DictionaryHeaderCell";
import {getId} from "../utils/authHelper";
import {toast} from "react-toastify";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ExportToExcel} from "./components/ExportToExcel";
import {FilterForm} from "./components/DictionaryHeaderCell/components/FilterForm";
import {CardsItemsView} from "./components/CardsItemsView/CardsItemsView";
import { ConfigurationContext } from '../utils/configurationContext';
import appConfig from "../utils/appConfig";


export default function ReportDictionary({dictionary, currentReload, view}) {
    const context = useContext(ConfigurationContext);
    let [lastTimeResiveTostFromServer, setLastTimeResiveTostFromServer] = useState(null);
    let [searchQueryValue, setSearchQueryValue] = useState('');
    let [totalCount, setTotalCount] = useState(0);
    let [queryResult, setQueryResult] = useState(null);
    let [rows, setRows] = useState(null);
    let [selectedIds, setSelectedIds] = useState([]);
    let [isLoadingList, setIsLoadingList] = useState(false);
    let [loadedActions, setLoadedActions] = useState([]);
    let [page, setPage] = useState(0);
    let [lastLoadedPage, setLastLoadedPage] = useState(null);
    let [isScrolling, setIsScrolling] = useState(false);
    let [scrollTop, setScrollTop] = useState(0);
    let keyForSort = getId() + '.' + dictionary.nameSingle + '.' + view.viewName;
    let filterStateKey = 'FilterState' + getId() + '.' + dictionary.nameSingle + '.' + view.viewName;
    
    let viewType = view.viewType;

    let sortValue = localStorage.getItem(keyForSort);
    let initialState = sortValue === undefined || sortValue === null  ? '' : keyForSort + '.' + sortValue;
    let [currentSort, setCurrentSort] = useState(initialState);
    let [currentFilter, setCurrentFilterPrivate] = useState(JSON.parse(localStorage.getItem(filterStateKey) ?? '[]'));
    let setCurrentFilter = (val)=>{setCurrentFilterPrivate(val);localStorage.setItem(filterStateKey, JSON.stringify(val))};
    let [globalFilterColumn, setGlobalFilterColumn] = useState(null);
    let [openedFilter, setOpenedFilter] = useState(null);
    
    let [isOpenEditCreateModal, setIsOpenEditCreateModal] = useState(false);
    let [editData, setEditData] = useState(null);
    let [searchLoading, setSearchLoading] = useState(false);
    
    let [isOpenSumInfo, setIsOpenSumInfo] = useState(false);
    let [sumValues, setSumValues] = useState([]);
    
    
    let setCurrentSortAndRefresh = (sort) => {
        setCurrentSort(sort);
        load({
            sort: sort
        });
    };
    let [itemActions, setItemActions] = useState([]);

    let reportTypes = reportColumnTypes();

    let description = view.description !== null ? 'ViewDescription_' + view.description : dictionary.description;
    let nameSingle = view.nameSingle !== null ? 'ViewNameSingle_' + view.nameSingle :  'NameSingle_' + dictionary.nameSingle;
    
    let createButtonText = view.createBtnOverrideName != null && view.createBtnOverrideName !== undefined ? 
        <>{t(view.createBtnOverrideName)}</> : 
        <>{t('Create')} {t(nameSingle)}</>;
    let nameMany = view.nameMany !== null ? 'ViewNameMany_' + view.nameMany :  'NameMany_' + dictionary.nameMany;
    let imageForDescription = view.imageForDescription !== null  ? view.imageForDescription :  dictionary.imageForDescription;
    let onBottomVisible = ()=>{
        if(isLoadingList)
            return;
        
        let newPage = page + 1;
        if(newPage * 30 > totalCount && page !== 0){
            return;
        }
        setPage(newPage);
        load({page: newPage});
    }; 

    let load = (loadProps) => {
        loadProps = loadProps ?? {};
        loadProps.sort = loadProps.sort ?? currentSort; 
        loadProps.filter = loadProps.filter ?? currentFilter; 
        loadProps.withoutLoader = loadProps.withoutLoader ?? false; 

        if(!loadProps.withoutLoader)
            setIsLoadingList(true);

        let queryForRequest = loadProps.query ?? searchQueryValue;
        let pageForRequest = loadProps.page ?? page;
        api().getListForView(dictionary.strictNameMany, pageForRequest, loadProps.filter, view.viewName, loadProps.sort, queryForRequest)
            .then(getListResult => {
                for (var i = 0; i < dictionary.backlights.length; i ++) {
                    let backlight = dictionary.backlights[i];
                    let condition = backlight.condition;
                    getListResult.rows = getListResult.rows.map(row => {
                        let result = calculateExpression(condition, dictionary, [], row);
                        if(result){
                            if(row.backlights == undefined)
                                row.backlights = [];
                            row.backlights.push({
                                name: backlight.name,
                                color: backlight.color,
                            });
                        }
                        
                        return row;
                    });
                }
                let loadedRows = getListResult.rows.map(row=>{
                    row.createAtNew = moment.utc(row.CreateAt).from(new Date( moment.utc().format() ));
                    row.createAtHumanView = moment(new Date(new Date(row.CreateAt).getTime() - new Date().getTimezoneOffset() * 60000)).format(getCurrentLangValue() === 'ru' ? "DD.MM.yyyy HH:mm" : "MMMM D, yyyy h:mm a");// moment.utc(n.createAt).format(currentLangValue === 'ru' ? "DD.MM.yyyy HH:mm" : "MMMM D, yyyy h:mm a");
                    return{data: row, actions: []};
                });
                
                let oldRows = rows == null ? [] : rows;
                if(lastLoadedPage === pageForRequest && pageForRequest === 0)
                    setRows(loadedRows);
                else
                    setRows(oldRows.concat(loadedRows));
                
                if(totalCount !== getListResult.totalCount)
                    setTotalCount(getListResult.totalCount);
                if(queryResult !== getListResult.query)
                    setQueryResult(getListResult.query);
                setIsLoadingList(false);
                setSearchLoading(false);
                setLastLoadedPage(pageForRequest);
            });
        if(view.fieldsForSum.length > 0){
            api().getSumForView(dictionary.strictNameMany, pageForRequest, loadProps.filter, view.viewName, queryForRequest)
                .then(getSumResult => {
                    setSumValues(getSumResult.rows);
                });
        }
    };
    currentReload.load = load;
    window.currentReload = load;

    let setFilterAndLoad = (clearFilter)=>{
        setSearchQueryValue('');
        if(appConfig().info.globalFilter !== null && localStorage.getItem('GlobalFilterValue') !== null) {
            let filterDictionary = appConfig()
                .dictionaries.filter(x=>x.nameSingle === appConfig().info.globalFilter.split('-')[0])[0];
            let filterDictionaryView = appConfig()
                .dictionaries.filter(x=>x.nameSingle === appConfig().info.globalFilter.split('-')[0])[0];

            let newGlobalFilterColumn = dictionary.columns.filter(column => column.type === reportTypes.linkToDictionary &&
                column.linkTo === filterDictionary.strictNameMany)[0] ?? null;
            setGlobalFilterColumn(newGlobalFilterColumn);
            let newCurrentFilter = [{
                name: newGlobalFilterColumn.name,
                type: 'global',
                value: localStorage.getItem('GlobalFilterValue')
            }]
            setCurrentFilter(newCurrentFilter);
            load({
                filter: newCurrentFilter,
                query: ''
            });
        }else{
            let filter = JSON.parse(localStorage.getItem(filterStateKey) ?? '[]');
            if(clearFilter)
                filter = [];
            setCurrentFilter(filter);
            load({
                query: '',
                filter: filter,
            });
        }
    };
    
    useEffect(() => {
        if(appConfig().info.globalFilter !== null && localStorage.getItem('GlobalFilterValue') == null)
            return;
        setFilterAndLoad();
    },[]);

    useEffect(()=>{
        if(context.lastTimeResiveTostFromServer != null && context.lastTimeResiveTostFromServer != lastTimeResiveTostFromServer){
            setLastTimeResiveTostFromServer(context.lastTimeResiveTostFromServer)
            load({withoutLoader: true})
        }
    }, [context])


    let ifHaveFilter = (column, currentFilter) => {
        if(currentFilter.filter(x=>x.name === column.name).length > 0){
            let filterElement = currentFilter.filter(x=>x.name === column.name)[0];
            if((filterElement.value != null && filterElement.value != '') ||
                (filterElement.canEmpty != null && filterElement.canEmpty))
                return true;
        }

        return false;
    }
    
    if (appConfig().info.globalFilter !== null && localStorage.getItem('GlobalFilterValue') === null) {
        return <div/>;
    }

    if(rows === null) return <> <Loader active /></>;
    let dictionaryModal = isOpenEditCreateModal ? <DictionaryItemEdit
        isOpenProp={isOpenEditCreateModal}
        isOpenPropSetter={(val) => {if(!val){setEditData(null);}setIsOpenEditCreateModal(val)}}
        onSaveEvent={id=>{
            load();
            if(editData === null && id !== undefined && id !== null && view.stayAfterCreate ){
                setTimeout(()=>{
                    setEditData({Id: id});
                    setIsOpenEditCreateModal(true);
                }, 0);
            }
            if(editData !== null && id !== undefined && id !== null && view.stayAfterEdit ){
                setTimeout(()=>{
                    setEditData({Id: id});
                    setIsOpenEditCreateModal(true);
                }, 0);
            }
        }}
        view={view}
        dictionaryItemMetaDescription={dictionary}
        onActionEvent={action => {
            console.log('load');
            load();
            if(action.stayAfterRun){
                setTimeout(()=>{
                    setEditData({Id: editData.Id});
                    setIsOpenEditCreateModal(true);
                }, 0);
            }
        }}
        onDeleteEvent={() => {
            console.log('load');
            load();
        }}
        data={editData == null ? undefined : editData}
        key={editData != null ? dictionary.name + 'row' + editData.Id : 'create-' + dictionary.name}    
    
    /> : '';


    function getSumValueFor(fieldForSum) {
        if(sumValues.filter(x=>x.name === fieldForSum).length > 0){
            return sumValues.filter(x=>x.name === fieldForSum)[0].value;
        }
        return 0;
    }

    if((rows.length !== undefined && rows.length > 0) || 
        (queryResult !== undefined && queryResult !== null && queryResult !== '') ||
        (currentFilter !== undefined && currentFilter !== null && currentFilter !== '' && currentFilter.filter(x=>x.type !== 'global').length > 0) || 
        isLoadingList
    ){
        var pageInner = <>
            <Grid className={'report-dictionary-header'}>
                <Grid.Row>
                    {view.canTextSearch ? <Grid.Column width={4} className={'for-desktop'}>
                        <Search
                            placeholder={t('placeholder_for_all_fields_search')}
                            fluid
                            value={searchQueryValue}
                            onSearchChange={(e, {value}) => {
                                setSearchQueryValue(value)
                            }}
                            showNoResults={false}
                            loading={searchLoading}
                            onKeyPress={(event) => {
                                if(event.key === 'Enter'){
                                    setSearchLoading(true);
                                    load();
                                }
                            }}
                        />
                        {searchQueryValue !== null && searchQueryValue !== '' ? <Icon 
                            name={'close'} 
                            style={{position: 'absolute', top: '9px', right: '39px', cursor: 'pointer'}}
                            onClick={()=>{
                                setSearchQueryValue('');
                                setSearchLoading(true);
                                load({query: ''});
                            }}
                        /> : ''}
                    </Grid.Column>: ''}
                    <Grid.Column width={2}
                                 className={'padding-top-10 dictionary-search-count'}>{t(nameMany)}: {totalCount}</Grid.Column>
                    {view.canCreate ? <Grid.Column width={5} className={'create-btn-container'}>
                        <Button onClick={()=>{setEditData(null);setIsOpenEditCreateModal(true)}}>
                            <Icon name={'plus'} className={'for-mobile'}/>
                            <span className={'for-desktop'}> {createButtonText}</span>
                        </Button>
                    </Grid.Column>: ''}
                    <Grid.Column width={(5 + (view.canTextSearch ? 0: 4) + (view.canCreate ? 0: 5))} className={'dictionary-list-header-btn-container'}>
                        {(view.canFilter && currentFilter.filter(x=>x.type !== 'global').length > 0) || (view.canTextSearch && queryResult != undefined && queryResult != null && queryResult !== '') ? <Popup
                            content={t('Reset all filters')}
                            trigger={<Button
                                //style={{marginTop: '5px'}}
                                onClick={()=>{setFilterAndLoad(true);}}
                                icon={'filter'}
                                color={'red'}
                            />} /> : ''}
                        {view.canUploadFromExcel ? <UploadFromExcel
                            view={view}
                            globalFilterColumn={globalFilterColumn}
                            dictionaryItemMetaDescription={dictionary}
                            onLoad={()=>{
                                load();
                            }}/> : ''}
                        {view.canExportToExcel ? <ExportToExcel
                            view={view}
                            globalFilterColumn={globalFilterColumn}
                            dictionaryItemMetaDescription={dictionary}
                            /> : ''}
                        {(()=>{
                            let buttons = dictionary
                                .buttons
                                .filter(b => b.showThisTaskForDictionary === "Always" ||
                                    b.showThisTaskForDictionary === "OnlyForNotEmptyDirectory");
                            return buttons !== undefined && buttons.length > 0 ? <>
                                <Dropdown icon='bars' button direction='left'>
                                    <Dropdown.Menu>
                                        <Dropdown.Header content={t('available_tasks')} />
                                        <Dropdown.Divider />
                                        {buttons.map(button => {
                                            return <ActionButton
                                                for={'task'}
                                                as={'dropdown'}
                                                action={button}
                                                afterInvoke={()=>{load();}}
                                            />
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>

                            </> : ''
                        })()}

                        {description !== undefined && description !== '' ? <Popup
                            content={imageForDescription !== null ? <Grid style={{width: '450px'}}><Grid.Row><Grid.Column width={6}><img src={imageForDescription} /> </Grid.Column><Grid.Column width={10}>{getElementsFromFormattedText(t(description))}</Grid.Column> </Grid.Row></Grid> : getElementsFromFormattedText(t(description))}
                            position={"left center"}
                            basic
                            on={"click"}
                            trigger={<Button icon>
                                <Icon name='info'/>
                            </Button>}
                        /> : ''}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div style={{textAlign: 'right', paddingTop: '10px'}}>
                {view.extraFilters.map(extraFilter => {
                    let column = dictionary.columns.filter(x=>x.name === extraFilter)[0];
                    let isIdOrCreateAt = column.name === 'Id' || column.name === 'CreateAt';
                    let cellHeaderText = t(isIdOrCreateAt ? column.name : dictionary.nameSingle + '.' + column.name);
                    if(column.ruShorNameForList !== null &&
                        column.ruShorNameForList !== undefined &&
                        column.ruShorNameForList !== '' &&
                        getCurrentLangValue() === 'ru')
                        cellHeaderText = column.ruShorNameForList;

                    if(column.enShorNameForList !== null &&
                        column.enShorNameForList !== undefined &&
                        column.enShorNameForList !== '' &&
                        getCurrentLangValue() === 'en')
                        cellHeaderText = column.enShorNameForList;


                    return <Popup open={openedFilter === column.name} flowing basic
                                  onClose={(e, data)=>{
                                      if(e.type === 'mouseleave')
                                          return;
                                      setOpenedFilter(null);
                                  }}
                                  content={<FilterForm
                                      column={column}
                                      dictionary={dictionary}
                                      view={view}
                                      currentFilter={currentFilter}
                                      openedFilter={openedFilter === column.name}
                                      setCurrentFilter={(f)=>{
                                          setCurrentFilter(f);
                                          setOpenedFilter(null);
                                          load({filter: f});
                                      }
                                      }
                                  />} trigger={<Button
                        onClick={()=>{
                            if(openedFilter === column.name)
                                setOpenedFilter(null);
                            else
                                setOpenedFilter(column.name);
                        }}

                    ><span
                        style={{cursor: 'pointer', textDecoration: openedFilter === column.name ? 'underline' : 'none', position: 'relative', display: 'block'}}>{cellHeaderText} {ifHaveFilter(column, currentFilter) ?
                        <span style={{position: 'absolute', right: '-15px', top: '0'}}><Icon name={'circle'} color={'blue'} size={'mini'} style={{verticalAlign: 'top'}} /></span> : ''}</span></Button>} />;
                })}
            </div>
            {viewType === 'Cards' ? <CardsItemsView 
                view={view} 
                dictionary={dictionary} 
                rows={rows} 
                openEdit={(data)=>{setEditData(data);setIsOpenEditCreateModal(true)}}
                load={load}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
            /> : ''}
            {viewType === 'List' ? <div id={'dictionary-grid-container'} className={"grid-container" + (view.extraFilters.length > 0 ? ' with-extra-filters ' : '') + (view.selectable ? ' grid-selectable' : '') + (isScrolling ? ' on-scrolling': '') + (view.rowActions ? ' grid-row-actions' : '')}>
                <Visibility style={{height: '1px', display: 'contents'}} onUpdate={(e, { calculations })=>{
                    let scrollTop1 = document.getElementById('dictionary-grid-container').scrollTop;

                    if(scrollTop1 !== 0 && scrollTop1 !== scrollTop){
                        if(!isScrolling) setIsScrolling(true);
                        setScrollTop(scrollTop1);
                    }
                    if(scrollTop1 === 0 && isScrolling){
                        setIsScrolling(false);
                        setScrollTop(0);
                    }
                }}>

                </Visibility>
                <div style={{ position: "relative" }}>
                    <Table striped={view.stripped}
                           celled={view.verticalLines}
                           selectable
                           unstackable={false}

                    >
                        <Table.Header>
                            <Table.Row key={dictionary.name + 'row-header'}>
                                {view.selectable ? <Table.HeaderCell
                                    key={dictionary.name + 'header-checkbox'}
                                    style={{padding: '2px 8px 0px 11px'}} className={'z-index-1'}>
                                    <Checkbox onChange={(e, { checked })=>{
                                        e.stopPropagation();
                                        if(checked)
                                            setSelectedIds(rows.map(x=>x.data.Id));
                                        else
                                            setSelectedIds([]);
                                    }} />
                                </Table.HeaderCell>: ''}
                                {view.viewId ? <DictionaryHeaderCell
                                    column={{name: 'Id'}}
                                    dictionary={dictionary}
                                    view={view}
                                    globalFilterColumn={globalFilterColumn}
                                    currentSort={currentSort}
                                    setCurrentSort={setCurrentSortAndRefresh}
                                    openedFilter={openedFilter}
                                    setOpenedFilter={setOpenedFilter}
                                    currentFilter={currentFilter}
                                    setCurrentFilter={(f)=>{
                                        setCurrentFilter(f);
                                        load({
                                            filter: f
                                        });}}
                                /> : ''}
                                {view.viewCreateAt ? <DictionaryHeaderCell
                                    column={{name: 'CreateAt'}}
                                    dictionary={dictionary}
                                    view={view}
                                    globalFilterColumn={globalFilterColumn}
                                    currentSort={currentSort}
                                    setCurrentSort={setCurrentSortAndRefresh}
                                    openedFilter={openedFilter}
                                    setOpenedFilter={setOpenedFilter}
                                    currentFilter={currentFilter}
                                    setCurrentFilter={(f)=>{
                                        setCurrentFilter(f);
                                        load({
                                            filter: f
                                        });}}

                                /> : ''}
                                {dictionary.columns !== undefined ? dictionary.columns
                                    .filter(column => column !== globalFilterColumn)
                                    .filter(column => !column.hideInList)
                                    .map(column => {
                                        return <DictionaryHeaderCell
                                            column={column}
                                            dictionary={dictionary}
                                            view={view}
                                            globalFilterColumn={globalFilterColumn}
                                            currentSort={currentSort}
                                            setCurrentSort={setCurrentSortAndRefresh}
                                            openedFilter={openedFilter}
                                            setOpenedFilter={setOpenedFilter}
                                            currentFilter={currentFilter}
                                            setCurrentFilter={(f)=>{
                                                setCurrentFilter(f);
                                                load({
                                                    filter: f
                                                });}}
                                        />;
                                    }) : ""}
                                {view.rowActions ? <Table.HeaderCell key={dictionary.name + 'header-Action'}></Table.HeaderCell>: ''}
                            </Table.Row>
                        </Table.Header>

                        <Table.Body key={dictionary.name + 'Table.Body'}>
                            {
                                rows.map((row, i) => <Table.Row
                                    key={dictionary.name + 'row' + row.data.Id + 'pos' + i}
                                    className={(row.data.backlights !== undefined ? 'backgorund-for-row-' + row.data.backlights[0].color: '')}
                                    onClick={()=>{setEditData(row.data);setIsOpenEditCreateModal(true)}}
                                >
                                    {view.selectable ? <Table.Cell>
                                        <Checkbox
                                            checked={selectedIds.filter(x=>x === row.data.Id ).length > 0}
                                            onChange={(e, { checked })=>{
                                                e.stopPropagation();
                                                //debugger;
                                                let result = [...selectedIds];
                                                if(checked){
                                                    result.push(row.data.Id);
                                                    setSelectedIds(result);
                                                }
                                                else{
                                                    result = selectedIds.filter(x => x !== row.data.Id);
                                                    setSelectedIds(result);
                                                }
                                            }} />
                                    </Table.Cell>: ''}
                                    {view.viewId ? <Table.Cell><Popup basic inverted flowing trigger={<span>{row.data.Id.substr(0, 7) + '...'}</span>} content={row.data.Id} /><CopyToClipboard onCopy={()=>{
                                        toast.info(getCurrentLangValue() === 'ru' ? 'Идентификатор скопирован в буфер обмена!' : 'Id copied to the clipboard!');
                                    }} text={row.data.Id}>
                                        <Icon name={'copy'} onClick={(e)=>{
                                            e.stopPropagation();
                                        }}/>
                                    </CopyToClipboard></Table.Cell> : ''}
                                    {view.viewCreateAt ? <Table.Cell><Popup basic content={row.data.createAtHumanView} trigger={<span>{row.data.createAtNew}</span>} /></Table.Cell> : ''}
                                    {dictionary.columns !== undefined ? dictionary.columns
                                        .filter(column => column !== globalFilterColumn)
                                        .filter(column => !column.hideInList)
                                        .map((column, columnIndex) => {
                                            return <Table.Cell key={dictionary.name + column.name + i}>
                                                {columnValueGetter(row.data, column, queryResult)}
                                            </Table.Cell>;
                                        }) : ""}
                                    {view.rowActions ? <Table.Cell>
                                        <ActionInvokerForGrid id={row.data.Id} afterInvoke={()=>{load();}} dictionaryStrictNameMany={dictionary.strictNameMany}/>
                                    </Table.Cell>: ''}
                                </Table.Row>)
                            }
                            {isLoadingList ?  <Loader active inline size={"mini"}/> : ''}
                        </Table.Body>
                    </Table>
                    {rows.length === 0 ? <div className={'dictionary-empty-after-search-message-container'}>
                        <p>{t('not_found_message_after_filter')}</p>
                        <Button onClick={()=>{setFilterAndLoad(true);}}>{t('clear_filters_btn')}</Button>
                    </div> : ''}
                    {rows.length >= 30 ? <Visibility
                        continuous={false}
                        once={false}
                        onTopVisible={onBottomVisible}
                        style={{ position: "absolute", bottom: 0, left: 0, right: 0, height: "10px", zIndex: -1 }} />: ''}
                </div>
                {isScrolling ? <div style={{position:"absolute", width:'calc(100% - 2px)', height: '10px', backgroundColor: '#f9fafb', left: '1px', top: (scrollTop) + 'px'}}/>:''}
            </div> : ''}
            </>;

        return <div className={'report-dictionary-page'} style={appConfig().info.appBackgroundImageUrl !== '' ?  {'background-image': `url(${appConfig().info.appBackgroundImageUrl})`} : {}}>
            <div className={appConfig().info.blurAppBackgroundImageUrl ? "blur": ''}>
                {dictionary.listType === 'Slim' || dictionary.listType === ''  ? <Container className={'report-dictionary-container'}>
                    {pageInner}
                </Container> : <div className={'report-dictionary-full-screen'}>{pageInner}</div> }
                {view.fieldsForSum.length > 0 && !isOpenSumInfo ? <div className={'sum-btn'} onClick={()=>{setIsOpenSumInfo(true)}}>{t('Sum_label_sum_panel')}</div> : ''}
                {isOpenSumInfo ? <div className={'sum-info-container'}>
                    <Container>
                    <Message style={{position: 'relative', paddingLeft: '0px'}}>
                        {view.fieldsForSum.map(fieldForSum => <div style={{display: 'inline-block', marginLeft: '15px'}}>
                            {t(dictionary.nameSingle + '.' + fieldForSum)}: <b>{getSumValueFor(fieldForSum)}</b>
                        </div>)}
                        <a
                            style={{position: 'absolute', right: '6px', top: '3px', cursor: 'pointer'}}
                            onClick={()=>{setIsOpenSumInfo(false)}}>{t('Hide_sum_panel')}</a>
                    </Message>
                    </Container>
                </div> : ''}
            </div>
            {dictionaryModal}
        </div>;        
    }
    
    return <div className={'report-dictionary-page'}
                                                               style={appConfig().info.appBackgroundImageUrl !== '' ? {'background-image': `url(${appConfig().info.appBackgroundImageUrl})`} : {}}>
            <div className={appConfig().info.blurAppBackgroundImageUrl ? "blur" : ''}>
                <div className={'dictionary-name-for-empty for-mobile'}>{(`${t(nameMany)}`)}</div>
                <Container className={'report-dictionary-container-empty'}>
                    <Message
                        icon={imageForDescription == null ? 'info' : null}
                        size='huge'
                        header={<span className={'for-desktop'} style={{fontWeight: 'bold', fontSize: 'larger'}}>{(`${t(nameMany)}`)}</span>}
                        content={<>
                                    <br className={'for-desktop'}/>
                                    {imageForDescription !== null ? <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={10}>
                                                {getElementsFromFormattedText(t(description))}
                                            </Grid.Column>
                                            <Grid.Column width={6}>
                                                {imageForDescription !== null ? <img
                                                    className={'description-img'}
                                                    src={imageForDescription}
                                                />: ''}

                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>: getElementsFromFormattedText(t(description))}
                                    <br className={'for-desktop'}/>
                                    <br className={'for-desktop'}/>
                                    <small>{t((view.canCreate || view.canUploadFromExcel ? 'noAvailableItemsInDictionaryList' : 'noAvailableItemsInDictionaryListWithoutActions'))}</small><br/>
                                    {view.canCreate ? <Button onClick={()=>{setEditData(null);setIsOpenEditCreateModal(true)}} primary>{createButtonText}</Button> : ''}
                                    {view.canUploadFromExcel ? <>{t('or_in_empty_dictonary_list')} <UploadFromExcel
                                        as={'button'}
                                        view={view}
                                        globalFilterColumn={globalFilterColumn}
                                        dictionaryItemMetaDescription={dictionary}
                                        onLoad={()=>{
                                            load();
                                        }}/></> : ''}
                                        {(()=>{
                                            var buttonsFroEmpty = dictionary
                                                .buttons
                                                .filter(b => b.showThisTaskForDictionary === 'Always' ||
                                                    b.showThisTaskForDictionary === 'OnlyForEmptyDirectory')
                                            ; 
                                            return buttonsFroEmpty !== undefined && buttonsFroEmpty.length > 0 ? <>
                                                <br/>{view.canCreate || view.canUploadFromExcel ? <small>{t('And also')}:</small> : ''}
                                                {buttonsFroEmpty.map(button => {
                                                    return <div /*style={{marginTop: '5px'}}*/><ActionButton
                                                        for={'task'}
                                                        action={button}
                                                        afterInvoke={()=>{load();}}
                                                    /></div>
                                                })}
                                            </> : ''
                                        })()}
                                </>}
                    />

                </Container></div>
            {dictionaryModal}
        </div>;
}