import React, {useState} from 'react';
import './newLetterModal.css'
import {Button, Form, FormField, Modal, ModalActions, ModalContent, ModalHeader} from "semantic-ui-react";
import api from "../../../../../../utils/api";
import {toast} from "react-toastify";

export default function NewLetterModal({from, open, setOpen, onSend, prefilledTo, prefilledTitle, prefilledBody}) {
    const [to, setTo] = useState(prefilledTo ?? '')
    const [title, setTitle] = useState(prefilledTitle ?? '')
    const [body, setBody] = useState(prefilledBody ?? '')
    const [isLoading, setIsLoading] = useState(false)
    const send = () => {
        setIsLoading(true)
        api()
            .sendEmail(from.id, to, title, body)
            .then(resp => {
                setIsLoading(false)
                if(resp.error != null && resp.error != '' && resp.error != undefined){
                    toast.error('Возникла ошибка при отправке письма')
                    return
                }
                setOpen(false)
                toast.info('Письмо отправлено!')
                onSend()
            })
    }
    return (<Modal
        closeIcon
        size={'tiny'}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}>
        <ModalHeader>Новое письмо</ModalHeader>
        <ModalContent>
            <Form>
                <FormField>
                    <label>Кому</label>
                    <input 
                        placeholder='Введите email...' 
                        onChange={e=>setTo(e.target.value)}
                        value={to}
                        disabled={isLoading}
                    />
                </FormField>
                <FormField>
                    <label>Тема</label>
                    <input 
                        placeholder='Укажите тему письма...' 
                        onChange={e=>setTitle(e.target.value)}
                        value={title}
                        disabled={isLoading}
                    />
                </FormField>
                <FormField>
                    <label>Текст письма</label>
                    <textarea
                        placeholder='Укажите текст письма...'
                        value={body}
                        onChange={e=>setBody(e.target.value)}
                        disabled={isLoading}
                    />
                </FormField>
            </Form>
        </ModalContent>
        <ModalActions>
            <Button basic onClick={() => setOpen(false)}>
                Отмена
            </Button>
            <Button
                content="Отправить"
                labelPosition='right'
                icon='mail'
                onClick={send}
                positive
                disabled={isLoading}
                loading={isLoading}
            />
        </ModalActions>
    </Modal>);
}