import React from 'react';
import './LanguageSwitcher.css'
import t from "../../../utils/translate";
import {Dropdown} from "semantic-ui-react";
import langs from "../../../utils/langs";

export default function LanguageSwitcher() {
    var currentLangValue = localStorage.getItem("currentLangValue") ?? 'ru';
    const langOptions = langs();

    let handleChangeLang = (e, data)=>{
        console.log("handleChangeLang");
        console.log(JSON.stringify(data.value));
        localStorage.setItem("currentLangValue", data.value);
        window.location = window.location;
    };
    
    return (<div className="language-switcher">
        <i className={`${langOptions[(currentLangValue === "ru" ? 0 : 1)].flag} flag`}></i>
        <span>{t("language")}:</span> <Dropdown inline
                                                options={langOptions}
                                                onChange={handleChangeLang}
                                                defaultValue={langOptions[(currentLangValue === "ru" ? 0 : 1)].value}
    ></Dropdown>
    </div>);
}