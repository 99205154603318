import React, {useRef} from 'react';
import api from "../../../../../../../../utils/api";
import getBackedUrl from "../../../../../../../../utils/getBackedUrl";

export default function MyImageUpload(props) {
    const fileInputRef = useRef()
    const handleClick = () => {
        fileInputRef.current.click()
    }
    
    return <span
        onClick={handleClick}
        className="button button-type-image"
        title="Image"
        style={{position: 'relative'}}>
            <i className="rmel-iconfont rmel-icon-image"></i>
            <input
                ref={fileInputRef}
                onChange={(e) => { return api().uploadFile(e.target.files[0]).then((resp)=> {
                    props.editor.insertText(`![](${getBackedUrl()}/api/file/image/${resp.id})`);
                })}}
                type="file"
                style={{position: 'absolute', zIndex: -1, left: '0', top: '0', width: '0', height: '0', opacity: '0'}}/>
    </span>;
}