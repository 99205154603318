import React, {useRef, useState} from 'react';
import {
    Button,
    Grid, Icon
} from "semantic-ui-react";
import api from "../../../utils/api";
import t from "../../../utils/translate";
import './ArrayImageFormInput.scss'
import getBackedUrl from "../../../utils/getBackedUrl";
import ImageViewer from "../../ImageViewer/ImageViewer";

export default function ArrayImageFormInput({name, value, column, onChangeFieldValue, readOnly}) {
    let fileInputRef = useRef();
    const [imageViewerOpen, setImageViewerOpen] = useState(false)
    const [imageViewerSrc, setImageViewerSrc] = useState('')
    const fileIsEmpty = value == null || value === '' || value === '[]' || JSON.parse(value).length === undefined || JSON.parse(value).length === 0;
    const element = <div className={'preview-container'}>
        {fileIsEmpty ? <div
            className={'image'} style={{backgroundImage: `url("/img/image-placeholder.svg")`,
            cursor: 'default'
        }}/> : <div className={'inner-preview-container'} style={{width: `${200 * JSON.parse(value).length}px`}}>
            {JSON.parse(value).map(image => <div
                onClick={() => {
                    if(!fileIsEmpty){
                        setImageViewerSrc(`${getBackedUrl()}/api/file/image/${image.id}`)
                        setImageViewerOpen(true)
                    }
                }}
                className={'image'} style={{backgroundImage: `url("${getBackedUrl()}/api/file/image/${image.previewId}")`,
                cursor: 'zoom-in'
            }}>
                {readOnly ? '' : <Button
                    className={'delete-btn'}
                    icon
                    color={'red'}
                    size={'mini'}
                    onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        onChangeFieldValue(name, JSON.stringify(JSON.parse(value).filter(x => x.id !== image.id)))
                    }}
                ><Icon name={'close'}/></Button>}
            </div>)}
        </div>}
    </div>

    return <div className={'images-input-container'}>
        {readOnly ? element : <>
            {element}
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column><Button
                        style={{width: '100%'}}
                        content={(fileIsEmpty ? t('select_image_input_choose_an_photo') : t('select_image_input_add_the_photo'))}
                        labelPosition="left"
                        icon="photo"
                        onClick={() => fileInputRef.current.click()}
                    /></Grid.Column>
                </Grid.Row>
            </Grid>
            <input
                type="file"
                multiple
                hidden
                ref={fileInputRef}
                onChange={(e) => { 
                    let promises = []
                    for(let i = 0; i < e.target.files.length; i++){
                        promises.push(api().uploadImage(e.target.files[i]))
                    }
                    return Promise
                        .all(promises)
                        .then(responses => {
                            let old = fileIsEmpty ? [] : JSON.parse(value)
                            responses.map(x=> {
                                old.push(x)
                            })
                            onChangeFieldValue(name, JSON.stringify(old)); 
                        })
                  }} />
        </>}
        <ImageViewer open={imageViewerOpen} setOpen={setImageViewerOpen} imageViewerSrc={imageViewerSrc} />
    </div>
}