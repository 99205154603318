import React, {useEffect, useState} from 'react';
import './NewsModal.scss'
import {
    Button, Checkbox,
    Grid, Icon, Label, MenuItem,
    Modal, Popup, Segment, Tab, Table, TabPane
} from "semantic-ui-react";
import api from "../../../../utils/api";
import {toast} from "react-toastify";
import KnowledgeNewsItemView from "../KnowledgeNewsItemView/KnowledgeNewsItemView";
import ViewRelativeTime from "../../../../shared/components/ViewRelativeTime/ViewRelativeTime";

export default function NewsModal({open, setOpen}) {
    let [activeIndex, setActiveIndex] = useState(0);
    let [unreadNewsCount, setUnreadNewsCount] = useState(0);
    let [news, setNews] = useState([]);
    let [settings, setSettings] = useState([]);

    const load = (loadSettingsToo=false) => {
        if(activeIndex === 0){
            api()
                .getNewsForKnowledgeBase()
                .then(newsResp => {
                    setNews(newsResp.data)
                })
        }
        if(activeIndex === 1 || loadSettingsToo){
            api()
                .getSettingsForKnowledgeBase()
                .then(settingsResp => {
                    setSettings(settingsResp.data)
                })
        }
        api()
            .getUnreadNewsCountForKnowledgeBase()
            .then(settingsResp => {
                setUnreadNewsCount(settingsResp.data)
            })
    }
    
    useEffect(()=> {
        if(open){
            setNews([])
            setSettings([])
            setActiveIndex(0)
            load(true)
        }
    }, [open])

    useEffect(()=> {
        if(open){
            load()
        }
    }, [activeIndex])
    
    const setAsRead = (ids) => {
        api()
            .setUnreadNewsForKnowledgeBaseAsRead(ids)
            .then(load)

    }
    const readAll = () => {
        api()
            .setAllUnreadNewsForKnowledgeBaseAsRead()
            .then(resp => {
                toast.info('Все новости помечены как прочитанные')
                load()
            })
    }

    const changeSubscription = (item, value, type) => {
        let fun = value ? api()
                .addToSubscribers : api()
            .removeFromSubscribers
        fun(item.id, type)
            .then(resp => {
                toast.info(value ? 'Подписка добавлена' : 'Подписка удалена')
                load()
            })
    }
    const changeCommentSubscription = (item) => {}
    
    return (<Modal
        open={open}
        onClose={() => setOpen(false)}
        //size={"mini"}
        centered={false}
        closeIcon={true}
        onClick={e=>{
            e.preventDefault()
            e.stopPropagation()
        }}
    >
        <Modal.Header>Новости базы знаний</Modal.Header>
        <Modal.Content>
            <Tab
                onTabChange={(e, data) => {setActiveIndex(data.activeIndex)}} 
                activeIndex={activeIndex} 
                defaultActiveIndex={0} 
                panes={[
                {
                    menuItem: (
                        <MenuItem key='messages'>
                            Новости {unreadNewsCount != 0 ? <Label>{unreadNewsCount}</Label> : ''}
                        </MenuItem>
                    ),
                    render: () => <TabPane attached={false}>
                        <Button onClick={readAll}>Пометить все как прочитанные</Button>
                        {news.map(newsItem => <Segment>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={3}><ViewRelativeTime dateTime={newsItem.createAt}/></Grid.Column>
                                    <Grid.Column width={10}>
                                        <KnowledgeNewsItemView data={newsItem}/>
                                    </Grid.Column>
                                    <Grid.Column width={3}><Button onClick={()=>{setAsRead([newsItem.id])}}>Прочитать</Button></Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>)}
                    </TabPane>,
                },
                {
                    menuItem: (
                        <MenuItem key='messages'>
                            Настройки {settings.length != 0 ? <Label>{settings.length}</Label> : ''}
                        </MenuItem>
                    ),
                    render: () => <TabPane attached={false}>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Тип</Table.HeaderCell>
                                    <Table.HeaderCell>Название</Table.HeaderCell>
                                    <Table.HeaderCell>Контент</Table.HeaderCell>
                                    <Table.HeaderCell>Комментарии</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {settings.map(settingItem => <Table.Row>
                                <Table.Cell><Popup
                                    basic
                                    inverted
                                    content={settingItem.isFolder ? 'Папка' :  'Страница'}
                                    trigger={<Icon name={settingItem.isFolder ? 'folder outline' :  'file outline'} />} /></Table.Cell>
                                <Table.Cell><a onClick={()=>{window.open(`/knowledgeBase/${settingItem.url}`, '_blank')}} target={'_blank'} href={`/knowledgeBase/${settingItem.url}`}>{settingItem.name}</a></Table.Cell>
                                <Table.Cell><Checkbox onChange={(e, data)=> { changeSubscription(settingItem, data.checked, 'content') } } checked={settingItem.isContent}/></Table.Cell>
                                <Table.Cell>{settingItem.isFolder ? '' : <Checkbox onChange={(e, data)=> { changeSubscription(settingItem, data.checked, 'comment') } } checked={settingItem.isComment}/>}</Table.Cell>
                            </Table.Row>)}
                        </Table>
                    </TabPane>,
                }
            ]} />
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={() => setOpen(false)} basic>
                Закрыть
            </Button>
        </Modal.Actions>
    </Modal>);
}