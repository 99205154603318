import React, {useEffect, useRef, useState} from 'react';
import './CodeExample.scss'

import AceEditor from "react-ace";
import {Header, Icon, Popup} from "semantic-ui-react";
import {toast} from "react-toastify";
import {getCurrentLangValue} from "../../../../utils/translate";
import {CopyToClipboard} from "react-copy-to-clipboard";

const CodeExample = ({title, code, hideLines}) => {
    const copyRef = useRef()
    return (<div className={'code-example'}>
        {title != undefined ? <Header as='h4'>Пример кода: {title}</Header> : ''}
        <AceEditor
            mode="python"
            enableBasicAutocompletion={true}
            setOptions={{ useWorker: false }}
            theme="solarized_dark"
            minLines={2}
            maxLines={50}
            width={'100%'}
            readOnly={true}
            value={code}
            fontSize={16}
            highlightActiveLine={false}
            showGutter={hideLines == undefined}
        />
        <Popup content={'Копировать код'}
               basic
               inverted
               trigger={<CopyToClipboard onCopy={()=>{
                   toast.info(getCurrentLangValue() === 'ru' ? 'Скопировано в буфер обмена!' : 'Copied to the clipboard!');
               }} text={code}>
                   <Icon
                       ref={copyRef}
                       className={'copy-icon'}
                       name={'copy'}
                       inverted
                       onClick={(e)=>{
                           e.stopPropagation();
                       }}/>
               </CopyToClipboard>}
        />
        
    </div>);
}

export default CodeExample