import React, {useEffect, useState} from 'react';
import {Loader} from "semantic-ui-react";
import Chart from "react-apexcharts";
import GanttDiagramComponent from "./GanttDiagramComponent/GanttDiagramComponent";

let getTitle = (element)=>{
    if(element.viewTitle && (element.ruTitle === undefined || element.ruTitle === null || element.ruTitle === '')){

        let fieldForValue = JSON.parse(element.dictionaryFieldForValue);
        let dictionaryNameForValue = fieldForValue.value.split('"."')[0].substr(1);
        let fieldNameForValue = fieldForValue.value.split('"."')[1];

        fieldNameForValue = fieldNameForValue.substr(0, fieldNameForValue.length - 1);

        let fieldForLabel = JSON.parse(element.dictionaryFieldForLabel);
        let dictionaryNameForLabel = fieldForLabel.value.split('"."')[0].substr(1);
        let fieldNameForLabel = fieldForLabel.value.split('"."')[1];

        fieldNameForLabel = fieldNameForLabel.substr(0, fieldNameForLabel.length - 1);

        if(dictionaryNameForLabel === dictionaryNameForValue)
            return `${fieldNameForValue} по ${fieldNameForLabel} (${dictionaryNameForValue})`;
        return `${fieldNameForValue} из ${dictionaryNameForValue} по ${fieldNameForLabel} из ${dictionaryNameForLabel}`;
    }

    return element.ruTitle;
}

const GanttDiagram = ({
                       getData,
                       pageIndex,
                       elementIndex,
                       element,
                       width,
                       height,
                       onePercentInPixelForWidth,
                       onePercentInPixelForHeight,
                       filtersValues
                   }) => {
    let [isLoading, setIsLoading] = useState(true);
    let [labels, setLabels] = useState([]);
    let [series, setSeries] = useState([]);
    let [data, setData] = useState([]);
    

    useEffect(() => {
        getData(pageIndex, elementIndex, filtersValues)
            .then(values => {
                setLabels(values.map(x=>x[0]));
                setSeries(values.map(x=>parseFloat(x[1]) ));
                let xs = [...new Set(values.map(x=>x[5]))]
                console.log(element)
                setData(xs.map(x => {
                    let valuesForX = values.filter(z=>z[5] == x).map(val => {
                        let start = new Date(val[0]).getTime();
                        let end = new Date(val[1]).getTime();
                        return {
                            start: start,
                            end: end,
                            title: val[2],
                        }
                    })
                    return {
                        x: x,
                        y: valuesForX
                    }
                }))
                setIsLoading(false);
            });
        setIsLoading(false);
    }, []);

    return (<div style={{marginTop: '20px'}}>
        {!isLoading ? <div style={{textAlign: 'left', fontSize: (onePercentInPixelForWidth*1.5) + 'px', padding: (onePercentInPixelForWidth) + 'px'}}>{getTitle(element)}</div> : ''}
        {isLoading ? <Loader active inline size='massive'></Loader> : <GanttDiagramComponent 
            value={data}
            width={width}
            height={height}
        />}
    </div>);
}

export default GanttDiagram