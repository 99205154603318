import React, {useRef} from 'react';
import {
    Button,
    Grid
} from "semantic-ui-react";
import api from "../../../utils/api";
import t from "../../../utils/translate";
import './AvatarFormInput.css'
import getBackedUrl from "../../../utils/getBackedUrl";

export default function AvatarFormInput({name, value, column, onChangeFieldValue, readOnly}) {
    let fileInputRef = useRef();
    const avatarIsEmpty = value == null || value === '' || JSON.parse(value).id === undefined;
    const element = <div className={'preview-container'}>
        <div className={'image'} style={{backgroundImage: avatarIsEmpty ? 
                `url("/img/avatar-placeholder.svg")` : 
                `url("${getBackedUrl()}/api/avatar/byFile/${JSON.parse(value).id}")`}}/>
    </div>

    return <div className={'avatar-input-container'}>
        {readOnly ? element : <>
            {element}
            <Grid>
                <Grid.Row columns={avatarIsEmpty ? 1 : 2}>
                    <Grid.Column><Button
                        style={{width: '100%'}}
                        content={(avatarIsEmpty ? t('select_avatar_input_choose_an_avatar') : t('select_avatar_input_change_the_avatar'))}
                        labelPosition="left"
                        icon="file"
                        onClick={() => fileInputRef.current.click()}
                    /></Grid.Column>
                    {avatarIsEmpty ? '' : <Grid.Column><Button
                        style={{width: '100%'}}
                        content={t('select_avatar_input_delete_an_avatar')}
                        labelPosition="left"
                        icon="remove"
                        color={'red'}
                        onClick={()=>{onChangeFieldValue(name, null)}}
                    /></Grid.Column>}
                </Grid.Row>
            </Grid>
            <input
                type="file"
                hidden
                ref={fileInputRef}
                onChange={(e) => { return api().uploadAvatar(e.target.files[0]).then((resp)=> {
                    onChangeFieldValue(name, JSON.stringify(resp));
                })}} />
        </>}
    </div>
}