import React, {useEffect, useState} from 'react';
import './EmailClient.css'
import api from "../../utils/api";
import Login from "./components/login/login";
import EmailClientBody from "./components/emailClientBody/emailClientBody";

export default function EmailClient() {
    const [isInit, setIsInit] = useState(false)
    const [emails, setEmails] = useState([])

    useEffect(()=>{
        setIsInit(false)
        api()
            .getEmailAccounts()
            .then(resp => {
                setEmails(resp)
                setIsInit(true)
            })
    }, [])
    return (<div className={'email-client-page-container'}>
        {isInit && <div>
            {emails.length > 0 ? <EmailClientBody emails={emails} /> : <Login/>}
        </div>}
    </div>);
}