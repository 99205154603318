import React from 'react';
import {getCurrentLangValue} from "../../utils/translate";
import {useWindowSize} from "../../utils/windowSize";
import getBackedUrl from "../../utils/getBackedUrl";
import appConfig from "../../utils/appConfig";

const IFramePage = ({page}) => {
    const [width, height] = useWindowSize();
    let resultHeight = height - 48;
    let url = page.iframeUrl;
    if (page.iframeUseApi){
        url += `?serverUrl=${getBackedUrl()}&token=${JSON.parse(localStorage.getItem('Identity')).accessToken}`
        const globalFilterName = appConfig().info.globalFilter
        if(globalFilterName !== undefined && 
            globalFilterName !== '' && 
            globalFilterName != null){
            url += `&globalFilterValue=${localStorage.getItem('GlobalFilterValue')}`
        }
        url += `&lang=${getCurrentLangValue()}`
        if(window.user !== undefined && window.user.Id !== undefined && window.user.Id != null){
            url += `&userId=${window.user.Id}`
        }
    }
    return (<div style={{paddingTop: '3px'}}>
        <iframe src={url} style={{border: 'none', width: '100%', height: resultHeight + 'px', padding: '0', margin: '0'}} allow="microphone *"/>
    </div>);
}

export default IFramePage