import React, {useState} from 'react';
import './InnerAdd.css'
import {
    Icon, Popup
} from "semantic-ui-react";
import CreatePageOrFolderModal from "../../../CreatePageOrFolderModal/CreatePageOrFolderModal";

export default function InnerAdd({directory, onCreate}) {
    const [openCreatePageOrFolderModal, setOpenCreatePageOrFolderModal] = useState(false)    
    return <><Popup
        basic
        inverted
        content={'Добавить страницу или папку'}
        trigger={<a
            className={'inner-add'}
            onClick={e=>{
                setOpenCreatePageOrFolderModal(true)
                e.preventDefault()
                e.stopPropagation()
            }}> <Icon name={'plus circle'}/></a>} />
        <CreatePageOrFolderModal
            directory={directory}
            open={openCreatePageOrFolderModal}
            setOpen={setOpenCreatePageOrFolderModal}
            onCreate={onCreate}
        />
    </>;
}