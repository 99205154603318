import React, {useEffect, useState} from 'react';
import {Loader} from "semantic-ui-react";
import Chart from "react-apexcharts";
import {getCurrentLangValue} from "../../../../utils/translate";
import md5 from "md5";
import resolveColor from "../../../../utils/colorHelper";
import getBackedUrl from "../../../../utils/getBackedUrl";

let getTitle = (element)=>{
    if(element.viewTitle && (element.ruTitle === undefined || element.ruTitle === null || element.ruTitle === '')){
        
        let fieldForValue = JSON.parse(element.dictionaryFieldForValue);
        let dictionaryNameForValue = fieldForValue.value.split('"."')[0].substr(1);
        let fieldNameForValue = fieldForValue.value.split('"."')[1];

        fieldNameForValue = fieldNameForValue.substr(0, fieldNameForValue.length - 1);
        
        let fieldForLabel = JSON.parse(element.dictionaryFieldForLabel);
        let dictionaryNameForLabel = fieldForLabel.value.split('"."')[0].substr(1);
        let fieldNameForLabel = fieldForLabel.value.split('"."')[1];
        
        fieldNameForLabel = fieldNameForLabel.substr(0, fieldNameForLabel.length - 1);
        
        if(dictionaryNameForLabel === dictionaryNameForValue)
            return `${fieldNameForValue} по ${fieldNameForLabel} (${dictionaryNameForValue})`;
        return `${fieldNameForValue} из ${dictionaryNameForValue} по ${fieldNameForLabel} из ${dictionaryNameForLabel}`;
    }

    return element.ruTitle;
}

function getColorsForLabels(labels, dataColors, columnType, linkTo, enumColors) {
    if(columnType === 'linkToEnum') {
        let color = enumColors.filter(x => x.name === linkTo)[0].color;
        return resolveColor(color);
    }
    let palette = ['black', 'red', 'orange', 'olive', 'green', 'teal', 'blue', 'violet', 'pink', 'brown' ];
    let getIndex = (label)=>{
        let a = md5(label).charCodeAt(7) % 10;
        console.log('getColorsForLabels: ' + a);
        return a;
    };
    
    return labels.map(label=>{
        if(label === '<Пусто>'){
            return '#C4C4C4';
        }
        if(dataColors.filter(x=>x.dataValue === label).length > 0) {
            let filterElement = dataColors.filter(x=>x.dataValue === label)[0];
            return filterElement.color;
        }
        return resolveColor(palette[getIndex(label)])
    });
}

const PieChart = ({isEdit,
                      getData,
                      dataColors,
                      enumColors,
                      pageIndex, 
                      elementIndex, 
                      element, 
                      width, 
                      height, 
                      onePercentInPixelForWidth, 
                      onePercentInPixelForHeight,
                      filtersValues
}) => {
    let [isLoading, setIsLoading] = useState(true);
    let [labels, setLabels] = useState([]);
    let [series, setSeries] = useState([]);
    
    let currentLangValue = getCurrentLangValue();

    let labelIndex = 1;
    let dataIndex = 2;
//debugger;
    if(JSON.parse(element.dictionaryFieldForLabel).fieldType === 'LinkToEnum'){
         labelIndex = 1;
         dataIndex = 2;
    }
    let update = ()=>{
        getData(pageIndex, elementIndex, filtersValues)
            .then(values => {
                setLabels(values.map(x => x[labelIndex] === undefined ||x[labelIndex] === null || x[labelIndex] === '' ? '<Пусто>' :  x[labelIndex]));
                setSeries(values.map(x => x[dataIndex] === '' ? 0 : parseFloat(x[dataIndex])));
                setIsLoading(false);
            });
    }
    useEffect(() => {
        update();
    }, []);

    useEffect(() => {
        update();
    }, [filtersValues]);
    let number = 40;
    let resultWidth = (width / (height - number)) >= (1.42) ? ((height - number)*1.42) : (width) ;

    let prefix = '';
    let postfix = '';
    let func = '';
    if(element.dictionaryFieldForValue !== undefined && element.dictionaryFieldForValue !== ''){
        let field = JSON.parse(element.dictionaryFieldForValue);
        if(isEdit){
            prefix = field.prefix ?? '';
            postfix = field.postfix ?? '';
        }else{
            prefix = (getCurrentLangValue() === 'ru' ? field.prefix : field.enPrefix) ?? '';
            postfix = (getCurrentLangValue() === 'ru' ? field.postfix : field.enPostfix) ?? '';
        }
        func = field.func ?? '';
    }
    let postfixString = postfix !== '' && func !== 'COUNT' ? ' ' + postfix : (func === 'COUNT' ? (getCurrentLangValue() === 'ru' ? ' ед.' : ' units') : '');
    let colorsForLabels = getColorsForLabels(labels, dataColors, JSON.parse(element.dictionaryFieldForLabel).fieldType, JSON.parse(element.dictionaryFieldForLabel).linkTo, enumColors);
    return (<div style={{marginTop: '20px'}}>
        {!isLoading ? <div style={{textAlign: 'left', fontSize: (onePercentInPixelForWidth*1.5) + 'px', padding: (onePercentInPixelForWidth) + 'px'}}>{getTitle(element)}</div> : ''}
        {isLoading ? <Loader active inline size='massive'></Loader> : <Chart
            options={{
                chart: {
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false,
                        animateGradually: {
                            enabled: false,
                        },
                        dynamicAnimation: {
                            enabled: false,
                        }
                    }
                    
                },
                labels: labels,
                fill: {
                    type: 'image',
                    opacity: 1,
                    image: {
                        src: labels.map((label, i) => {
                            if(label == '<Пусто>')
                                return 'https://static.tildacdn.com/tild6365-3739-4232-a237-653864623336/Empty_1.svg';
                            
                            return getBackedUrl() + '/api/getSvgForColor?color=' + colorsForLabels[i].replace('#', '');
                        }),
                        width: 25,
                        imagedHeight: 25
                    },
                },
                colors: colorsForLabels,
                stroke: {
                    width: 0
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ['#111']
                    },
                    background: {
                        enabled: true,
                        foreColor: '#fff',
                        borderWidth: 0
                    },
                    formatter: function(value, { seriesIndex, dataPointIndex, w }) {
                        value = Math.round((value + Number.EPSILON) * 100) / 100;
                        let series1 = w.config.series[seriesIndex];
                        let label = w.config.labels[seriesIndex];
                        series1 = series1.toLocaleString(currentLangValue === 'ru' ? 'ru-RU' : 'en-US') + postfixString;
                        return `${label} (${value}%)`
                    }
                },
                legend: {
                    formatter: function(val, opts) {
                        let series1 = opts.w.globals.series[opts.seriesIndex];
                        if(series1 === undefined)
                            return val; 
                        return val + " - " + (series1.toLocaleString(currentLangValue === 'ru' ? 'ru-RU' : 'en-US') + postfixString)
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                },
                tooltip: {
                    enabled: true,
                    y: {
                        formatter: function (value, opts){
                            return value.toLocaleString(currentLangValue === 'ru' ? 'ru-RU' : 'en-US') + (postfix !== '' && func !== 'COUNT' ? ' ' + postfix : (func ==='COUNT' ? (getCurrentLangValue() === 'ru' ? ' ед.' : ' units') : ''));
                        }
                    }
                }
            }}
            series={series}
            //type="pie"
            type="donut"
            width={resultWidth}
        />}
    </div>);
}

export default PieChart