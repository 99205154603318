import React, {useEffect, useState} from 'react';
import './Comments.scss'
import api from "../../../utils/api";
import {
    Button,
    Comment,
    CommentAuthor,
    CommentAvatar,
    CommentContent, CommentGroup,
    CommentMetadata, CommentText, Form,
    Header,
    Icon, Input
} from "semantic-ui-react";
import getBackedUrl from "../../../utils/getBackedUrl";
import ViewRelativeTime from "../ViewRelativeTime/ViewRelativeTime";

export default function Comments({dictionary, id}) {
    const [comments, setComments] = useState([])
    const [page, setPage] = useState(0)
    const [message, setMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const load = () => {
        setIsLoading(true)
        api()
            .getCommentsForEntity(dictionary.strictNameMany, id, page)
            .then(resp => {
                setIsLoading(true)
                setComments(resp.data)
            })
    }
    useEffect(load, [])
    
    const send = () => {
        api()
            .sendCommentForEntity(dictionary.strictNameMany, id, message)
            .then(resp => {
                setMessage('')
                load()
            })
    }
    
    return <div className={'comments-for-dictionary-item-container'}>
        <CommentGroup>
            <Header as='h3'>
                Комментарии
            </Header>
            {comments.length > 4 ? <a><Icon name={'arrows alternate vertical'}/> Развернуть предыдуще комментарии</a> : ''}
            {comments.filter((x, i)=> i > comments.length - 5).map(comment => <Comment>
                <CommentAvatar src={`${getBackedUrl()}/api/avatar/byUser/${comment.fromId}`} />
                <CommentContent>
                    <CommentAuthor as='a'>{comment.fromName}</CommentAuthor>
                    <CommentMetadata>
                        <div><ViewRelativeTime dateTime={comment.createAt}/></div>
                    </CommentMetadata>
                    <CommentText>{comment.comment}</CommentText>
                </CommentContent>
            </Comment>)}
            <Form reply>
                <Input
                    value={message}
                    onChange={e=>{setMessage(e.target.value)}}
                    style={{width: '100%'}}
                    size={'large'}
                    placeholder={'Введите комментарий...'}
                />
                <Button
                    disabled={message == ''}
                    style={{position: 'absolute', right: '3px', top: '3px'}}
                    circular
                    icon='paper plane outline'
                    primary
                    onClick={send}
                />
            </Form>
        </CommentGroup>
    </div>
}