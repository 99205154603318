import React, {useEffect, useState} from 'react';
import {
    Form, FormField,
    Grid, GridColumn, GridRow,
    Icon,
    Input, Loader,
    Modal,
    ModalContent,
    ModalHeader, Pagination,
    Popup
} from 'semantic-ui-react'
import t, {getCurrentLangValue} from '../../../utils/translate'
import './NotificationsModal.css'
import api from "../../../utils/api";
import moment from "moment";
import 'moment/locale/ru';
import DatePicker from "react-datepicker";
import {getElementsFromFormattedText} from "../../../utils/textHelper";

export default function NotificationsModal({isOpenProp, isOpenPropSetter}) {
    let [notifications, setNotifications] = useState([]);
    let [isOpen, setIsOpen] = useState(isOpenProp);
    let [search, setSearch] = useState('');
    let [isLoading, setIsLoading] = useState(false);
    let [activePage, setActivePage] = useState(1);
    let [totalCount, setTotalCount] = useState(1);
    let [totalPageCount, setTotalPageCount] = useState(1);
    
    let [from, setFrom] = useState(null);
    let [to, setTo] = useState(null);
    
    let currentLangValue = getCurrentLangValue();

    let load = (pageIndex, from, to, search) => {
        setIsLoading(true);
        moment().locale('ru');
        api().getNotifications(pageIndex, (from == null ? '_' : new Date(from).toLocaleDateString("ru-RU")), (to == null ? '_' : new Date(to).toLocaleDateString("ru-RU")), search ?? '_').then(data=>{
            let notifications = data.notifications.map(n => {
                n.createAtNew = moment.utc(n.createAt).from(new Date( moment.utc().format() ));
                n.createAtHumanView = moment(new Date(new Date(n.createAt).getTime() - new Date().getTimezoneOffset() * 60000)).format(currentLangValue === 'ru' ? "DD.MM.yyyy HH:mm" : "MMMM D, yyyy h:mm a");// moment.utc(n.createAt).format(currentLangValue === 'ru' ? "DD.MM.yyyy HH:mm" : "MMMM D, yyyy h:mm a");
                return n;
            });
            setNotifications(notifications);
            setIsLoading(false);
            setTotalCount(data.totalCount)
            setTotalPageCount(data.totalPageCount)
        });
    };
    let onOpen = () => {
        setIsOpen(true);
        setSearch('');
        setNotifications([]);
        if(isOpenPropSetter !== null)
            isOpenPropSetter(true);
        load(0);
    };
    let close = () => {
        setIsOpen(false);
        if(isOpenPropSetter !== null)
            isOpenPropSetter(false);
    };
    useEffect(()=>{
        if(isOpenProp)
            onOpen();
    },[]);
    
    return <Modal
        size={"tiny"}
        open={isOpen}
        onClick={e => e.stopPropagation()}
        onOpen={onOpen}
        closeIcon
        onClose={() => {
            close();
        }}
        centered={false}
    >
        <ModalHeader>{t('notifications')}</ModalHeader>
        <ModalContent style={{minHeight: '400px'}}>
            {notifications.length === 0 && !isLoading && search === null ? <div style={{textAlign: 'center', paddingTop: '150px'}}>
                <Icon name={'inbox'} size={'big'}/>
                <p style={{marginTop: '30px'}}>{t('empty_notifications_msg')}</p>
            </div>: <Form>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Input 
                                icon={'search'}
                                type={'text'} 
                                value={search}
                                onChange={(e)=>{
                                    setSearch(e.target.value);
                                }}
                                onKeyPress={(e)=>{
                                    if(e.key === 'Enter'){
                                        setActivePage(1);
                                        load(0, from, to, search);
                                    }
                                }}
                                placeholder={'Поиск по содержанию'}
                                style={{width: '100%'}}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}> 
                            
                                <FormField>
                                    <DatePicker
                                        placeholderText={'С'}
                                        isClearable
                                        dateFormat={currentLangValue === 'ru' ? "dd.MM.yyyy" : "MMMM d, yyyy"}
                                        timeCaption={t('timeHeader')}
                                        locale={currentLangValue}
                                        selected={from}
                                        onChange={date => {
                                            setFrom(date);
                                            setActivePage(1);
                                            load(0, date, to, search);
                                        }}
                                    />
                                </FormField>
                            </Grid.Column>
                        <Grid.Column width={4} >
                            <FormField>
                                <DatePicker
                                    placeholderText={'По'}
                                    isClearable
                                    dateFormat={currentLangValue === 'ru' ? "dd.MM.yyyy" : "MMMM d, yyyy"}
                                    timeCaption={t('timeHeader')}
                                    locale={currentLangValue}
                                    selected={to}
                                    onChange={date => {
                                        setTo(date);
                                        setActivePage(1);
                                        load(0, from, date, search);
                                    }}

                                />
                            </FormField>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <div>
                    {isLoading ? <div style={{position: 'absolute', width: '100%', height: '300px', textAlign: 'center', paddingTop: '100px', backgroundColor: 'rgba(0,0,0, 0.1)' }}><Loader active inline size='massive'></Loader></div>  : ''}
                </div>
                {totalCount === 0 ? <div style={{width: '100%', textAlign: 'center', paddingTop: '30px'}}>Не найдено</div> : ''}
                {totalPageCount > 1 || activePage !== 1 ? <Grid >
                    <GridRow>
                        <GridColumn width={8} style={{paddingTop: '10px'}}>
                            Всего: {totalCount}
                        </GridColumn>
                        <GridColumn style={{textAlign: 'right'}} width={8}>
                            <Pagination
                                boundaryRange={0}
                                defaultActivePage={1}
                                ellipsisItem={null}
                                firstItem={null}
                                lastItem={null}
                                siblingRange={1}
                                totalPages={totalPageCount}
                                onPageChange={(event, data)=>{
                                    setActivePage(data.activePage);
                                    load(data.activePage - 1, from, to, search);
                                }}
                                activePage={activePage}
                            />

                        </GridColumn>
                    </GridRow>
                </Grid> : ''}
                {notifications.map(notification =>{
                    return <div style={{
                        border: '1px solid rgba(34,36,38,.15)', 
                        borderRadius: '5px', 
                        padding: '15px',
                        paddingTop: '30px',
                        width: '100%',
                        marginTop: '20px',
                        position: 'relative'
                    }}>
                        <div style={{position: 'absolute', right: '15px', top: '5px', color: '#a0a0a0', cursor: 'default'}}><Popup basic content={notification.createAtHumanView} trigger={<span>{notification.createAtNew}</span>} /></div>
                        {getElementsFromFormattedText(notification.message)}
                    </div>;
                })}
            </Form>}
        </ModalContent>
    </Modal>;
}