import React, {useEffect, useState} from 'react';
import Clock from "react-clock";
import 'react-clock/dist/Clock.css';

const ReportClock = ({useAnalogClock, width, height, onePercentInPixelForWidth}) => {
    const [value, setValue] = useState(new Date());
    
    useEffect(() => {
        const interval = setInterval(
            () => setValue(new Date()),
            1000
        );

        return () => {
            clearInterval(interval);
        }
    }, []);

    if(useAnalogClock)
        return <Clock value={value} size={(width > height ? height : width)}/>;
    let c = 0.1;
    let fontHeight = width * c;
    
    return <div style={{fontSize:  fontHeight  + 'px', textAlign: 'left'}}>
        <div style={{fontSize:  fontHeight  + 'px'}}>{value.toLocaleTimeString('ru-RU', {hour: '2-digit', minute:'2-digit'})}</div>
        <div style={{fontSize:  fontHeight  + 'px'}}>{new Intl.DateTimeFormat('ru-RU', { weekday: 'long'}).format(value)}, {new Intl.DateTimeFormat('ru-RU', { month:'long', day:'numeric'}).format(value)}</div>
    </div>;
    
}

export default ReportClock