import React, {useEffect, useState} from 'react';
import './PythonTasksInstruction.scss'
import {
    Container, Divider,
    Header,
    List,
    ListItem,
    ListList, Loader
} from "semantic-ui-react";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Intro from "./components/Intro/Intro";
import Structure from "./components/Structure/Structure";
import Dictionaries from "./components/Dictionaries/Dictionaries";
import System from "./components/System/System";
import Enums from "./components/Enums/Enums";
import api from "../../utils/api";
import {getRole} from "../../utils/authHelper";
import getBackedUrl from "../../utils/getBackedUrl";
import appConfig from "../../utils/appConfig";
import EnumsOfSystem from "./components/EnumsOfSystem/EnumsOfSystem";
import Context from "./components/Context/Context";
import ConnectToAnotherApplication from "./components/ConnectToAnotherApplication/ConnectToAnotherApplication";


export default function PythonTasksInstruction() {
    const [configuration, setConfiguration] = useState(null)

    const isDeveloperInConfigurator = appConfig().info.location === 'configurator.report.ms' &&
        getRole() == 'Developer' &&
        localStorage.getItem('GlobalFilterValue') != null;
    
    useEffect(() => {
        let getConfigurationMethod = api()
            .getConfiguration
        
        if(isDeveloperInConfigurator){
            getConfigurationMethod = () => {
                return fetch(getBackedUrl() + '/api/getConfigurationForApp?id=' + localStorage.getItem('GlobalFilterValue'), {
                    method: 'Get',
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                        "Authorization": 'Bearer ' + JSON.parse(localStorage.Identity).accessToken
                    }
                })
                    .then(x=>x.json())
            }
        }
        
        getConfigurationMethod()
            .then(configuration => {
                setConfiguration(configuration)
            })
    }, [])
    if(configuration == null)
        return <Loader active />
    
    return (<div className={'python-tasks-instruction-page'}>
        <Container text>
            <Header as='h1'>Руководство по написанию фоновых задач</Header>
            <List as='ul'>
                <ListItem as='li'><a href={'#intro'}>Введение</a></ListItem>
                <ListItem as='li'>
                    <a href={'#dictionaries'}>Как работать со справочниками</a>
                    <ListList as='ul'>
                        <ListItem as='li'><a href='#dictionaries-create'>Создание</a></ListItem>
                        <ListItem as='li'><a href='#dictionaries-search'>Поиск</a></ListItem>
                        <ListItem as='li'><a href='#dictionaries-search'>Получения значения поля</a></ListItem>
                        <ListItem as='li'><a href='#dictionaries-search'>Установка значения поля</a></ListItem>
                        <ListItem as='li'><a href='#dictionaries-delete'>Удаление</a></ListItem>
                        <ListItem as='li'><a href='#dictionaries-action'>Действие</a></ListItem>
                    </ListList>
                </ListItem>
                <ListItem as='li'><a href={'#structure'}>Справочники вашей системы</a></ListItem>
                <ListItem as='li'>
                    <a href={'#enums'}>Как работать с перечислениями</a>
                    <ListList as='ul'>
                        <ListItem as='li'><a href='#enums-search'>Поиск</a></ListItem>
                        <ListItem as='li'><a href='#enums-set'>Присваивание</a></ListItem>
                    </ListList>
                </ListItem>
                <ListItem as='li'><a href={'#enums-of-system'}>Перечисления вашей системы</a></ListItem>
                <ListItem as='li'>
                    <a href={'#context'}>Контекст</a>
                    <ListList as='ul'>
                        <ListItem as='li'><a href='#context-dictionaty'>Объект справочника</a></ListItem>
                        <ListItem as='li'><a href='#context-user'>Пользователь</a></ListItem>
                        <ListItem as='li'><a href='#context-parameters'>Параметры</a></ListItem>
                    </ListList>
                </ListItem>
                <ListItem as='li'>
                    <a href={'#system'}>Система</a>
                    <ListList as='ul'>
                        <ListItem as='li'><a href='#system-notifications'>Уведомления</a></ListItem>
                        <ListItem as='li'><a href='#system-mail-send'>Отправить письмо</a></ListItem>
                        <ListItem as='li'><a href='#system-chat-send'>Отправить сообщение в чат</a></ListItem>
                        <ListItem as='li'><a href='#system-sql-request'>Sql запрос</a></ListItem>
                        <ListItem as='li'><a href='#system-parameters'>Параметры</a></ListItem>
                        <ListItem as='li'><a href='#system-tasks'>Плановые задачи</a></ListItem>
                        <ListItem as='li'><a href='#system-api-methods'>Api методы</a></ListItem>
                        <ListItem as='li'><a href='#system-files'>Работа с файлами</a></ListItem>
                    </ListList>
                </ListItem>
                {isDeveloperInConfigurator ? <ListItem as='li'><a href={'#connect-to-another-application'}>Связь с другими приложениями</a></ListItem> : ''}
            </List>

            <Intro />
            <Dictionaries />
            <Structure configuration={configuration} />
            <Enums configuration={configuration} />
            <EnumsOfSystem configuration={configuration} />
            <Context />
            <System />
            {isDeveloperInConfigurator ? <ConnectToAnotherApplication /> : ''}
        </Container>
        <ScrollToTop />
    </div>);
}