import {
    Button, Dimmer,
    Form,
    Header,
    Icon,
    Loader,
    Message,
    Modal,
    Popup, Segment
} from "semantic-ui-react";

import React, { useState } from 'react';
import * as PropTypes from "prop-types";
import t from "../../utils/translate";
import api from "../../utils/api";
import {getElementsFromFormattedText} from "../../utils/textHelper";
import reportColumnTypes from "../../utils/columnTypes";
import FileFormInput from "../fieldTypes/file/FileFormInput";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';


export function UploadFromExcel(props) {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(null);
    let states = {
        readyForLoad: 'readyForLoad',
        loading: 'loading',
        result: 'result',
    };
    const [state, setState] = React.useState(states.readyForLoad);
    const [isLoading, setIsLoading] = React.useState(false);
    let [resp, setResp] = useState(null);
    let [dictionaryItemsForSelects, setDictionaryItemsForSelects] = useState([]);
    let [enumItemsForSelects, setEnumItemsForSelects] = useState([]);

    let reportTypes = reportColumnTypes();
    let onClickUpload = () => {
        if(value == null) 
            return;
        setIsLoading(true);
        setState(states.loading);
        api()
            .uploadByExcel(JSON.parse(value).id, props.view.viewName, props.dictionaryItemMetaDescription.strictNameMany)
            .then(resp=>{
                setIsLoading(false);
                setResp(resp);
                setState(states.result);
                props.onLoad();
                //HandleResponse(resp);
            })
        ;
    };
	
	let downloadExampleExcel = () => {
		let obj = {}
		let columns = props.dictionaryItemMetaDescription
                                    .columns
                                    //.filter(column => column !== props.globalFilterColumn)
                                    .filter(column => !column.hideInList)
                                    .filter(column => column.type !== 'innerTable')
                                    .map(col=>col.ruName);
		for (let a in columns) {
			obj[columns[a]] = ''
		}
		const ws = XLSX.utils.json_to_sheet([obj]);
		const wb = {Sheets: { 'data': ws }, SheetNames: [props.dictionaryItemMetaDescription.ruNameMany]};
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});
		const data = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'});
		FileSaver.saveAs(data, props.dictionaryItemMetaDescription.ruNameMany + '.xlsx')
	}

  
    return <Modal
        open={open}
        onClose={() => {
            setOpen(false);
            setValue(null);
            setState(states.readyForLoad);
        }}
        onClick={(e)=>{e.stopPropagation();}}
        size='mini'
        closeOnEscape={!isLoading}
        closeOnDimmerClick={!isLoading}
        trigger={<Popup content={t('loadFromExcelBtn')} trigger={<Button onClick={()=>{setOpen(true)}} icon={(props.as !== 'button')}>
            {(props.as === 'button' ? t('loadFromExcelBtn'): <Icon name='file excel'/>)}
        </Button>} /> }

    >
        <Header>
            {t('UploadFromExcel')}
        </Header>
        <Modal.Content onClick={(e)=>{e.stopPropagation();}}>
            {state == states.readyForLoad ? <>
                <Message
                    icon={'file excel'}
                    content={<>
                        {getElementsFromFormattedText(t("SelectTheExcelFileWithTheData"))}
						<a href={'#'} onClick={()=>{downloadExampleExcel()}}>{t('LikeInExample')}</a>.</>}
                />
                <Form autoComplete="off">
                    <FileFormInput
                        name={''}
                        value={value}
                        column={{example: ''}}
                        onChangeFieldValue={(name, value)=>{setValue(value);}}
                    />
                </Form>
            </> : ""}
            {state == states.loading ? <>
                <Segment basic={true}>
                    <Dimmer active inverted>
                        <Loader inverted>{t('ProcessingAnExcelFile')}</Loader>
                    </Dimmer>
                </Segment>
            </> : ""}            
            {state == states.result ? <>
                <Message
                    color={resp.type == 1 ? 'red' : ''}
                    content={<>
                        {getElementsFromFormattedText(resp.message)}
                    </>}
                />
            </> : ""}            
        </Modal.Content>
        <Modal.Actions>
            {state == states.readyForLoad ? <>
                <Button
                    disabled={(value == null)}
                    primary
                    onClick={onClickUpload}
                    loading={isLoading}
                >
                    {t("Upload")}
                </Button>
            </> : ""}

            {state == states.result ? <>
                <Button
                    onClick={()=>{
                        setValue(null);
                        setState(states.readyForLoad);
                    }}
                >
                    {t("DownloadAnotherExcel")}
                </Button>
                <Button
                    primary
                    onClick={()=>{
                        setOpen(false);
                        setValue(null);
                        setState(states.readyForLoad);
                    }}
                >
                    {t("Close")}
                </Button>
            </> : ""}
        </Modal.Actions>
    </Modal>;
}

UploadFromExcel.propTypes = {
    view: PropTypes.any,
    globalFilterColumn: PropTypes.any,
    dictionaryItemMetaDescription: PropTypes.any,
    onLoad: PropTypes.func
};