import React, {useEffect, useState} from 'react';
import {Loader} from "semantic-ui-react";
import { FunnelChart } from 'react-funnel-pipeline'
import 'react-funnel-pipeline/dist/index.css'

let getTitle = (element)=>{
    if(element.viewTitle && (element.ruTitle === undefined || element.ruTitle === null || element.ruTitle === '')){

        let fieldForValue = JSON.parse(element.dictionaryFieldForValue);
        let dictionaryNameForValue = fieldForValue.value.split('"."')[0].substr(1);
        let fieldNameForValue = fieldForValue.value.split('"."')[1];

        fieldNameForValue = fieldNameForValue.substr(0, fieldNameForValue.length - 1);

        let fieldForLabel = JSON.parse(element.dictionaryFieldForLabel);
        let dictionaryNameForLabel = fieldForLabel.value.split('"."')[0].substr(1);
        let fieldNameForLabel = fieldForLabel.value.split('"."')[1];

        fieldNameForLabel = fieldNameForLabel.substr(0, fieldNameForLabel.length - 1);

        if(dictionaryNameForLabel === dictionaryNameForValue)
            return `${fieldNameForValue} по ${fieldNameForLabel} (${dictionaryNameForValue})`;
        return `${fieldNameForValue} из ${dictionaryNameForValue} по ${fieldNameForLabel} из ${dictionaryNameForLabel}`;
    }

    return element.ruTitle;
}

const Funnel = ({
                       getData,
                       pageIndex,
                       elementIndex,
                       element,
                       width,
                       height,
                       onePercentInPixelForWidth,
                       onePercentInPixelForHeight,
                       filtersValues
                   }) => {
    let [isLoading, setIsLoading] = useState(true);
    let [data, setData] = useState([]);
    let [labels, setLabels] = useState([]);
    let [series, setSeries] = useState([]);

    let labelIndex = 0;
    let dataIndex = 1;
//debugger;
    if(JSON.parse(element.dictionaryFieldForLabel).fieldType === 'LinkToEnum'){
        labelIndex = 1;
        dataIndex = 2;
    }

    useEffect(() => {
        getData(pageIndex, elementIndex, filtersValues)
            .then(values => {
                setData(values
                    .map(x=> {x[dataIndex] = parseFloat(x[dataIndex]); return x;})
                    .sort(function (a, b) {
                        if (a[dataIndex] > b[dataIndex]) {
                            return -1;
                        }
                        if (a[dataIndex] < b[dataIndex]) {
                            return 1;
                        }
                        return 0;
                    })
                    .map(x=> {return { name: x[labelIndex] == ''? '<Пусто>' : x[labelIndex], value: x[dataIndex] };}))
                setIsLoading(false);
            });
        setIsLoading(false);
    }, []);

    return (<div style={{marginTop: '20px'}}>
        {!isLoading ? <div style={{textAlign: 'left', fontSize: (onePercentInPixelForWidth*1.5) + 'px', padding: (onePercentInPixelForWidth) + 'px'}}>{getTitle(element)}</div> : ''}
        {isLoading ? <Loader active inline size='massive'></Loader> : <FunnelChart
            heightRelativeToValue
            chartHeight={height - 100}
            data={data}
        />}
    </div>);
}

export default Funnel