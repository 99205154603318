import React from 'react';
import './ViewRelativeTime.css'
import {Popup} from "semantic-ui-react";
import moment from "moment/moment";
import {getCurrentLangValue} from "../../../utils/translate";

function viewTimeFromNow (time, currentLangValue) {
    let momentTime = moment.utc(time)
    let now = moment.utc()
    let result = momentTime.from(new Date(now.format()))
    
    if(result === 'через несколько секунд' && currentLangValue == 'ru')
        return 'только что'
    
    if(result === 'in a few seconds' && currentLangValue == 'en')
        return 'just now'
    return result
}

export default function ViewRelativeTime({dateTime, classNameOverride = 'update-at'}) {
    let currentLangValue = getCurrentLangValue();
    return <Popup
        basic
        inverted
        position={'left center'}
        content={moment.utc(dateTime).local().format(currentLangValue === 'ru' ? "DD.MM.yyyy HH:mm" : "MMMM D, yyyy h:mm a")}
        trigger={<div style={{cursor: "default"}} className={classNameOverride}>{viewTimeFromNow(dateTime, currentLangValue)}</div>} />
}