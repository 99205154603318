import appConfig from "./appConfig";

export default function t(a, configuration) {
    let arr = [
        'LicenseIsExpired;License is expired;Лицензия истекла',
        'language;Language;Язык',
        'help_menu_link_title;Help;Помощь',
        'Russian;Russian;Русский',
        'English;English;Английский',
        'login_btn;Login;Войти',
        'forgot_pass;Forgot password?;Забыли пароль?',
        'password_placeholder;Password;Пароль',
        'entities;Entities;Сущности',
        'dictionaries;Dictionaries;Справочники',
        'roles;Roles;Роли',
        'roles;Roles;Роли',
        'userFields;Addition user fields;Дополнительные поля пользователя',
        'properties;Properties;Свойства',
        'projects;Projects;Проекты',
        'users;Users;Пользователи',
        'exit;Exit;Выход',
        'notifications;Notifications;Уведомления',
        'empty_notifications_msg;You don\'t have any notifications yet;У вас пока  нет уведомлений',
        'More;More;Ещё',
        'LicenseInfoMsg;Free test use up to;Бесплатное тестовое использование до',
        'LicenseInfoBtn;Buy a license;Купить лицензию',
        'Choose file;Choose file;Выберите файл',
        'edit;Edit;Редактировать',
        'add;Add;Добавить',
        'No results found.;No results found.;Не найдено.',
        'Create;Create;Создать',
        'Created;Created;Создано',
        'Creating;Creating;Создание',
        'Editing;Editing;Редактирование',
        'profile;Profile;Профиль',
        'ViewNameSingle_profile;Profile;Профиль',
        'saveChanges;Save;Сохранить',
        'createInBase;Create;Создать',
        'addItem;Add;Добавить',
        'delete;Delete;Удалить',
        'Remove_it;Delete;Удалить',
        'login_is_empty;Login is empty;Email не заполнен',
        'password_is_empty;Password is empty;Пароль не заполнен',
        'wrong_user_or_password;Wrong user or password;Неверный Email или пароль',
        'role_not_configured;Your user role is not configured. There are no available directories to display.;Роль вашего пользователя не настроена. Нет доступных справочников для отображения.',
        'Introduction_start_btn;Start;Начать',
        'Introduction_next_btn;Next;Далее',
        'Loading;Loading;Подготовка',
        'password_recovery_modal_titlte;Password Recovery;Восстановление пароля',
        'password_recovery_modal_message;Enter the email address that you use to log in.;Введите email, который вы используете для входа.',
        'password_recovery_modal_message_about_sent;We have sent a confirmation of password reset to your email.\nClick on the link in the email to continue.\nNo email? *Check the Spam folder*;Мы отправили подтверждение сброса пароля на ваш email.\nПерейдите по ссылке в письме, чтобы продолжить.\nНет письма? *Проверьте папку «Спам»*',
        'password_recovery_modal_cancel_btn;Cancel;Отказаться',
        'password_recovery_modal_recovery_btn;Recovery;Восстановить',
        'set_password_page_message;Set a new password.;Установите новый пароль.',
        'set_password_page_field_label;New password;Новый пароль',
        'set_password_page_cancel_btn;Opt out and go to the login page.;Отказаться и перейти на страницу входа.',
        'set_password_page_set_btn;Set;Установить',
        'set_password_page_finish_message;The password was successfully set.;Пароль успешно установлен.',
        'set_password_page_finish_btn;Login;Войти',
        'available_actions_lbl_in_modal;Available actions;Доступные действия',
        'available_tasks;Available tasks;Доступные задачи',
        'iconPicker_Clear;Clear;Очистить',
        'there are no others;There are no others;Нет других',
        'errorLoginToken;Error logging in to the app;Ошибка при входе в приложение',
        'speakClearlyAndLoudly;Speak clearly and loudly;Говорите чётко и громко',
        'timeHeader;Time;Время',
        'loadFromExcelBtn;Load from Excel;Загрузить из Excel',
        'exportToExcelBtn;Export to Excel;Выгрузить в Excel',
        'or_in_empty_dictonary_list;or;или',
        'noAvailableItemsInDictionaryList;No available, you can;Нет доступных, вы можете',
        'noAvailableItemsInDictionaryListWithoutActions;No available;Нет доступных',
        'Close;Close;Закрыть',
        'Upload;Upload;Загрузить',
        'ProcessingAnExcelFile;Processing an excel file;Обработка excel файла',
        'DownloadAnotherExcel;Download another Excel;Загрузить другой Excel',
        'SelectTheExcelFileWithTheData;Select the Excel file with the data. The file must contain the first line with the names of the fields, as in;Выберите файл Excel с данными. Файл должен содержать первую строку с названиями полей, как в ',
        'LikeInExample;example;примерe',
        'UploadFromExcel;Upload from Excel;Загрузить из Excel',
        'No available;No available;Нет доступных',
        'There are no actions available for this unit.;There are no actions available for this unit.;Для данной единицы нет доступных действий.',
        'actions_lbl_name;Actions;Действия',
        'actions_lbl_name_for_no_available;No actions available;Нет доступных действий',
        'Apply;Apply;Применить',
        'Cancel;Cancel;Отменить',
        'Id;Id;Идентификатор',
        'Identifier;Identifier;Идентификатор',
        'CreateAt;Create at;Создано',
        'And also;And also;А также',
        'Fill_required_fields;Fill required fields;Заполните обязательные поля',
        'placeholder_for_all_fields_search;Enter a request...;Введите запрос...',
        'clear_filters_btn;Clear filters;Сбросить фильтры',
        'not_found_message_after_filter;Not found;Ничего не найдено',
        'not_set;Not set;Не установлено',
        'select_##DICTIONARY_NAME##_from_list;Select ##DICTIONARY_NAME## from list;Выберите ##DICTIONARY_NAME## из списка',
        'Enter a search phrase;Enter a search phrase;Введите фразу для поиска',
        'Apply;Apply;Применить',
        'Reset the filter for this field;Reset the filter for this field;Сбросить фильтр для этого поля',
        'From;From;От',
        'To;To;До',
        'since_the;since the;с',
        'until;until;по',
        'Select date;Select date;Выберите дату',
        'Tomorrow;Tomorrow;Завтра',
        'Today;Today;Сегодня',
        'Yesterday;Yesterday;Вчера',
        'Date Selection;Date Selection;Выбор даты',
        'Between dates;Between dates;Между датами',
        'Exact date;Exact date;Точная дата',
        'many_Date selection;Date selection;Выбор дат',
        'Last week;Last week;Прошлая неделя',
        'This week;This week;Эта неделя',
        'Next week;Next week;Следующая неделя',
        'Contains;Contains;Содержит',
        'Does not contain;Does not contain;Не содержит',
        'Starts with;Starts with;Начинается',
        'Ends with;Ends with;Заканчивается',
        'Equal;Equal;Совпадает',
        'Reset all filters;Reset all filters;Сбросить все фильтры',
        'Hide_sum_panel;Hide;Свернуть',
        'Sum_label_sum_panel;Sum;Сумма',
        'Empty;Empty;Пусто',
        'set_duration_btn;Set;Установить',
        'set_duration_modal_title;Duration;Продолжительность',
        'set_duration_modal_years;Years;Годы',
        'set_duration_modal_months;Months;Месяцы',
        'set_duration_modal_days;Days;Дни',
        'set_duration_modal_hours;Hours;Часы',
        'set_duration_modal_minutes;Minutes;Минуты',
        'select_avatar_input_choose_an_avatar;Choose an avatar;Выбрать аватар',
        'select_avatar_input_change_the_avatar;Change the avatar;Изменить аватар',
        'select_avatar_input_delete_an_avatar;Delete an avatar;Удалить аватар',

        'select_image_input_choose_an_photo;Choose an photo;Выбрать фото',
        'select_image_input_change_the_photo;Change the photo;Изменить фото',
        'select_image_input_delete_an_photo;Delete an photo;Удалить фото',
        'select_image_input_add_the_photo;Add the photo;Добавить фото',

        'calendar_day_view_name;Day;День',
        'calendar_week_view_name;Week;Неделя',
        'calendar_month_view_name;Month;Месяц',
        
        'admin_menu_system_events;System events;Системные события',
        'admin_menu_background_tasks;Background tasks;Фоновые задачи',
        'admin_menu_no_recurring_tasks_available;There are no recurring tasks available;Нет доступных повторяющихся задач',
        'admin_menu_public_pages;Public pages;Публичные страницы',
        'admin_menu_parameters;Parameters;Параметры',
    ];
    arr = arr.concat(configuration !== undefined ? configuration.translates : appConfig().translates)
    let filter = arr.filter(x=> x.split(';')[0] === a);
    let index = getCurrentLangValue() === 'ru' ? 2 : 1;
    
    if(filter.length > 0){
        return filter[0].split(';')[index]; 
    }
    return a;
}

export function getCurrentLangValue() {
    return (localStorage.getItem('currentLangValue') ?? 'ru') === 'ru' ? 'ru' : 'en';
}

export function setCurrentLangValue(lang) {
    localStorage.setItem('currentLangValue', lang);
}