import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Form, Icon, Menu, Modal} from "semantic-ui-react";
import {getCurrentLangValue} from "../../utils/translate";
import ValueReportElement from "./Elements/Value/ValueReportElement";
import PieChart from "./Elements/PieChart/PieChart";
import Histogram from "./Elements/Histogram/Histogram";
import Graph from "./Elements/Graph/Graph";
import LineDiagram from "./Elements/LineDiagram/LineDiagram";
import './ReportViewer.css'
import {useWindowSize} from "../../utils/windowSize";
import RecognitionInput from "../../shared/components/RecognitionInput";
import {Resizable} from 'react-resizable';
import 'react-resizable/css/styles.css';
import Draggable from 'react-draggable';
import FilterReportElement from "./Elements/Filter/FilterReportElement";
import ReportMap from "./Elements/Map/Map";
import ShapeReportElement from "./Elements/Shapes/ShapeReportElement";
import TextReportElement from "./Elements/Text/Text";
import TableReportElement from "./Elements/TableReportElement/TableReportElement";
import Funnel from "./Elements/Funnel/Funnel";
import Indicator from "./Elements/Indicator/Indicator";
import GanttDiagram from "./Elements/GanttDiagram/GanttDiagram";
import WebElement from "./Elements/WebElement/WebElement";
import ActionElement from "./Elements/ActionElement/ActionElement";

const getComponent = (isEdit, dataColors, enumColors, getData, currentPage, index, element, width, height, onePercentInPixelForWidth, onePercentInPixelForHeight, isConfigured, filtersValues, setFiltersValues) =>{
    let notConfiguredComponent = (imgUrl) => {
        let widthByHeight = width/height > 1.3225806;
        return <img
            style={{paddingTop: (widthByHeight ? '0px' : ((height)/2 - ((width * 31)/41)/2) + 'px')}}
            className={'placeholder-img ' + (widthByHeight ? 'left-right-auto': 'top-bottom-auto')}
            src={imgUrl}
        />;
    };
    
    switch (element.type) {
        case 'PieChart': 
            if(!isConfigured)
                return notConfiguredComponent('https://static.tildacdn.com/tild6339-3331-4565-b733-316362636533/PieChart_big.svg');
            return <PieChart
            isEdit={isEdit}
            getData={getData}
            pageIndex={currentPage}
            dataColors={dataColors}
            enumColors={enumColors}
            width={width}
            height={height}
            onePercentInPixelForWidth={onePercentInPixelForWidth}
            onePercentInPixelForHeight={onePercentInPixelForHeight}
            
            elementIndex={index}
            element={element}
            filtersValues={filtersValues}
            />;

        case 'Histogram':
            if(!isConfigured)
                return notConfiguredComponent('https://static.tildacdn.com/tild3838-6264-4737-b065-353362323965/Histogram_big.svg');
            return <Histogram
                getData={getData}
                pageIndex={currentPage}
                width={width}
                height={height}
                onePercentInPixelForWidth={onePercentInPixelForWidth}
                onePercentInPixelForHeight={onePercentInPixelForHeight}

                elementIndex={index}
                element={element}
                filtersValues={filtersValues}
            />;

        case 'Graph':
            if(!isConfigured)
                return notConfiguredComponent('https://static.tildacdn.com/tild3063-3364-4939-b065-623166613065/Graph_big.svg');
            return <Graph
                getData={getData}
                pageIndex={currentPage}
                width={width}
                height={height}
                onePercentInPixelForWidth={onePercentInPixelForWidth}
                onePercentInPixelForHeight={onePercentInPixelForHeight}

                elementIndex={index}
                element={element}
                filtersValues={filtersValues}
            />;

        case 'LineDiagram':
            if(!isConfigured)
                return notConfiguredComponent('https://static.tildacdn.com/tild3433-3939-4763-a532-353766376239/LineDiagram_big_1.svg');
            return <LineDiagram
                getData={getData}
                pageIndex={currentPage}
                width={width}
                height={height}
                onePercentInPixelForWidth={onePercentInPixelForWidth}
                onePercentInPixelForHeight={onePercentInPixelForHeight}

                elementIndex={index}
                element={element}
                filtersValues={filtersValues}
            />;

        case 'Table':
            if(!isConfigured)
                return notConfiguredComponent('https://static.tildacdn.com/tild6161-3638-4434-b564-396166353732/Table_big.svg');
            return <TableReportElement
                getData={getData}
                pageIndex={currentPage}
                width={width}
                height={height}
                onePercentInPixelForWidth={onePercentInPixelForWidth}
                onePercentInPixelForHeight={onePercentInPixelForHeight}

                elementIndex={index}
                element={element}
                filtersValues={filtersValues}
            />;

        case 'Image':
            if(!isConfigured)
                return notConfiguredComponent('https://static.tildacdn.com/tild3630-3933-4933-b133-653036386361/Image_big.svg');
            return <div 
                style={{
                    backgroundSize: 'cover', 
                    backgroundRepeat: 'no-repeat', 
                    backgroundPosition: 'center', 
                    height: height + 'px',
                    backgroundImage: 'url('+element.imageUrlForImageField+')',
                    overflow: 'hidden'
                }}
            >{element.blurImage ? <div className="blur"/>: ''}</div>;

        case 'Text':
            if(!isConfigured)
                return notConfiguredComponent('https://static.tildacdn.com/tild6432-3636-4134-a634-353639623662/Text_big.svg');
            return <TextReportElement
                isEdit={isEdit}
                onePercentInPixelForWidth={onePercentInPixelForWidth}
                onePercentInPixelForHeight={onePercentInPixelForHeight}
                element={element}
            />;

        case 'Value':
            if(!isConfigured)
                return notConfiguredComponent('https://static.tildacdn.com/tild6231-6164-4433-a561-336337643537/Value_big.svg');
            return <ValueReportElement
                getData={getData}
                pageIndex={currentPage}
                width={width}
                height={height}
                onePercentInPixelForWidth={onePercentInPixelForWidth}
                onePercentInPixelForHeight={onePercentInPixelForHeight}

                elementIndex={index}
                element={element}

                filtersValues={filtersValues}
                isEdit={isEdit}
            />;

        case 'Funnel':
            if(!isConfigured)
                return notConfiguredComponent('https://static.tildacdn.com/tild3837-6231-4461-a530-346639333831/Funnel_big.svg');
            return <Funnel
                getData={getData}
                pageIndex={currentPage}
                width={width}
                height={height}
                onePercentInPixelForWidth={onePercentInPixelForWidth}
                onePercentInPixelForHeight={onePercentInPixelForHeight}

                elementIndex={index}
                element={element}
                filtersValues={filtersValues}
            />;

        case 'Filter':
            if(!isConfigured)
                return notConfiguredComponent('https://static.tildacdn.com/tild3238-6337-4236-b436-613339303665/Filter_big.svg');
            return <FilterReportElement
                getData={getData}
                pageIndex={currentPage}
                width={width}
                height={height}
                onePercentInPixelForWidth={onePercentInPixelForWidth}
                onePercentInPixelForHeight={onePercentInPixelForHeight}

                elementIndex={index}
                element={element}
                
                filtersValues={filtersValues}
                setFiltersValues={setFiltersValues}
            />;

        case 'Map':
            if(!isConfigured)
                return notConfiguredComponent('https://static.tildacdn.com/tild6332-6634-4661-b065-336239323937/Map_big.svg');
            return <ReportMap
                width={width}
                height={height}/>;

        case 'GanttDiagram':
            if(!isConfigured)
                return notConfiguredComponent('https://static.tildacdn.com/tild6264-3333-4562-b364-353564333132/GanttChart_1.svg');
            return <GanttDiagram
                getData={getData}
                pageIndex={currentPage}
                width={width}
                height={height}
                onePercentInPixelForWidth={onePercentInPixelForWidth}
                onePercentInPixelForHeight={onePercentInPixelForHeight}

                elementIndex={index}
                element={element}
                filtersValues={filtersValues}
            />;

        case 'Indicator':
            if(!isConfigured)
                return notConfiguredComponent('https://static.tildacdn.com/tild3531-3735-4463-b136-616437336437/Indicator_big.svg');
            return <Indicator
                getData={getData}
                pageIndex={currentPage}
                width={width}
                height={height}
                onePercentInPixelForWidth={onePercentInPixelForWidth}
                onePercentInPixelForHeight={onePercentInPixelForHeight}

                elementIndex={index}
                element={element}
                filtersValues={filtersValues}
            />;
            
        case 'Shapes':
            if(!isConfigured)
                return notConfiguredComponent('https://static.tildacdn.com/tild3134-3430-4165-b666-383162373231/Shapes_1.svg');
            return <ShapeReportElement
                pageIndex={currentPage}
                width={width}
                height={height}
                onePercentInPixelForWidth={onePercentInPixelForWidth}
                onePercentInPixelForHeight={onePercentInPixelForHeight}
                elementIndex={index}
                element={element}            />;
        case 'Web':
            if(!isConfigured)
                return notConfiguredComponent('https://static.tildacdn.com/tild6131-3735-4062-b962-306637643136/Web_1.svg');
            return <WebElement
                isEdit={isEdit}
                onePercentInPixelForWidth={onePercentInPixelForWidth}
                onePercentInPixelForHeight={onePercentInPixelForHeight}
                element={element}
                width={width}
                height={height}
            />;
            
        case 'Action':
            if(!isConfigured)
                return notConfiguredComponent('https://static.tildacdn.com/tild3039-6533-4663-b662-623036613464/Action_2.svg');
            return <ActionElement
                onePercentInPixelForWidth={onePercentInPixelForWidth}
                onePercentInPixelForHeight={onePercentInPixelForHeight}
                element={element}
            />;
            
    }
} 

const ReportViewer = ({   isEdit,
                          isViewElementBorder,
                          pages,
                          dataColors,
                          enumColors,
                          getData, 
                          onAddPage, 
                          onChangePage, 
                          onRemovePage, 
                          onRemoveElement,
                          onCloneElement,
                          onMoveElementToFront,
                          onMoveElementToBack, 
                          onElementFocus, 
                          onPageFocus, 
                          onSetElementPosition,
                          onMovePageRight,
                          onMovePageLeft, 
                          width,
                          pageIndexForFocusedElement,
                          elementIndexForFocusedElement}) => {
    let [isFullscreen, setIsFullscreen] = useState(false);
    let [viewLeftPageSelectorButton, setViewLeftPageSelectorButton] = useState(false);
    let [viewRightPageSelectorButton, setViewRightPageSelectorButton] = useState(false);
    let [isHidePageSelector, setIsHidePageSelector] = useState(false);
    let [currentDateTime, setCurrentDateTime] = useState(null);
    let [lastMoveMouseDateTime, setLastMoveMouseDateTime] = useState(null);
    let [hideCursor, setHideCursor] = useState(false);
    let [currentPage, setCurrentPage] = useState(0);
    let [inputPageName, setInputPageName] = useState('');
    let [isOpenPageModal, setIsOpenPageModal] = useState(false);
    let [pageModalIsCreate, setPageModalIsCreate] = useState(true);
    let [editablePageIndex, setEditablePageIndex] = useState(0);
    let [isDraggabbing, setIsDraggabbing] = useState(false);
    let [filtersValues, setFiltersValues] = useState([]);
    
    let elements = pages[currentPage]?.elements ?? [];
    const [originWidth1, originHeight] = useWindowSize();
    let originWidth = width ?? originWidth1;
    let calculatedWidth, calculatedHeight;
    useEffect(() => {

        const interval = setInterval(
            () => {
                //debugger
                console.log('isFullscreen:' + isFullscreen);
                console.log('lastMoveMouseDateTime:' + lastMoveMouseDateTime);
                console.log('hideCursor:' + hideCursor);
                setCurrentDateTime(JSON.stringify(new Date()));
            },
            3000
        );

        return () => {
            clearInterval(interval);
        }
    }, []);
    
    if(isFullscreen) {
        calculatedWidth = originWidth1;
        calculatedHeight = calculatedWidth*9/16;
        //debugger
        if(lastMoveMouseDateTime !== null && !hideCursor){
            let string = (new Date(JSON.parse(currentDateTime)) - new Date(JSON.parse(lastMoveMouseDateTime))).toString();
            let number = parseInt(string);
            if(number > 3000){
                hideCursor = true;
            }
        }
    } else {
        let offset = 82;
        if(originWidth/(originHeight - offset) !== 16/9){
            if(originWidth/(originHeight - offset) > 16/9) {
                calculatedWidth = (originHeight - offset)*16/9;
                calculatedHeight = originHeight  - offset;
            }else{
                calculatedWidth = originWidth;
                calculatedHeight = originWidth*9/16;
            }
        }else{
            calculatedWidth = originWidth;
            calculatedHeight = originHeight  - offset;
        }        
    }


    let onePercentInPixelForWidth = calculatedWidth / 100; 
    let onePercentInPixelForHeight = (calculatedHeight) / 100; 
    let currentLangValue = getCurrentLangValue();
    return (<><div onMouseMove={(e)=>{ if(isFullscreen) {
        if(e.clientY > (calculatedHeight - 80) && isHidePageSelector){
            setIsHidePageSelector(false);
            return;
        }
        if(e.clientY < (calculatedHeight - 80) && !isHidePageSelector){
            setIsHidePageSelector(true);
            return;
        }
        setLastMoveMouseDateTime(JSON.stringify(new Date()));
        if(e.clientX > (calculatedWidth - 80) && !viewRightPageSelectorButton){
            setViewRightPageSelectorButton(true);
            return;
        }
        if(e.clientX < (calculatedWidth - 80) && viewRightPageSelectorButton){
            setViewRightPageSelectorButton(false);
            return;
        }
        if(e.clientX < 80 && !viewLeftPageSelectorButton){
            setViewLeftPageSelectorButton(true);
            return;
        }
        if(e.clientX > 80 && viewLeftPageSelectorButton){
            setViewLeftPageSelectorButton(false);
            return;
        }
        
        if(hideCursor){
            //debugger;
            //setHideCursor(false);
        }
    }}} id={'current-report-element'} className={'report-container' + (isEdit && isViewElementBorder ? ' editable' : '') + (isFullscreen ? ' full-screen' : '') + (hideCursor ? ' hide-cursor' : '')} onClick={()=>{if(isEdit) onPageFocus(currentPage);}}>
        <div className={'report-elements-container'} style={{width: calculatedWidth + 'px', height: (calculatedHeight - 1) + 'px'}}>{elements.map((element, elementIndex) => {
            let width = (element.w * onePercentInPixelForWidth);
            let height = (element.h * onePercentInPixelForHeight);
            
            if(isEdit && pageIndexForFocusedElement === currentPage && elementIndex === elementIndexForFocusedElement)
                return <Draggable
                    key={'draggable-' + elementIndex}
                    bounds="parent"
                    defaultPosition={{x: element.x * onePercentInPixelForWidth, y: element.y * onePercentInPixelForHeight}}
                    onStart={()=>{
                        setIsDraggabbing(true);
                    }}
                    onStop={(e, data)=>{
                        //debugger;
                        console.log('x: ' + data.x + ', y: ' + data.y);
                        onSetElementPosition(currentPage, elementIndex, data.x/onePercentInPixelForWidth, data.y/onePercentInPixelForHeight, element.w, element.h);
                        setIsDraggabbing(false);
                    }}
                    handle={'.draggable-element i'}>
                    <Resizable 
                        height={height} 
                        width={width} 
                        onResize={(event, {elementInner, size, handle})=>{
                            let newWidth = size.width/onePercentInPixelForWidth;
                            let newHeight = size.height/onePercentInPixelForHeight;
                            if(element.x + newWidth > 100)
                                newWidth = 100 - element.x; 
                            if(element.y + newHeight > 100)
                                newHeight = 100 - element.y; 
                            onSetElementPosition(currentPage, elementIndex, element.x, element.y, newWidth, newHeight);
                        }}>
                        <div
                            onClick={(e)=>{e.stopPropagation()}}
                            className={'report-element'} style={{
                            width: width + 'px',
                            height: height + 'px',
                            //overflow: element.type === 'Filter' ? 'visible' : 'hidden'
                        }}>

                            {getComponent(true, dataColors, enumColors, getData, currentPage, elementIndex, element, width, height, onePercentInPixelForWidth, onePercentInPixelForHeight, isEdit ? element.isConfigured : true, filtersValues, setFiltersValues)}
                            <div className="draggable-element"><Icon className={isDraggabbing ? 'grabbing' : ''} name={'arrows alternate'}/></div>
                            <div className="menu-element"><Dropdown floating direction={"left"}>
                                <Dropdown.Menu>
                                    {(elements.length > 1 && elementIndex !== (elements.length - 1)) ? <Dropdown.Item icon='chevron up' text='Вперёд' onClick={()=>{onMoveElementToFront(currentPage, elementIndex);}} /> : ''}
                                    {(elements.length > 1 && elementIndex !== 0) ? <Dropdown.Item icon='chevron down' text='Назад' onClick={()=>{onMoveElementToBack(currentPage, elementIndex);}} /> : ''}
                                    {(elements.length > 1) ? <Dropdown.Divider /> : ''}
                                    {pages.length === 1 ? <Dropdown.Item icon='clone' text='Дублировать' onClick={()=>{onCloneElement(currentPage, elementIndex);}} /> : ''}
                                    {pages.length > 1 ? <Dropdown item icon='' text={<><Icon name={'clone'}/>Дублировать</>} direction={'right'}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item text='На эту страницу' onClick={()=>{onCloneElement(currentPage, elementIndex);}}  />
                                            <Dropdown.Divider />
                                            {pages.map((x,index)=>{return {page: x, oldIndex: index};}).filter((page, index) => index !== currentPage).map((pageAndIndex, index)=> <Dropdown.Item 
                                                text={pageAndIndex.page.ruName}
                                                onClick={()=>{
                                                    onCloneElement(currentPage, elementIndex, pageAndIndex.oldIndex);
                                                    setCurrentPage(pageAndIndex.oldIndex);
                                                }}
                                            />)}
                                        </Dropdown.Menu>
                                    </Dropdown> : ''}
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={()=>{onRemoveElement(currentPage, elementIndex);}}  icon='trash' text='Удалить' />
                                </Dropdown.Menu>
                            </Dropdown></div>
                        </div>

                    </Resizable></Draggable>;  
                
            return <div className={'report-element'} onClick={(e)=>{e.stopPropagation(); if(isEdit) {onElementFocus(currentPage, elementIndex);}}} style={{
                top: (element.y * onePercentInPixelForHeight) + 'px', 
                left: (element.x * onePercentInPixelForWidth) + 'px', 
                width: width + 'px',
                height: height + 'px',
                //overflow: element.type === 'Filter' ? 'visible' : 'hidden'
            }}>
                {getComponent(isEdit, dataColors, enumColors, getData, currentPage, elementIndex, element, width, height, onePercentInPixelForWidth, onePercentInPixelForHeight, isEdit ? element.isConfigured : true, filtersValues, setFiltersValues)}</div>;
        })}</div>
        {pages.length > 0 && isFullscreen && currentPage !== 0 && viewLeftPageSelectorButton ? <div onClick={()=>{setCurrentPage(currentPage - 1);}} style={{paddingTop: (calculatedHeight/2 - 20) + 'px'}} className={'page-controller-left'}><Icon size={'huge'} style={{cursor: 'pointer'}} name={'angle left'}/></div> : ''}
        {pages.length > 0 && isFullscreen  && currentPage !== (pages.length - 1) && viewRightPageSelectorButton ? <div onClick={()=>{setCurrentPage(currentPage + 1);}} style={{paddingTop: (calculatedHeight/2 - 20) + 'px'}} className={'page-controller-right'}><Icon size={'huge'} style={{cursor: 'pointer'}} name={'angle right'}/></div> : ''}
        <div className={'report-pages-container' + (isFullscreen && isHidePageSelector ? ' hide' : '')}>
            <Menu>
                {pages.map((x, index)=><Menu.Item
                    active={currentPage === index}
                    onClick={(e)=>{
                        if(isEdit)
                            onPageFocus(index);
                        setCurrentPage(index);
                        e.stopPropagation();
                    }}
                    onDoubleClick={()=>{
                        if(isEdit){
                            setIsOpenPageModal(true);
                            setPageModalIsCreate(false)
                            setEditablePageIndex(index);
                            setInputPageName(x.ruName);
                        }
                    }}>
                    {(isEdit ? x.ruName : (currentLangValue === 'ru' ? x.ruName : x.enName))}
                </Menu.Item>)}
                {isEdit ? <div className={'add-page-button-container'}>
                    <Button onClick={()=>{
                        setIsOpenPageModal(true);
                        setPageModalIsCreate(true);
                    }}>Создать</Button>
                </div>: ''}
                {!isEdit ? <div className={'full-screen-button'}>
                    <Icon size={'large'} name={isFullscreen ? 'compress' : 'expand'} onClick={()=>{
                        if(isFullscreen) {
                            document.exitFullscreen();
                        }else{
                            document.getElementById("current-report-element").webkitRequestFullScreen();
                        }
                        let needAddEvent = !isFullscreen;
                        setIsFullscreen(!isFullscreen);
                        if(needAddEvent) {
                            let listener = (e)=>{
                                //alert('dsfg');
                                setIsFullscreen(false);
                                ["", "webkit", "moz", "ms"].forEach(
                                    prefix => document.removeEventListener(prefix+"fullscreenchange", listener, false)
                                );
                            };

                            setTimeout(()=>{
                                ["", "webkit", "moz", "ms"].forEach(
                                    prefix => document.addEventListener(prefix+"fullscreenchange", listener, false)
                                );
                            }, 500);
                        }

                    }} />
                </div>: ''}
            </Menu>
        </div>
        
    </div>
        <Modal
            className={'create-report-page-modal'}
            size={"mini"}
            centered={false}
            closeIcon
            onClose={() => {setIsOpenPageModal(false);setInputPageName('');}}
            onOpen={() => {setIsOpenPageModal(true)}}
            open={isOpenPageModal}>
            <Modal.Header>{pageModalIsCreate ? 'Новая страница отчёта' : 'Редактировать страницу отчёта'}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <RecognitionInput value={inputPageName} onChange={(e)=>{
                            setInputPageName(e.target.value);
                        }}/>
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                {(!pageModalIsCreate && pages.length > 1) ? <Button.Group style={{position: 'absolute', left: '20px'}}>
                    {editablePageIndex !== 0 ? <Button onClick={()=> {onMovePageLeft(editablePageIndex); setCurrentPage(editablePageIndex - 1);setEditablePageIndex(editablePageIndex - 1);}} icon={'angle left'} /> : ''}
                    {editablePageIndex !== (pages.length - 1) ? <Button onClick={()=> {onMovePageRight(editablePageIndex);setCurrentPage(editablePageIndex + 1);setEditablePageIndex(editablePageIndex + 1);}} icon={'angle right'} /> : ''}
                </Button.Group> : ''}
                
                {(!pageModalIsCreate && pages.length > 1) ? <Button onClick={()=> {
                    setCurrentPage(editablePageIndex === 0 ? editablePageIndex + 1 : editablePageIndex - 1);
                    onRemovePage(editablePageIndex);
                    setIsOpenPageModal(false);
                    setInputPageName('');
                }} color={'red'}>Удалить</Button> : ''}
                <Button onClick={()=>{
                    if(pageModalIsCreate){
                        onAddPage(inputPageName);
                        setCurrentPage(pages.length);
                    }
                    else
                        onChangePage(currentPage, inputPageName);
                    setIsOpenPageModal(false);
                    setInputPageName('');}}>
                    {pageModalIsCreate ? 'Создать' : 'Сохранить'}</Button>
            </Modal.Actions>
        </Modal>
    </>);
}

export default ReportViewer