import React from 'react';
import './EnumModalDescription.scss'
import {
    Button,
    Icon,
    Modal, Table, TableBody, TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow
} from "semantic-ui-react";
import {toast} from "react-toastify";
import {getCurrentLangValue} from "../../../../utils/translate";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CodeExample from "../CodeExample/CodeExample";

const EnumModalDescription = ({currentEnum, setCurrentEnum}) => {
    return currentEnum ? <Modal
            open={currentEnum != null}
            closeIcon
            onClose={() => {setCurrentEnum(null)}}
            centered={false}
        >
            <Modal.Header>Детали перечисления "{currentEnum.ruNameSingle}"</Modal.Header>
            <Modal.Content>
                <Table celled>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Значение</TableHeaderCell>
                            <TableHeaderCell>Код</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {currentEnum.values.map(x=><TableRow>
                            <TableCell>{x.ruNameSingle}</TableCell>
                            <TableCell style={{width: '65%'}}>
                                <CodeExample 
                                    hideLines
                                    code={`app.enum('${currentEnum.ruNameSingle}', '${x.ruNameSingle}')`}
                                />
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button basic onClick={() => {setCurrentEnum(null)}}>Закрыть</Button>
            </Modal.Actions>
        </Modal> : ''
}

export default EnumModalDescription