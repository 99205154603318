import React, {useEffect, useState} from 'react';
import './FolderViewerForFolderSelector.scss'
import {
    Icon
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import api from "../../../../../../../../../../utils/api";

export default function FolderViewerForFolderSelector({directory, onSelect}) {
    let [openedIndex, setOpenedIndex] = useState([]);
    let [content, setContent] = useState([]);
    const history = useHistory();

    const load = (url)=>{
        api()
            .getKnowledgeBaseMenuItems(directory)
            .then(resp => {
                setContent(resp.filter(x=>x.isFolder))
                if(url !== undefined && url != null)
                    history.push(`/knowledgeBase/${url}`)
            })
    }
    useEffect(load, [])
    
    return <>
        {content.length == 0 && directory == '/' ? '' : 
            <div className={'folder-viewer-container-for-select'}>
                {content.length == 0 ? <div>
                    <p>В этой папке ничего нет</p>
                </div> : ''}
                {content.map((contentItem, index)=> <><div
                    className={'item' + (!contentItem.isFolder ? ' current' : '')}
                    onClick={()=>{
                        if(contentItem.isFolder)
                            setOpenedIndex(oldOpened => {
                                if(openedIndex.includes(index)) return openedIndex.filter(x=>x !== index);
                                return [...openedIndex, index];
                            })
                    }}
                >{contentItem.isFolder ? <Icon name={'angle ' + (openedIndex.includes(index) ? 'down' : 'right')}/> : ''} {contentItem.name}
                    <a className={'select'} onClick={e => {e.stopPropagation(); e.preventDefault(); onSelect({url: contentItem.url, title: contentItem.name})}}>Выбрать</a>
                </div>
                {contentItem.isFolder && openedIndex.includes(index) ? <div className={'inner-folders'}>
                    <FolderViewerForFolderSelector 
                        directory={contentItem.url} 
                        onSelect={onSelect}
                    />
                </div> : ''}
            </>)}
        </div>}
    </>;
}