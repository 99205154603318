import React, {useEffect, useState} from 'react';
import './letterPage.css'
import {
    Button,
    Loader
} from "semantic-ui-react";
import api from "../../../../../../utils/api";
import moment from "moment/moment";
import NewLetterModal from "../newLetterModal/newLetterModal";

export default function LetterPage({id, currentAccount, setId}) {
    const [from, setFrom] = useState('')
    const [when, setWhen] = useState('')
    const [title, setTitle] = useState('')
    const [body, setBody] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [answerLetterModalOpen, setAnswerLetterModalOpen] = useState(false)
    useEffect(()=>{
        setIsLoading(true)
        api()
            .getLetter(id)
            .then(letter =>{
                setFrom(letter.from)
                setWhen(moment(letter.when).format('DD.MM.yyyy hh:mm'))
                setTitle(letter.title)
                setBody(letter.body)
                setIsLoading(false)
            })
    }, [])
    
    const getBodyForAnswer = (value) => {
        return '\r\n' + value.split('\r\n').map(x=> `> ${x}`).join('\r\n')
    }
    if(isLoading)
        return <Loader active/>
    return (<div>
        <h3>{from} <span style={{fontSize: 'small', color: 'gray'}}>{when}</span></h3>
        <h2>{title}</h2>
        <div style={{whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{ __html: body }}></div>
        <Button
            onClick={()=>{setAnswerLetterModalOpen(true)}}
            icon={'pencil'}
            labelPosition='left'
            content="Ответить"/>
        <NewLetterModal 
            from={currentAccount} 
            open={answerLetterModalOpen} 
            setOpen={setAnswerLetterModalOpen} 
            onSend={()=>{setId(null)}}
            prefilledTo={from}
            prefilledTitle={`Re: ${title}`}
            prefilledBody={getBodyForAnswer(body)}
        />
    </div>);
}