import React, {useEffect, useState} from 'react';
import {Loader} from "semantic-ui-react";
import Chart from "react-apexcharts";

let getTitle = (element)=>{
    if(element.viewTitle && (element.ruTitle === undefined || element.ruTitle === null || element.ruTitle === '')){
        
        let fieldForValue = JSON.parse(element.dictionaryFieldForValue);
        let dictionaryNameForValue = fieldForValue.value.split('"."')[0].substr(1);
        let fieldNameForValue = fieldForValue.value.split('"."')[1];

        fieldNameForValue = fieldNameForValue.substr(0, fieldNameForValue.length - 1);
        
        let fieldForLabel = JSON.parse(element.dictionaryFieldForLabel);
        let dictionaryNameForLabel = fieldForLabel.value.split('"."')[0].substr(1);
        let fieldNameForLabel = fieldForLabel.value.split('"."')[1];
        
        fieldNameForLabel = fieldNameForLabel.substr(0, fieldNameForLabel.length - 1);
        
        if(dictionaryNameForLabel === dictionaryNameForValue)
            return `${fieldNameForValue} по ${fieldNameForLabel} (${dictionaryNameForValue})`;
        return `${fieldNameForValue} из ${dictionaryNameForValue} по ${fieldNameForLabel} из ${dictionaryNameForLabel}`;
    }

    return element.ruTitle;
}

const Histogram = ({
                      getData, 
                      pageIndex, 
                      elementIndex, 
                      element, 
                      width, 
                      height, 
                      onePercentInPixelForWidth, 
                      onePercentInPixelForHeight,
                      filtersValues
}) => {
    let [isLoading, setIsLoading] = useState(true);
    let [labels, setLabels] = useState([]);
    let [series, setSeries] = useState([]);

    useEffect(() => {
        getData(pageIndex, elementIndex, filtersValues)
            .then(values => {
                setLabels(values.map(x=>x[0]));
                setSeries(values.map(x=>parseFloat(x[1]) ));
                setIsLoading(false);
            });
        setIsLoading(false);
    }, []);
    
    return (<div style={{marginTop: '20px'}}>
        {!isLoading ? <div style={{textAlign: 'left', fontSize: (onePercentInPixelForWidth*1.5) + 'px', padding: (onePercentInPixelForWidth) + 'px'}}>{getTitle(element)}</div> : ''}
        {isLoading ? <Loader active inline size='massive'></Loader> : <Chart
            options={{
                chart: {
                    height: 350,
                    type: 'bar',
                    toolbar: {
                        show: false
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val + "%";
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '12px',
                        colors: ["#304758"]
                    }
                },

                xaxis: {
                    categories: labels,
                    position: 'top',
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#D8E3F0',
                                colorTo: '#BED1E6',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            }
                        }
                    },
                    tooltip: {
                        enabled: false,
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return val + "%";
                        }
                    }

                }
            }}
            series={[{
                name: 'Inflation',
                data: series
            }]}
            type="bar"
            width={width}
        />}
    </div>);
}

export default Histogram