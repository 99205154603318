import {
    Form,
    Icon,
    Message,
    Popup,
    Tab
} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import './FieldsPartViewerForReport.css'
import {defineIfOneInnerTableInColumn, getMenuItemName} from "../../../../../utils/TabHelper";
import {getElementsFromFormattedText} from "../../../../../utils/textHelper";
import t from "../../../../../utils/translate";
import {calculateExpression} from "../../../../../utils/expressionHelper";
import resolveColumnToElement from "../../../../../utils/resolveColumnToElement";
import reportColumnTypes from "../../../../../utils/columnTypes";
import {FieldInputForReport} from "../FieldInputForReport/FieldInputForReport";
import appConfig from "../../../../../utils/appConfig";

export function FieldsPartViewerForReport({
                                              formData, 
                                              setFormData, 
                                              currentPageIndex, 
                                              currentElementIndex, 
                                              currentElementType, 
                                              onStartSelectField, 
                                              columnNameForFocused, 
                                              selectedField, 
                                              onStopSelectedField,
                                              setHoveredFieldValue
}) {
    let reportTypes = reportColumnTypes();
    
    let getNotHiddenColumns = () => {
        let dictionary = appConfig().dictionaries.filter(x=>x.nameSingle === 'ReportElement')[0];
        return dictionary
            .columns
            .filter(column => !column.hideInForm)
            .filter(column => {
                if(column.hideIf !== undefined && column.hideIf !== null && column.hideIf !== '') {
                    var hideIf = column.hideIf;

                    for (var i = 0;i < appConfig().enums.length; i++){
                        for (var j = 0;j < appConfig().enums[i].values.length; j++){
                            hideIf = hideIf.replaceAll(`"${appConfig().enums[i].nameSingle}"."${appConfig().enums[i].values[j].nameSingle}"`, `"${appConfig().enums[i].values[j].nameSingle}"`);
                        }
                    }

                    dictionary.columns.map(col =>
                        {
                            let element = formData.filter(x=>x.name=== 'Pages')[0].value[currentPageIndex].Elements[currentElementIndex];
                            if(element !== undefined) {
                                var fdi = element[col.name];
                                hideIf = hideIf.replaceAll(`"${col.name}"`, `"${fdi}"` ?? 'null');
                            }
                        }
                    );

                    //hideIf = hideIf.replaceAll('"Id"', data !== undefined && data.Id !== undefined ? ("'" + data.Id + "'") : 'null');

                    /*for (var i = 0;i < formData.length; i++){
                        hideIf = hideIf.replaceAll(formData[i].name, formData[i].value);
                    }*/

                    let result = false;

                    console.log(hideIf);
                    try {
                        result = eval(hideIf);
                    }catch (e) {
                        console.log('hideIf ERROR');
                        console.log(e);
                    }
                    return result !== true;
                }
                return true;
            });
    };
    let setIsConfigured = (element) => {
        let dataColumns = getNotHiddenColumns().filter(col => col.tabName === "Data");
        let isConfigured = true;
        dataColumns.map(dataColumn => {
            if(element[dataColumn.name] === undefined || element[dataColumn.name] === null)
                isConfigured = false;
        });
        if(dataColumns.length === 0)
            isConfigured = false;

        element['IsConfigured'] = isConfigured;
    };
    
    let  loadEnumItems = () => {
        enumItemsForSelects = enumItemsForSelects.map(x=>x);
        let filter = columns.filter(c => c.type === reportTypes.linkToEnum && enumItemsForSelects.filter(e => e.enumName === c.linkTo).length === 0);
        if (filter.length > 0) {
            filter.map(colForAdd => {
                let enums = appConfig().enums;
                let options = enums
                    .filter(x => x.nameSingle === colForAdd.linkTo)[0].values
                    .map(x => {
                        let newVar = {key: x.nameSingle, text: t(x.nameSingle), value: x.nameSingle};
                        if (x.color !== null)
                            newVar.label = {color: x.color, empty: true, circular: true};
                        if (x.imageUrlForLabel !== null)
                            newVar.image = {avatar: false, src: x.imageUrlForLabel};
                        return newVar;
                    });
                enumItemsForSelects.push({enumName: colForAdd.linkTo, options: options});
            })
            setEnumItemsForSelects(enumItemsForSelects);
        }
    }
    useEffect(()=>{
        loadEnumItems();
    }, [])
    
    useEffect(()=>{
        loadEnumItems();
        if(columnNameForFocused !== null && selectedField !== null){
            //debugger;
            let fd = formData.map(x=>x);
            let element = fd.filter(x=>x.name=== 'Pages')[0].value[currentPageIndex].Elements[currentElementIndex];
            //debugger
            element[columnNameForFocused] = JSON.stringify({
                value: `"${selectedField.dictionary.RuManyName}"."${selectedField.field.DictionaryFieldName}"`, 
                fieldType: selectedField.field.DictionaryFieldType,
                dictionaryId: selectedField.dictionary.Id,
                dictionaryName: (selectedField.dictionary.type === 'dictionary'? selectedField.dictionary.RuManyName : selectedField.dictionary.OriginalDictionaryName),
                sourceType: selectedField.dictionary.type,
                func: selectedField.field.DictionaryFieldType === 'Number' ? 'SUM' : 'COUNT',
                distinct: false,
                prefix: selectedField.field.Prefix,
                enPrefix: selectedField.field.EnPrefix,
                postfix: selectedField.field.Postfix,
                enPostfix: selectedField.field.EnPostfix
            });
            
            setIsConfigured(element);
            
            setFormData(fd);
            
            onStopSelectedField();
        }
    }, [selectedField]);
    
    useEffect(()=>{
        //debugger;
        loadEnumItems();
        let fd = formData.map(x=>x);
        let element = fd.filter(x=>x.name=== 'Pages')[0].value[currentPageIndex].Elements[currentElementIndex];
        if(element['ElementType'] !== currentElementType){
            element['ElementType'] = currentElementType;
            setFormData(fd);
        }
        
    }, [currentElementType])
    let [enumItemsForSelects, setEnumItemsForSelects] = useState([]);
    //loadEnumItems();


    //let formData = [];
    let dictionary = appConfig().dictionaries.filter(x=>x.nameSingle === 'ReportElement')[0];
    let columns = getNotHiddenColumns();

    let createInputFromColumn = (column, viewLabel = true, rowIsAllBool) => {
        let name = column.name;
        let item = formData.filter(x=>x.name=== 'Pages')[0].value[currentPageIndex].Elements[currentElementIndex];
        if(item === undefined)
            return '';
        let value = item[name] ?? '';

        let inputElement = null;
        let readOnly = false;

        if(column.readOnlyIf !== undefined && column.readOnlyIf !== null && column.readOnlyIf !== '') {
            readOnly = calculateExpression(column.readOnlyIf, dictionary, formData, data)
        }

        let onChangeFieldValue = (name, value)=>{
            let fd = formData.map(x=>x);
            let element = fd.filter(x=>x.name=== 'Pages')[0].value[currentPageIndex].Elements[currentElementIndex];
            element[name] = value;
            setIsConfigured(element);
            setFormData(fd);
        };
        let data = {};
        let dictionaryItemsForSelects = [];
        let parentData = {};
        //let setFormData = ()=>{};
        let columnWithFunctor = true;
        switch(column.type) {
            case reportTypes.fieldSelectorForReportWithoutFunctor:
                columnWithFunctor = false;
            case reportTypes.fieldSelectorForReport:
            case reportTypes.fieldSelectorForReportDateTimeOnly:
                inputElement = <FieldInputForReport
                    withFunctor={columnWithFunctor}
                    value={value}
                    state={(columnNameForFocused === column.name ? 'focused': 'new')}
                    onStartFocus={()=>{
                        onStartSelectField(column.name);
                    }}
                    onCancel={()=>{
                        onStopSelectedField()
                    }}
                    onSetFunc={(type, distinct)=>{
                        let newValue = JSON.parse(value);
                        newValue.func = type.name;
                        newValue.distinct = distinct;
                        onChangeFieldValue(name, JSON.stringify(newValue));
                    }}
                    onRemoveData={()=>{
                        onChangeFieldValue(name, null);
                    }}
                    onHover={(fieldValue)=>{
                        setHoveredFieldValue(fieldValue);
                    }}
                    onLeave={()=>{
                        setHoveredFieldValue(null);
                    }}
                />;
                break;
            default:
                [inputElement, viewLabel] = resolveColumnToElement(column,
                    readOnly,
                    value,
                    reportTypes,
                    onChangeFieldValue,
                    formData,
                    viewLabel,
                    dictionaryItemsForSelects,
                    enumItemsForSelects,
                    data,
                    parentData,
                    dictionary.nameSingle + '.' + name,
                    //dictionary.nameSingle + '.' + name);
                    dictionary.nameSingle + '.' + column.description,
                    setFormData);
                break;
        }

        return <Form.Field
            readOnly={readOnly}
            key={'form-field-' + name}
            width={column.size}
            className={(!rowIsAllBool && column.type === reportTypes.bool) ? 'padding-top-30' : ''}
        >
            {viewLabel ? (column.description !== '' ?    <><label>{t(dictionary.nameSingle + '.' + name)}</label><Popup
                position={"top center"}
                trigger={<Icon name={"info circle"}/>}
                on='click'
                content={getElementsFromFormattedText(t(dictionary.nameSingle + '.' + column.description))}

            /></> : <label>{t(dictionary.nameSingle + '.' + name)}</label>) : ''}
            {inputElement}
        </Form.Field>;
    };

    return <>
        <Tab menu={{ secondary: true, pointing: true }} panes={dictionary.tabs
            .filter(tab => columns.filter(col => col.tabName === tab.nameSingle).length > 0)
            .map(tab => {
                return {
                    menuItem: getMenuItemName(tab, columns, []),
                    render: () => {
                        let columnsInTab = columns.filter(col => col.tabName === tab.nameSingle);

                        return <Tab.Pane>
                            {tab.description !== '' ?  <Message info>
                                <p>{getElementsFromFormattedText(t(tab.description))}</p>
                            </Message> : <></>}
                            {columnsInTab.map((col, i) => <Form.Group key={"group-for-in-tabs-row-" + i}>{createInputFromColumn(col,
                                defineIfOneInnerTableInColumn(tab, columns), col.type === 'bool')}</Form.Group>)}
                        </Tab.Pane>;
                    }
                };
            })}/>
    </>;
}