import React from 'react';
import './DownloadPdfBtn.css'
import {
    Button, Icon, Popup
} from "semantic-ui-react";
import generatePDF, {Margin} from "react-to-pdf";

export default function DownloadPdfBtn({targetRef, name}) {
    return <Popup
        content={'Скачать pdf'}
        basic
        inverted
        trigger={<Button
            className={'download-pdf-btn'}
            icon
            onClick={() => generatePDF(targetRef, {
                filename: `${name}.pdf`,
                page: {
                    margin: Margin.MEDIUM
                }
            })}><Icon name={'download'}/></Button>}/>;
}