import React, {useRef} from 'react';
import api from "../../../../../../../../utils/api";
import {Icon} from "semantic-ui-react";
import getBackedUrl from "../../../../../../../../utils/getBackedUrl";

export default function UploadDocumentPlugin(props) {
    const fileInputRef = useRef()
    const handleClick = () => {
        fileInputRef.current.click()
    }
    
    return <span
        onClick={handleClick}
        className="button button-type-image"
        title="Document"
        style={{position: 'relative'}}>
            <Icon name={'attach'} style={{margin: '0'}}></Icon>
            <input
                ref={fileInputRef}
                onChange={(e) => { return api().uploadFile(e.target.files[0]).then((resp)=> {
                    props.editor.insertText(`[${resp.name}](${getBackedUrl()}/api/knowledgeBase/download/file/${resp.id}?name=${encodeURIComponent(resp.name)})`);
                })}}
                type="file"
                style={{position: 'absolute', zIndex: -1, left: '0', top: '0', width: '0', height: '0', opacity: '0'}}/>
    </span>;
}