import {Icon, Popup, Table} from "semantic-ui-react";
import React from "react";
import './DictionaryHeaderCell.css'
import t, {getCurrentLangValue} from "../../../utils/translate";
import {getId} from "../../../utils/authHelper";
import reportColumnTypes from "../../../utils/columnTypes";
import {FilterForm} from "./components/FilterForm";

export function DictionaryHeaderCell({column, 
                                         dictionary,
                                         view, 
                                         globalFilterColumn,
                                         currentSort,
                                         setCurrentSort,
                                         openedFilter,
                                         setOpenedFilter,
                                         currentFilter,
                                         setCurrentFilter
                                     }) {
    let allColumns = dictionary.columns
        .filter(column => column !== globalFilterColumn)
        .filter(column => !column.hideInList);
    let isLast = allColumns.length > 0 ? allColumns[allColumns.length - 1].name === column.name : false;
    let userId = getId();
    let key = userId + '.' + dictionary.nameSingle + '.' + view.viewName;

    let reportTypes = reportColumnTypes();
    let typesForSort = [reportTypes.bool, reportTypes.linkToDictionary, reportTypes.linkToEnum, reportTypes.dateTime, reportTypes.date, reportTypes.int, reportTypes.decimal];
    let isIdOrCreateAt = column.name === 'Id' || column.name === 'CreateAt';
    
    let isTypeForSort = typesForSort.includes(column.type) || isIdOrCreateAt;
    let setCurrentSortToLocalstorage = (key, value) => {
        if(currentSort === (key + '.' + column.name + '-' + value)){
            setCurrentSort('');
            localStorage.removeItem(key);
        }
        else{
            setCurrentSort(key + '.' + column.name + '-' + value);
            localStorage.setItem(key, column.name + '-' + value);
        }
    };

    let cellHeaderText = t(isIdOrCreateAt ? column.name : dictionary.nameSingle + '.' + column.name);
    if(column.ruShorNameForList !== null && 
        column.ruShorNameForList !== undefined && 
        column.ruShorNameForList !== '' && 
        getCurrentLangValue() === 'ru')
        cellHeaderText = column.ruShorNameForList;
    
    if(column.enShorNameForList !== null && 
        column.enShorNameForList !== undefined && 
        column.enShorNameForList !== '' && 
        getCurrentLangValue() === 'en')
        cellHeaderText = column.enShorNameForList;

    function ifHaveFilter(column, currentFilter) {
        if(currentFilter.filter(x=>x.name === column.name).length > 0){
            let filterElement = currentFilter.filter(x=>x.name === column.name)[0];
            if((filterElement.value != null && filterElement.value != '') ||
                (filterElement.canEmpty != null && filterElement.canEmpty))
                return true;
        }
        
        return false;
    }

    return <Table.HeaderCell
        key={dictionary.name + 'header' + column.name}>

        {view.canFilter && view.extraFilters.filter(x=>x === column.name).length === 0 ? <Popup open={openedFilter === column.name} flowing basic 
                                 onClose={(e, data)=>{
                                     if(e.type === 'mouseleave')
                                         return;
                                     setOpenedFilter(null);
                                 }}
                                 content={<FilterForm
            column={column}
            dictionary={dictionary}
            view={view}
            currentFilter={currentFilter}
            openedFilter={openedFilter === column.name}
            setCurrentFilter={(f)=>{
                setCurrentFilter(f);
                setOpenedFilter(null);}}
        />} trigger={<span style={{cursor: 'pointer', textDecoration: openedFilter === column.name ? 'underline' : 'none', position: 'relative', display: 'block'}} onClick={()=>{
            if(openedFilter === column.name)
                setOpenedFilter(null);
            else
                setOpenedFilter(column.name);
        }}>{cellHeaderText} {ifHaveFilter(column, currentFilter) ?
            <span style={{position: 'absolute', right: '0', top: '0'}}><Icon name={'circle'} color={'blue'} size={'mini'} style={{verticalAlign: 'top'}} /></span> : ''}</span>
        } /> : <span>{cellHeaderText}</span>}
        {view.canSort && isTypeForSort ? <div className={'report-dictionary-sort-container' + (isLast ? ' last' : '')}>
            <div>
                <Icon 
                    name={'angle up'} 
                    className={'report-dictionary-sort-container-icon' + (currentSort === ( key + '.' + column.name + '-asc')? ' active' : '')}
                    onClick={()=>{setCurrentSortToLocalstorage(key, 'asc');}}
                />
            </div>
            <div>
                <Icon 
                    name={'angle down'} 
                    className={'report-dictionary-sort-container-icon' + (currentSort === ( key + '.' + column.name + '-desc')? ' active' : '')}
                    onClick={()=>{setCurrentSortToLocalstorage(key, 'desc');}}
                />
            </div></div> : ''}
    </Table.HeaderCell>;
}