import React, {useState} from 'react';
import md5 from 'md5';
import './Introduction.css'
import {Button, Container, Grid, Icon} from "semantic-ui-react";
import {getElementsFromFormattedText} from "../../utils/textHelper";
import t, {getCurrentLangValue} from "../../utils/translate";
import appConfig from "../../utils/appConfig";

export default function Introduction() {
    let pages = appConfig().info.introductionPages;
    let [currentPageIndex, setCurrentPageIndex] = useState(0);
    
    if(pages === [])
        return <Container/>;
    
    let page = pages[currentPageIndex];
     
    let title = getCurrentLangValue() === 'ru' ? page.titleRu : page.titleEn;
    let text = getCurrentLangValue() === 'ru' ? page.textRu : page.textEn;
    let textElement = <Grid.Column width={8}>
            <h1>{title}</h1>
            <div className={'introduction-text-container'}>{getElementsFromFormattedText(text)}</div>
        </Grid.Column>
    ;
    
    let img = <Grid.Column width={8} className={'introduction-img-container'}>
        <img src={page.imgUrl}  alt={''}/>
    </Grid.Column>;
    
    let result = <></>;
    if(page.imgUrl !== undefined && page.imgUrl !== ''){
        switch (page.imgPosition) {
            case "left":
                result = <Grid.Row>{img}{textElement}</Grid.Row>
                break;
            case "right":
                result = <Grid.Row>{textElement}{img}</Grid.Row>
                break;
            default:
                result = <Grid.Row>{img}{textElement}</Grid.Row>
                break;
        }
    }else{
        result = <Grid.Row><Grid.Column width={4}> </Grid.Column>{textElement}</Grid.Row>
    }
    return (<div className={'introduction-page-container'}>
        <Grid>
            {result}
        </Grid>
        <div className={'introduction-controls-container'}>
            <div className={'introduction-controls-navigation'}>{pages.map((p,i)=>
                <Icon 
                    size={"big"} 
                    name={'circle' + (i !== currentPageIndex ? ' outline' : '')}
                    onClick={()=>{setCurrentPageIndex(i); window.scrollTo(0, 0)}}
                />)}</div>
            {(currentPageIndex + 1) === pages.length ?
                <Button primary onClick={()=>{
                    localStorage.setItem('Introduction-' + md5(JSON.stringify(pages))  + '_' + getCurrentLangValue() + '_' + window.user.Id, true);

                    let pageType =  appConfig()
                        .pages[0].type;
                    switch (pageType) {
                        case 0:
                        case 1:
                        case 2:
                            let singleName =  appConfig()
                                .dictionaries.filter(x=>x.strictNameMany === appConfig()
                                    .pages[0].name.split('.')[0])[0].nameSingle;
                            window.location = '/' + (singleName + 'View' + appConfig()
                                .pages[0].name.split('.')[1]);
                            break;
                        case 3:
                            window.location = '/' + ("iframe-" + appConfig()
                                .pages[0].name);
                            break;
                        case 4:
                            var report = appConfig().reports.filter(x=>x.strictName === appConfig()
                                .pages[0].name)[0];
                            window.location = '/' + ("report-" + report.strictName);
                            break;
                        case 5:
                            var powerBiReport = appConfig().powerBiReports.filter(x=>x.name === appConfig()
                                .pages[0].name)[0];

                            window.location = '/' + ("powerBi-" + powerBiReport.name);
                            break;
                        case 6:
                            window.location = '/' + ("pdf-" + appConfig()
                                .pages[0].name);
                            break;
                    }
                    
                }}>{t('Introduction_start_btn')}</Button>
                : <Button onClick={()=>{setCurrentPageIndex(currentPageIndex + 1); window.scrollTo(0, 0)}}>{t('Introduction_next_btn')}</Button>}
        </div>
    </div>);
}