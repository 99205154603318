import {Button, Dropdown, Icon, List, Popup} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import api from "../../../../../utils/api";
import './FieldSelectorForReport.css'
import DictionaryItemEdit from "../../../DictionaryItemEdit";
import t from "../../../../../utils/translate";
import {ActionButton} from "../../../ActionButton";
import appConfig from "../../../../../utils/appConfig";

export function FieldSelectorForReport({isFocused, onSelectField, formData, setFormData, hoveredFieldValue, height}) {
    let [dictionariesWithFieldsAndFilters, setDictionariesWithFieldsAndFilters] = useState([]);
    let [openedDictionaries, setOpenedDictionaries] = useState([]);
    
    useEffect(()=>{
        if(dictionariesWithFieldsAndFilters.length === 0)
            loadDictionary();
        else
            setFiltersInDictionaries(dictionariesWithFieldsAndFilters.filter(x=>x.type === 'dictionary'));
    }, [formData]);

    let dictionaryForDictionary = appConfig().dictionaries.filter(x=>x.nameSingle === 'Dictionary')[0];
    let viewForDictionary = appConfig().views.filter(x=>x.dictionaryStrictName === 'Dictionaries')[0];

    let dictionaryForReportDictionaryFilter = appConfig().dictionaries.filter(x=>x.nameSingle === 'ReportDictionaryFilter')[0];
    let fieldTypes = appConfig().enums.filter(x=>x.nameSingle === 'DictionaryFieldType')[0].values;

    let setFiltersInDictionaries = (dictionaries) => {
        let filters = [];
        if(formData.filter(x=>x.name === "DictionaryFilters").length !== 0){
            filters = formData.filter(x=>x.name === "DictionaryFilters")[0].value;
        }

        filters.map(filter => {
            let filterElement = dictionaries.map((x, index)=> {return {index, dictionary: x};}).filter(x => x.dictionary.Id === filter.DictionaryIdForReportFilter.id)[0];
            dictionaries.splice(filterElement.index + 1, 0, {
                Id: filterElement.dictionary.Id,
                RuManyName: filter.RuName,
                OriginalDictionaryName: filterElement.dictionary.RuManyName,
                type: 'filter',
                filterCondition: filter["Condition"],
                Fields: filterElement.dictionary.Fields
            })})
        setDictionariesWithFieldsAndFilters(dictionaries);
    }
    let loadDictionary = () => {
        api().getListForView("Dictionaries", 0, [{
            name: "ProjectId",
            type: 'eq',
            value: localStorage.getItem('GlobalFilterValue')
        }], 'ForAnalitic').then(resp => {

            let dictionaries = resp.rows.map(x=>{ 
                x.type = 'dictionary';
                return x;
            });
            setFiltersInDictionaries(dictionaries);
        });
    };

    let openDictionary = (dictionary) => {
        api().getById("Dictionaries", dictionary.Id).then(dictionaryExtensive=>{
            let tempdDctionariesWithFieldsAndFilters = dictionariesWithFieldsAndFilters.map(x=>x)
            let targetDictionary = tempdDctionariesWithFieldsAndFilters.filter(x=> x.RuManyName  === dictionary.RuManyName)[0];
            targetDictionary.Fields = dictionaryExtensive.Fields;
            targetDictionary.Fields.splice(0, 0, {
                DictionaryFieldType: 'DateTime',
                DictionaryFieldName: 'Дата и время создания',
            });
            targetDictionary.Fields.splice(0, 0, {
                DictionaryFieldType: 'id',
                DictionaryFieldName: 'Идентификатор',
            });
            setDictionariesWithFieldsAndFilters(tempdDctionariesWithFieldsAndFilters);

            let temp = openedDictionaries.map(x => x);
            if (openedDictionaries.filter(x => x.name === dictionary.RuManyName).length > 0) {
                temp = openedDictionaries.filter(x => x.name !== dictionary.RuManyName);
            } else {
                temp.push({name: dictionary.RuManyName});
            }
            setOpenedDictionaries(temp);
        })
    }
    let dictionary = appConfig().dictionaries.filter(x=>x.nameSingle === "Dictionary")[0];
    let [dictionaryActionOpen, setDictionaryActionOpen] = useState(false);
    return <>
        <div>
            <Button.Group size='mini' style={{width: '100%'}}>
                <DictionaryItemEdit
                    onSaveEvent={()=>{setOpenedDictionaries([]);loadDictionary();}}
                    view={viewForDictionary}
                    dictionaryItemMetaDescription={dictionaryForDictionary}>
                    <Button size='tiny'>Создать<br/> справочник</Button>
                </DictionaryItemEdit>

                <Button size='tiny' onClick={(e)=>{e.stopPropagation(); setDictionaryActionOpen(!dictionaryActionOpen);}}>{dictionary.buttons !== undefined && dictionary.buttons.length > 0 ? <>
                <Dropdown open={dictionaryActionOpen} onClose={()=>{setDictionaryActionOpen(false)}} onOpen={()=>{setDictionaryActionOpen(true);} } icon={'ellipsis horizontal'} direction='left'>
                    <Dropdown.Menu>
                        <Dropdown.Header content={t('available_tasks')} />
                        <Dropdown.Divider />
                        {dictionary.buttons.map(button => {
                            return <ActionButton
                                for={'task'}
                                as={'dropdown'}
                                action={button}
                                afterInvoke={()=>{loadDictionary();}}
                            />
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </> : ''}</Button>
            </Button.Group>
        </div>
        <List className={'fields-container'} style={{height: (height -10) + 'px', overflowY: 'auto', overflowX: 'clip', position: 'relative'}}>
        {dictionariesWithFieldsAndFilters.map(dictionary =>{
            let isHovered = (hoveredFieldValue ?? '').indexOf(`"${dictionary.RuManyName}".`) !== -1;
            let trigger = <span className={isHovered ? 'hovered' : ''}>{dictionary.RuManyName}</span>;
            let filterElement = formData.filter(x=>x.name === "DictionaryFilters")[0]?.value.filter(x=>x.RuName === dictionary.RuManyName)[0];
            let filterElementFormData = [];
            if(filterElement != null) {
                for(let a in filterElement){
                    filterElementFormData.push({name: a, value: filterElement[a]});
                }
            }
            return <List.Item>
                <List.Icon
                    name='dropdown'
                    rotated={openedDictionaries.filter(x=>x.name === dictionary.RuManyName).length > 0 ? '' : 'counterclockwise'}
                    onClick={()=>{openDictionary(dictionary)}}
                />
                <List.Content>
                    <List.Header
                        onClick={()=>{openDictionary(dictionary)}}
                        className={'dictionary-item' + (dictionary.type === 'filter' ? ' isFilter' : '')}>
                        {dictionary.type === 'filter' ? <Popup position='left center' content={dictionary.filterCondition} trigger={trigger} /> : trigger}

                        {dictionary.type === 'dictionary' ? <DictionaryItemEdit
                            overrideSave={(data)=>{
                                let fd = formData.map(x=>x);
                                if(fd.filter(x=>x.name === "DictionaryFilters").length === 0)
                                    fd.push({name: 'DictionaryFilters', value: []});

                                let dictionaryFilters = fd.filter(x=>x.name === "DictionaryFilters")[0].value;
                                dictionaryFilters.push(data);
                                setFormData(fd);
                                //loadDictionary();
                            }}
                            prefillFields={[{name: 'DictionaryIdForReportFilter', value: dictionary.Id}]}
                            dictionaryItemMetaDescription={dictionaryForReportDictionaryFilter}>
                            <Button style={{padding:'2px', marginLeft: '5px'}} size={"mini"} basic><Icon name={'plus'}/><Icon name={'filter'}/></Button>
                        </DictionaryItemEdit>: ''}
                        {dictionary.type === 'filter' ? <DictionaryItemEdit
                            overrideSave={(data)=>{
                                let fd = formData.map(x=>x);
                                if(fd.filter(x=>x.name === "DictionaryFilters").length === 0)
                                    fd.push({name: 'DictionaryFilters', value: []});

                                let dictionaryFilters = fd.filter(x=>x.name === "DictionaryFilters")[0].value;
                                dictionaryFilters.push(data);
                                setFormData(fd);
                            }}
                            initFormData={filterElementFormData}
                            dictionaryItemMetaDescription={dictionaryForReportDictionaryFilter}>
                            <Button style={{padding:'2px', marginLeft: '5px'}} size={"mini"} basic><Icon name={'edit'}/></Button>
                        </DictionaryItemEdit>: ''}
                    </List.Header>
                    {openedDictionaries.filter(x=>x.name === dictionary.RuManyName).length > 0 ? <List.List>
                        {dictionary.Fields.map(field=> {
                            let filterElementType;
                            if(field.DictionaryFieldType === 'id'){
                                filterElementType = {
                                    imageUrlForLabel: 'https://static.tildacdn.com/tild6661-3766-4037-a535-663930366138/Id.svg'
                                };
                            }else{
                                filterElementType = fieldTypes.filter(ft => {
                                    return ft.nameSingle == field.DictionaryFieldType || ft.nameSingle == field.name;
                                })[0];
                                if(filterElementType === undefined)
                                    return '';
                                
                            }
                            return <List.Item>
                                <List.Content>
                                    <List.Header onClick={() => {
                                        if (isFocused)
                                            onSelectField(dictionary, field);
                                    }}><span style={{
                                        backgroundImage: 'url("' + (filterElementType.imageUrlForLabel) + '")'
                                    }} /><div className={((hoveredFieldValue ?? '').indexOf(`."${field.DictionaryFieldName}"`) !== -1 && isHovered) ? 'hovered' : ''}>{field.DictionaryFieldName}</div></List.Header>
                                </List.Content>
                            </List.Item>;
                        })}
                    </List.List> : ''}

                </List.Content>


            </List.Item>;})}
    </List>
        
    </>;
}