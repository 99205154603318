import React, {useState} from 'react';
import './PermissionChanger.scss'
import {
    Icon, Popup
} from "semantic-ui-react";
import PermissionChangerModal from "./components/PermissionChangerModal/PermissionChangerModal";

export default function PermissionChanger({page, onChange, maxIndex}) {
    const [open, setOpen] = useState(false)
    
    const getPadding = (page, maxIndex) => {
        if(maxIndex === 0)
            return ' min-padding'
        if(page.index !== 0 && page.index !== maxIndex)
            return ' max-padding'
        return ' middle-padding'
    }
    
    return <>
        <Popup
            basic
            inverted
            content={'Установить ограничения'}
            trigger={<Icon
                className={'change-permissions-icon' + getPadding(page, maxIndex)}
                onClick={e=>{setOpen(true)}}
                name={page.viewOrEditSettingsIsSet ? 'lock' : 'unlock'}
            />} />
        <PermissionChangerModal 
            page={page} 
            open={open} 
            setOpen={setOpen}
            onChange={onChange}
        />
    </>;
}