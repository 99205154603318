import React, {useState} from 'react';
import './EmptyPage.css'
import {
    Button, Container,
    Icon
} from "semantic-ui-react";
import CreatePageOrFolderModal from "../CreatePageOrFolderModal/CreatePageOrFolderModal";
import {useHistory} from "react-router-dom";
import appConfig from "../../../../utils/appConfig";

export default function EmptyPage({}) {
    const [openCreatePageOrFolderModal, setOpenCreatePageOrFolderModal] = useState(false)
    const history = useHistory();
    
    return <Container text style={{paddingTop: '100px'}}>
        <h1>Добро пожаловать в базу знаний!</h1>
        <p>Вы находитесь в самом сердце нашей корпоративной системы, где каждому из вас предоставляется возможность делиться опытом, знаниями и идеями. Это пространство создано для того, чтобы помочь всем сотрудникам находить информацию, учиться друг у друга и расти вместе в нашей компании.</p>
        <p>На данный момент база знаний еще не заполнена, и именно вы можете стать её основой! Мы призываем вас добавлять свои статьи, советы и рекомендации. Вместе мы создадим ресурс, который будет полезен всем.</p>
        <p>Давайте сделаем нашу базу знаний богатой и разнообразной, чтобы каждый мог найти ответы на свои вопросы и вдохновение для новых идей.</p>
        <p>Спасибо, что являетесь частью нашей команды!</p>
        {appConfig().info.isKnowledgeBaseAdministrator ? <>
            <Button
                icon
                primary
                size='huge'
                fluid
                style={{marginTop: '10px'}}
                onClick={()=>{setOpenCreatePageOrFolderModal(true)}}
            ><Icon name='star outline' /> Начать</Button>
            <CreatePageOrFolderModal
                directory={'/'}
                open={openCreatePageOrFolderModal}
                setOpen={setOpenCreatePageOrFolderModal}
                onCreate={url=>{
                    history.push(`/knowledgeBase/${url}`)
                }}
            />
        </> : ''}
    </Container>;
}