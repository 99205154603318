import React, {useEffect, useState} from 'react';
import './SetNewPassword.css'
import {
    Button,
    Form,
    Grid,
    Icon,
    Loader,
    Message
} from "semantic-ui-react";
import t from "../../utils/translate";
import { useParams } from "react-router-dom";
import api from "../../utils/api";
import SaverPasswordFormInput from "../../shared/fieldTypes/savedPassword/SaverPasswordFormInput";

export default function SetNewPassword() {
    let {token} = useParams();
    let [isCorrectToken, setIsCorrectToken] = useState(null);
    let [password, setPassword] = useState('');
    let [loading, setLoading] = useState(false);
    useEffect(()=>{
        api().checkSetNewPasswordToken(token)
            .then(resp => {
                if(resp.result === '')
                    setIsCorrectToken(true);
                else
                    setIsCorrectToken(false);
            })
    },[]);
    
    let onSetNewPassword = () => {
        setLoading(true)
        api()
            .setNewPasswordByToken(token, password)
            .then(resp => {
                if(resp.result === 'ok'){
                    window.location = '/passwordIsSet';
                }else {
                    setLoading(false)
                }
            })
    }
    
    return (<div className={'ui container SetNewPassword-page-container'}>
        <div className={'close-btn'} onClick={()=>{
            window.location = '/';
        }}>
            <Icon name={"close"} size={"huge"}/>
            <p>{t('set_password_page_cancel_btn')}</p>
        </div>
        <Grid>
            <Grid.Row>
                <Grid.Column width={5}/>
                <Grid.Column width={6}>
                    {
                        isCorrectToken == null ? <Loader inline={true}/> : <>
                            <Message>{t('set_password_page_message')}</Message>
                            <Form>
                                <Form.Field
                                    width={16}
                                >
                                    <label>{t('set_password_page_field_label')}</label>
                                    <SaverPasswordFormInput
                                        name={'new-password'}
                                        value={password}
                                        column={{example: t('set_password_page_field_label')}}
                                        onChangeFieldValue={(name, val)=>{ setPassword(val)}}
                                    />
                                </Form.Field>
                                <Button 
                                    fluid 
                                    primary 
                                    disabled={(password.length < 6)}
                                    loading={loading}
                                    onClick={onSetNewPassword}>{t('set_password_page_set_btn')}</Button>
                            </Form>
                        </>
                    }
                </Grid.Column>
                <Grid.Column width={5}/>
            </Grid.Row>
        </Grid>
    </div>);
}