import React from 'react';
import './System.scss'

import {Divider, Header} from "semantic-ui-react";
import CodeExample from "../CodeExample/CodeExample";

const System = () => {
    return <div className={'block'}>
        <Divider />
        <Header as='h2' id={'system'}>Система</Header>
        <p>
            Для системных вызовов вам доступен объект <b>app</b>.
        </p>

        <Header as='h3' id={'system-notifications'}>Система: Уведомления</Header>
        <p>
            Отправляйте пользователям тост уведомления используя метод <b>notify</b> объекта <b>app</b>.
        </p>
        <CodeExample title={'Отправка уведомления пользователю'}
                     code={'user = app.dictionary(\'Пользователи\').find({\'Имя\': \'Имя пользователя\'})[0]\napp.notify(user, \'Тут текст уведомления!\')'}/>

        <Header as='h3' id={'system-mail-send'}>Система: Отправить письмо</Header>
        <p>
            Отправляйте электронные письма используя метод <b>mail</b> объекта <b>app</b>.
        </p>
        <CodeExample title={'Отправка уведомления пользователю'}
                     code={'user = app.dictionary(\'Пользователи\').find({\'Имя\': \'Имя пользователя\'})[0]\napp.mail(user.get(\'Email\'), \'Это заголовок письма\', \'Тут текст письма\')'}/>

        <Header as='h3' id={'system-chat-send'}>Система: Отправить сообщение в чат</Header>
        <p>
            Отправляйте сообщения в чат используя метод <b>chat</b> объекта <b>app</b>.
        </p>
        <CodeExample title={'Отправка сообщения в чат'}
                     code={'user_from = app.dictionary(\'Пользователи\').find({\'Имя\': \'Имя пользователя\'})[0]\nuser_to = Dictionary(\'Пользователи\').find({\'Имя\': \'Имя другого пользователя\'})[0]\napp.chat(user_from, user_to, \'Это текст сообщения в чате\')'}/>

        <Header as='h3' id={'system-sql-request'}>Система: Sql запрос</Header>
        <p>
            Отправляйте сообщения в чат используя метод <b>sql</b> объекта <b>app</b>.
        </p>
        <CodeExample title={'Выполнение sql запроса'}
                     code={'result = app.sql(\'SELECT "Name" from public."Users"\')\nprint(result)'}/>

        <Header as='h3' id={'system-parameters'}>Система: Параметры</Header>
        <p>
            Получить значение системного параметра можно с помощью метода <b>get_parameter</b> объекта <b>app</b>.
        </p>
        <CodeExample title={'Получение значения параметра'}
                     code={'result = app.get_parameter(\'Имя параметра\')\nprint(result)'}/>
        <p>
            Установить значение системного параметра можно с помощью метода <b>set_parameter</b> объекта <b>app</b>.
        </p>
        <CodeExample title={'Установка значения параметра'}
                     code={'app.set_parameter(\'Имя параметра\', \'Новое значение параметра\')'}/>
    </div>;
}

export default System