import React, {useState} from 'react';
import {Icon} from "semantic-ui-react";
import {getCurrentLangValue} from "../../utils/translate";
import {useWindowSize} from "../../utils/windowSize";
import { pdfjs } from 'react-pdf';
import {Document, Page} from "react-pdf";
import {getToken} from "../../utils/authHelper";
import getBackedUrl from "../../utils/getBackedUrl";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfPage = ({page}) => {
    const [width, height] = useWindowSize();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    
    let resultHeight = height - 45; 
    let onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    
    return (<div style={{height: (resultHeight) + 'px', width: '100%', padding: '0', margin: '0', position: 'relative'}}>
        <div style={{height: (resultHeight) + 'px', overflowY: 'scroll', width: '100%', padding: '0', margin: '0'}}>
            <Document
                wi
                file={(`${getBackedUrl()}/api/pdfPage/getData?name=${page.name}&token=${getToken()}`)}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={pageNumber}
                      width={(width - 15)}
                />
            </Document>
            
        </div>
        {numPages > 1 ? <div style={{position: 'absolute', top: '10px', right: '30px', backgroundColor: 'whitesmoke', padding: '10px', borderRadius: '5px'}}>{ pageNumber !== 1 ? <Icon style={{cursor: 'pointer'}} name={'arrow left'} onClick={(e)=>{
            e.stopPropagation();
            setPageNumber(pageNumber - 1);
        }} /> : ''} {getCurrentLangValue() === 'ru' ? 'Страница' : 'Page'} {pageNumber} {getCurrentLangValue() === 'ru' ? 'из' : 'of'} {numPages} { pageNumber !== numPages ? <Icon style={{cursor: 'pointer'}} name={'arrow right'} onClick={(e)=>{
            e.stopPropagation();
            setPageNumber(pageNumber + 1);
        }} /> : ''}</div> : ''}
    </div>);
}

export default PdfPage