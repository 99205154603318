import React, {useEffect, useRef, useState} from 'react';
import api from "../../utils/api";
import PowerBiReportViewer from "./PowerBiReportViewer";
import {Icon, Loader} from "semantic-ui-react";
import t from "../../utils/translate";

const PowerBiPage = ({name}) => {
    var [isError, setIsError] = useState(false);
    var [config, setConfig] = useState(null);
    
    const containerRef = useRef(null);
    
    useEffect(() => {
        api()
            .getPowerBiConfig(name)
            .then(c => {
                
                console.log('Power Bi config');
                console.log(c);
                
                if(c.errorMessage !== undefined && c.errorMessage !== null){
                    setIsError(true);
                }else{
                    setConfig(c);   
                }
        });
    }, []);
    
    return (<div ref={containerRef}>
        {isError ? <div className={'powerbi-placeholer'}>
            <div className={'loader-for-powerbi-placeholer'}>
                <Icon name={'bug'} size={'huge'} style={{marginBottom: '30px'}}/>
                Возникла проблема с загрузкой Power Bi отчёта
            </div>
        </div>: (config == null ? <div className={'powerbi-placeholer'}>
            <div className={'loader-for-powerbi-placeholer'}>
                <Loader active inline size='massive'>{t('Loading')}</Loader>
            </div>
        </div> : <PowerBiReportViewer accessToken={config.token} embedId={config.reportId} embedUrl={config.embedUrl}/>)}
    </div>);
}

export default PowerBiPage