import React, {useEffect, useState} from 'react';
import './SearchModal.scss'
import {
    Breadcrumb, BreadcrumbDivider, BreadcrumbSection,
    Button, Container, Input, Modal, Segment, SegmentGroup
} from "semantic-ui-react";
import {useWindowSize} from "../../../../utils/windowSize";
import api from "../../../../utils/api";

export default function SearchModal({open, setOpen}) {
    const [width, height] = useWindowSize();
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isStart, setStart] = useState(true);
    const [results, setResults] = useState([]);
    
    useEffect(() => {
        if(open){
            setStart(true)
            setResults([])
            setIsLoading(false)
            setSearchQuery('')
        }
    }, [open])
    
    const search = () => {
        api()
            .searchByKnowledgeBase(searchQuery)
            .then(resp => {
                setResults(resp.data)
                setStart(false)
            })
    }
    
    return (<Modal
        open={open}
        onClose={() => setOpen(false)}
        size={'fullscreen'}
        centered={false}
        closeIcon={true}
        onClick={e=>{
            e.preventDefault()
            e.stopPropagation()
        }}
    >
        <Modal.Header>Поиск в базе знаний</Modal.Header>
        <Modal.Content className={'search-by-knowledge-base-model'} style={{height: `${height - 180}px`}}>
            {isStart ? <Input
                style={{
                    width: '500px',
                    marginTop: '200px',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                value={searchQuery}
                onChange={e=> setSearchQuery(e.target.value)}
                onKeyDown={(event)=>{
                    if(event.ctrlKey === false && event.key === 'Enter'){
                        event.preventDefault();
                        search();
                    }
                }}
                icon={'search'}
                iconPosition={'left'}
                placeholder={'Введите фразу для поиска...'}/> : <>
                <Input
                    style={{
                        width: '500px',
                    }}
                    icon={'search'}
                    value={searchQuery}
                    onChange={e=> setSearchQuery(e.target.value)}
                    iconPosition={'left'}
                    placeholder={'Введите фразу для поиска...'}/>
                <div className={'result-header'}>
                    Cовпадений: {results.length}
                </div>
                <SegmentGroup>
                    {results.map(resultItem => <Segment>
                        <h4><a onClick={()=> window.open(`/knowledgeBase/${resultItem.url}`, '_blank')} target={'_blank'} href={`/knowledgeBase/${resultItem.url}`}>{resultItem.name}</a></h4>
                        {resultItem.path.length > 0 ? <div className={'path-explorer'}>
                            <Breadcrumb>
                                {resultItem.path.map((pathItem, index) =>
                                    <>{index !== 0 ? <BreadcrumbDivider icon='right chevron' /> : ''}<BreadcrumbSection onClick={e => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        window.open(`/knowledgeBase/${pathItem.url}`, '_blank')
                                    }} link>{pathItem.title}</BreadcrumbSection></> )}
                            </Breadcrumb>
                        </div> : ''}
                        <Container text fluid>
                            {resultItem.text}
                        </Container>
                    </Segment>)}
                </SegmentGroup>
            </>}
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={() => setOpen(false)} basic>
                Закрыть
            </Button>
        </Modal.Actions>
    </Modal>);
}