import { useEffect, useRef, useState } from "react";

function useDynamicState(initialState) {
    const [state, setState] = useState(initialState);
    const stateRef = useRef(state);

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    return [state, setState, stateRef];
}

export default useDynamicState