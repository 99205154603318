import {getCurrentLangValue} from "./translate";
import getBackedUrl from "./getBackedUrl";

export default function api() {
    
    let createReqFile = (url, file) => {
        let config = {
            method: 'Post',
            headers: {
            //    "Content-Type": "application/json; charset=utf-8"
            }
        }

        if (localStorage.Identity !== undefined) {
            let token = JSON.parse(localStorage.Identity).accessToken || null;
            if (token)
                config.headers["Authorization"] = 'Bearer ' + token;
            else {
                throw "No token saved!";
            }
        }
        const formData = new FormData();
        formData.append("file", file);

        config.body = formData;

        try{
            return fetch(getBackedUrl() + url, config)
                .then(function (response) {
                        return response.text().then(function (text) {
                            return ({text: text, response: response});
                        })
                    }
                ).then(function (a) {
                    if (a.response.status === 401) {
                        localStorage.clear()
                        window.location = '/login';
                    }
                    if (!a.response.ok) {
                        return Promise.reject(a.text);
                    }
                    return a.text;
                })
                .then(function (resp) {
                    return JSON.parse(resp)
                }).catch(function (err) {
                    return ({errorMessage: err.message});
                });
        }catch (e) {
            return new Promise(()=>{ return ({errorMessage: e.message}); });
        }
    }
    let getFile = (url) => {
        let config = {
            method: 'Get',
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        }

        if (localStorage.Identity !== undefined) {
            let token = JSON.parse(localStorage.Identity).accessToken || null;
            if (token)
                config.headers["Authorization"] = 'Bearer ' + token;
            else {
                throw "No token saved!";
            }
        }

        try{
            return fetch(getBackedUrl() + url, config)
                .then( res => res.blob() )
                .catch(function (err) {
                    return ({errorMessage: err.message});
                });
        }catch (e) {
            return new Promise(()=>{ return ({errorMessage: e.message}); });
        }
    }
    
    let createReq = (method, url, data) => {
        let config = {
            method: method,
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        }

        if (localStorage.Identity !== undefined) {
            let token = JSON.parse(localStorage.Identity).accessToken || null;
            if (token)
                config.headers["Authorization"] = 'Bearer ' + token;
            else {
                throw "No token saved!";
            }
        }

        if ((method === 'Post' || method === 'Put') &&  data)
            config.body = JSON.stringify(data);

        try{
            return fetch(getBackedUrl() + url, config)
                .then(function (response) {
                        if (response.ok) {
                            return response.text().then(function (text) {
                                return ({text: text, response: response});
                            });
                        } else {
                            throw new Error('Fetch error');
                        }
                    }
                ).then(function (a) {
                    if (a.response.status === 401) {
                        localStorage.clear()
                        window.location = '/login';
                    }
                    if (!a.response.ok) {
                        return Promise.reject(a.text);
                    }
                    return a.text;
                })
                .then(function (resp) {
                    return JSON.parse(resp)
                }).catch(function (err) {
                    return ({errorMessage: err.message});
                });            
        }catch (e) {
            return new Promise(()=>{ return ({errorMessage: e.message}); });
        }

    };
    
    let createQueryParams = (filter)=>{
        var result = '';
        if(filter.length > 0){
            result = '&';
            for (var i = 0; i < filter.length; i++){
                if(filter[i].value !== null && filter[i].value !== '')
                    result += filter[i].name + '=' + filter[i].value + '&';
                if(filter[i].canEmpty !== null && filter[i].canEmpty !== '')
                    result += filter[i].name + 'CanEmpty' + '=true' + '&';
            }
        }
        return result;
    }
    
    return {
        getFieldValuesForView: (name, page, filter, view, sort, searchQueryValue, targetFieldName) => {
            let fieldName = sort === '' ||  sort === null ||  sort === undefined ? '_' : sort.split('.')[3].split('-')[0];
            let sortType = sort === ''  ||  sort === null ||  sort === undefined ? '_' : sort.split('.')[3].split('-')[1];
            return createReq('Get', '/api/' + name + '/getFieldValuesForView/'+view+'/' + targetFieldName + '/' + (page*30) +'/30/' + fieldName + '/' + sortType +'?' + (searchQueryValue !== undefined && searchQueryValue != null && searchQueryValue !== '' ? ('&query=' + searchQueryValue) : '') + createQueryParams(filter));
        },
        getList: (name, page, filter) => {return createReq('Get', '/api/' + name + '/getList?page=' + page + createQueryParams(filter));},
        getListForView: (name, page, filter, view, sort, searchQueryValue) => {
            let fieldName = sort === '' ||  sort === null ||  sort === undefined ? '_' : sort.split('.')[3].split('-')[0]; 
            let sortType = sort === ''  ||  sort === null ||  sort === undefined ? '_' : sort.split('.')[3].split('-')[1]; 
            return createReq('Get', '/api/' + name + '/getListForView/'+view+'/' + (page*30) +'/30/' + fieldName + '/' + sortType +'?' + (searchQueryValue !== undefined && searchQueryValue != null && searchQueryValue !== '' ? ('&query=' + searchQueryValue) : '') + createQueryParams(filter));
        },
        getSumForView: (name, page, filter, view, searchQueryValue) => {
            return createReq('Get', '/api/' + name + '/getSumForView/'+view+'/' + (page*30) +'/30/' +'?' + (searchQueryValue !== undefined && searchQueryValue != null && searchQueryValue !== '' ? ('&query=' + searchQueryValue) : '') + createQueryParams(filter));
        },
        getListForSelect: (name, page, filter = [], additionFilter=null) => {return createReq('Get', '/api/' + name + '/getListForSelect' + (additionFilter !== null || filter.length > 0 ? '?': '') + (additionFilter == null ? '': 'additionFilter=' + additionFilter) + createQueryParams(filter), {page});},
        getById: (name, id) => {return createReq('Get', '/api/' + name + '/getById/' + id);},
        getActionsForId: (name, id) => {return createReq('Get', '/api/' + name + '/getActionsForId/' + id);},
        invokeActionForId: (name, actionName, id) => {var obj = {}; return createReq('Post', '/api/' + name + '/invokeAction/'+actionName+'/ForId/' + id, obj);},
        invokeActionForIdWithParameters: (name, actionName, id, parameters) => { var obj = {}; for (var a in parameters) {obj[parameters[a].name] = parameters[a].value;} return createReq('Post', '/api/' + name + '/invokeAction/'+actionName+'/ForId/' + id, obj);},
        invokeTask: (taskName) => {var obj = {};return createReq('Post', '/api/system/invokeTask/'+taskName, obj);},
        invokeTaskWithParameters: (taskName, parameters) => { var obj = {}; for (var a in parameters) {obj[parameters[a].name] = parameters[a].value;} return createReq('Post', '/api/system/invokeTask/'+taskName, obj);},
        getConfiguration: () => {return createReq('Get', '/api/configuration');},
        saveOrCreate: (name, data) => { var obj = {}; for (var a in data) {obj[data[a].name] = data[a].value;} return createReq('Post', '/api/' + name + '/createOrUpdate'+ '/' + getCurrentLangValue(), obj);},
        deleteDictionaryItem: (name, id) => { return createReq('Delete', '/api/' + name + '/delete/' + id + '/' + getCurrentLangValue());},
        getToken: (login, password) => { return createReq('Get', '/api/identity/getToken?login=' + login + '&password=' + password);},
        loginByToken: (token) => { return createReq('Get', '/api/identity/getTokenByLoginToken?token=' + token);},
        getPowerBiConfig: (name) => { return createReq('Get', '/api/powerBi/getConfig?name=' + name);},
        uploadFile: (file, path) => { return createReqFile(path ?? '/api/file/upload', file);},
        uploadAvatar: (file, path) => { return createReqFile(path ?? '/api/file/uploadAvatar', file);},
        uploadImage: (file, path) => { return createReqFile(path ?? '/api/file/uploadImage', file);},
        createDownloadToken: (fileId, name) => { return createReq('Get', `/api/file/createDownloadToken?fileId=${fileId}&name=${name}`);},
        sendForgotPasswordEmail: (email) => { return createReq('Post', '/api/forgotPassword/createRequest', {email: email});},
        checkSetNewPasswordToken: (token) => { return createReq('Get', '/api/forgotPassword/validateToken?token=' + token);},
        setNewPasswordByToken: (token, password) => { return createReq('Post', '/api/forgotPassword/set', {token, password});},
        uploadByExcel: (fileId, viewName, dictionaryName) => { return createReq('Post', '/api/'+dictionaryName+'/uploadByExcel/' + viewName + "/" + fileId);},
        exportToExcel: (viewName, dictionaryName, lang) => { return getFile('/api/'+dictionaryName+'/'+viewName+'/exportToExcel/' + lang);},
        getValuesForReportElement: (reportStrictName, pageIndex, elementIndex, filtersValues) => { return createReq('Get', '/api/report/getData?reportName='+reportStrictName+'&pageIndex='+pageIndex+'&elementIndex=' + elementIndex + (filtersValues.length > 0 ? '&filtersValues=' + (JSON.stringify(filtersValues)) : ''));},
        getNotifications: (pageNumber = 0, from = '_', to = '_', search = '_') => { return createReq('Get', '/api/notifications/getForUser?pageNumber=' + pageNumber + '&from=' + from + '&to=' + to + '&search=' + search);},
        getParameters: () => { return createReq('Get', '/api/system/parameters');},
        saveParameters: (data) => {var obj = {}; for (var a in data) {obj[data[a].name] = data[a].value;} return createReq('Post', '/api/system/parameters/save', obj);},
        getAllTasks: () => { return createReq('Get', '/api/system/tasks');},
        getIndicators: () => { return createReq('Get', '/api/indicators');},
        sendDataFromPublicForm: (page, lang, data) => { var obj = {}; for (var a in data) {obj[data[a].name] = data[a].value;} return createReq('Post', '/api/publicPages/saveFromPublicForm/'+lang+'/' + page.url.substr(1), obj);},
        loadBackgroundTasks: () => { return createReq('Get', `/api/backgroundTasks`);},
        
        /*Calendar methods start*/
        getEventsForWeek: (year, weekNumber) => { return createReq('Get', `/api/calendar/week/${year}/${weekNumber}`);},
        getEventsForMonth: (year, month) => { return createReq('Get', `/api/calendar/month/${year}/${month}`);},
        getEventsForDay: (year, month, day) => { return createReq('Get', `/api/calendar/day/${year}/${month}/${day}`);},
        getCalendarEventById: (id) => { return createReq('Get', `/api/calendar/getById/${id}`);},
        createOrUpdateCalendarEvent: (event) => { return createReq('Post', `/api/calendar/createOrUpdate`, event);},
        deleteCalendarEvent: (id) => { return createReq('Delete', `/api/calendar/deleteEvent/${id}`);},
        getAvailableMembers: () => { return createReq('Get', `/api/calendar/getAvailableMembers`);},
        /*Calendar methods end*/

        /*Chat methods start*/
        createChannel: (channelName, members, initMessage) => { return createReq('Put', `/api/chat/createChannel`, {channelName, members, initMessage});},
        getChannels: () => { return createReq('Get', `/api/chat/channels`);},
        deleteChannel: (id) => { return createReq('Delete', `/api/chat/deleteChannel/${id}`);},
        setSeen: (channelId, messageId) => { return createReq('Post', `/api/chat/setSeen/${channelId}/${messageId}`);},
        sendMessage: (toChatChannelId, message, files) => { return createReq('Post', `/api/chat/sendMessage`, {toChatChannelId, message, files});},
        getMessages: (channelId) => { return createReq('Get', `/api/chat/messages/${channelId}`);},
        getAvailableMembersForChat: () => { return createReq('Get', `/api/chat/getAvailableMembers`);},
        /*Chat methods end*/
        
        /*Mail client methods start*/
        mailLogin: (mail, password, imapServer, smtpServer) => { return createReq('Post', '/api/mail/login', {
            mail: mail,
            password: password,
            imapServer: imapServer,
            smtpServer: smtpServer,
        });},
        getEmailAccounts: () => { return createReq('Get', '/api/mail/mailCredentials');},
        logoutFromEmailAccount: (id) => { return createReq('Post', '/api/mail/logout?id=' + id);},
        getLetters: (id, folder) => { return createReq('Get', '/api/mail/list?mailId=' + id + '&folder=' + folder);},
        refreshEmail: (id) => { return createReq('Post', '/api/mail/refresh?mailId=' + id);},
        getLetter: (id) => { return createReq('Get', '/api/mail/details?id=' + id);},
        sendEmail: (from, to, title, body) => { return createReq('Post', '/api/mail/send', {
            mailsCredentialsId: from,
            to: to,
            title: title,
            body: body,
        });},
        /*Mail client methods end*/

        /*Knowledge base methods start*/
        getStartPageForKnowledgeBase: () => { return createReq('Get', '/api/knowledgeBase/start');},
        searchByKnowledgeBase: (query) => { return createReq('Get', '/api/knowledgeBase/search?query=' + query);},
        resolveKnowledgeBaseFolderNameByUrl: (path) => { return createReq('Get', '/api/knowledgeBase/resolveFolderNameByUrl?path=' + path);},
        createPageOrFolderInKnowledgeBase: (folderOrPage, name, path) => { return createReq('Post', '/api/knowledgeBase/page/create?folderOrPage=' + folderOrPage + '&name=' + name + '&path=' + path);},
        deleteKnowledgePage: (path) => { return createReq('Delete', '/api/knowledgeBase/page/delete?path=' + path);},
        saveKnowledgeBasePageContent: (path, content, title, icon) => { return createReq('Post', '/api/knowledgeBase/page/save', {
            path: path,
            title: title,
            icon: icon,
            content: content
        });},
        getKnowledgeBasePageContent: (path) => { return createReq('Get', '/api/knowledgeBase/page/content?path=' + path);},
        getPermissionsForKnowledgeFolder: (id) => { return createReq('Get', `/api/knowledgeBase/permissions/${id}`);},
        setPermissionsForKnowledgeFolder: (id, view, edit) => { return createReq('Post', `/api/knowledgeBase/permissions`, {id, view: view, edit: edit, roles: null});},
        addToSubscribers: (id, type) => { return createReq('Post', `/api/knowledgeBase/subscriptions/change/${id}|${type}|add`);},
        removeFromSubscribers: (id, type) => { return createReq('Post', `/api/knowledgeBase/subscriptions/change/${id}|${type}|remove`);},
        getSettingsForKnowledgeBase: () => { return createReq('Get', `/api/knowledgeBase/subscriptions`);},
        getKnowledgeBaseMenuItems: (path) => { return createReq('Get', '/api/knowledgeBase/list?path=' + path);},
        getKnowledgeBasePageComments: (id) => { return createReq('Get', `/api/knowledgeBase/comments/${id}`);},
        leaveCommentOnKnowledgeBasePage: (pageId, comment) => { return createReq('Post', `/api/knowledgeBase/comments`, {pageId: pageId, comment: comment});},
        getKnowledgeBasePageHistoryItems: (id) => { return createReq('Get', `/api/knowledgeBase/history/items/${id}`);},
        getKnowledgeBasePageHistoryItem: (id) => { return createReq('Get', `/api/knowledgeBase/history/item/${id}`);},
        getNewsForKnowledgeBase: () => { return createReq('Get', `/api/knowledgeBase/news`);},
        getUnreadNewsCountForKnowledgeBase: () => { return createReq('Get', `/api/knowledgeBase/news/unreadCount`);},
        setAllUnreadNewsForKnowledgeBaseAsRead: () => { return createReq('Post', `/api/knowledgeBase/news/readAll`);},
        setUnreadNewsForKnowledgeBaseAsRead: (ids) => { return createReq('Post', `/api/knowledgeBase/news/read`, {ids: ids});},
        
        setPositionKnowledgeBasePage: (id, position) => { return createReq('Post', `/api/knowledgeBase/move/setPosition/${id}/${position}`);},
        setNewParentForKnowledgePage: (pageId, url) => { return createReq('Post', `/api/knowledgeBase/move/setParent/${pageId}?url=${url}`);},
        /*Knowledge base methods end*/
        
        /*Dictionary entity comments start*/
        getCommentsForEntity: (dictionaryStrictName, id, page) => {return createReq('Get', `/api/comments/${dictionaryStrictName}/get/${id}/${page}`)},
        sendCommentForEntity: (dictionaryStrictName, id, message) => {return createReq('Post', `/api/comments/${dictionaryStrictName}/create/${id}`, {
            message: message
        })}
        /*Dictionary entity comments end*/
    };
}